import {TaskRepetitionUnit, CustomTaskRepetition} from '@hconnect/common/types'
import {TextField, SxProps, Theme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

type Props = {
  sx?: SxProps<Theme>
  settings: CustomTaskRepetition
  onChange: (value: number) => void
}

const upperLimit: Record<TaskRepetitionUnit, number> = {
  daily: 365,
  weekly: 53,
  monthly: 12,
  yearly: 5
}

const translationKey: Record<TaskRepetitionUnit, string> = {
  daily: 'recurringDialog.day',
  weekly: 'recurringDialog.week',
  monthly: 'recurringDialog.month',
  yearly: 'recurringDialog.year'
}

export const TaskRepetitionInterval: React.FC<Props> = ({settings, onChange, sx}) => {
  const min = 1
  const max = upperLimit[settings.repetition]

  const {t} = useTranslation()

  return (
    <>
      <TextField
        sx={sx}
        data-test-id="task-custom-repetition-interval"
        inputProps={{'data-test-id': 'task-custom-repetition-interval-input'}}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          let next = parseInt(event.target.value, 10)

          if (!next || isNaN(next) || next < min) {
            next = min
          } else if (next > max) {
            next = max
          }

          onChange(next)
        }}
        type="number"
        value={settings.repeatEvery}
        variant="outlined"
      />

      {t(translationKey[settings.repetition], {count: settings.repeatEvery ?? 1})}
    </>
  )
}
