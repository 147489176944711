import {EventType} from '@hconnect/common/types'

import {isStoppageEventType} from '../common/utils/eventType'

/**
 * For events update/create, different endpoints are used for different types of events
 * Any changes in repetition info goes to schedules api.
 * Unplanned stoppages events are handled by the stoppages' endpoint.
 * All other events have a shared api /events.
 * @param plantId
 * @param eventType
 * @param isRecurring
 * @returns
 */
export function getEventBackendEndpoint(
  plantId: string,
  eventType: EventType,
  isRecurring: boolean
) {
  if (isRecurring) {
    return `/shifthandover/${plantId}/tasks/schedules`
  } else if (isStoppageEventType(eventType)) {
    return `/shifthandover/${plantId}/stoppages`
  } else {
    return `/shifthandover/${plantId}/events`
  }
}
