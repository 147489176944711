import {shifthandoverStoppagesList, EventType} from '@hconnect/common/types'
import {uniq} from 'lodash'

import {isStoppageEventType} from '../../common/utils/eventType'

export type EventTypeFilterValue = EventType | 'stoppage'

export const everyStoppageCategoryChecked = (value: EventTypeFilterValue[]): boolean | undefined =>
  shifthandoverStoppagesList.every((code) => value.includes(code))

export const stoppageCategoryChecked = (value: EventTypeFilterValue[]): boolean | undefined =>
  shifthandoverStoppagesList.some((code) => value.includes(code))

export const preprocessValueList = (valueList: EventTypeFilterValue[]): EventType[] => {
  const stoppageSelected = valueList.includes('stoppage')
  const eventTypeList = valueList.filter((vt) => vt !== 'stoppage') as EventType[]
  if (stoppageSelected && everyStoppageCategoryChecked(valueList)) {
    return eventTypeList.filter((v) => !isStoppageEventType(v))
  }
  if (stoppageSelected) {
    return uniq([...shifthandoverStoppagesList, ...eventTypeList])
  }
  return eventTypeList
}
