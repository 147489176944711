import {AxiosError} from 'axios'
import {useMutation, UseMutationOptions} from 'react-query'

import {TimeRange} from '../../types/timeAndDate.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const QueryKey = 'shift-summary-document'

type ShiftSummaryDocumentPayload = {
  timeRange: TimeRange
  reportLanguage: string
  includeProductionVolumes: boolean
}

export const useShiftSummaryDocument = (
  options?: UseMutationOptions<Blob, AxiosError, ShiftSummaryDocumentPayload>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  const path = `/shifthandover/${plantId}/shiftsummaryreport`

  return useMutation(
    async (data: ShiftSummaryDocumentPayload) => {
      const response = await axiosInstance.post(
        `${path}?reportLanguage=${data.reportLanguage}`,
        {
          ...data.timeRange,
          includeProductionVolumes: data.includeProductionVolumes
        },
        {
          responseType: 'blob'
        }
      )
      return response.data
    },
    {
      ...options
    }
  )
}
