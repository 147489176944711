import {AxiosRequestConfig} from 'axios'
import type MockAdapter from 'axios-mock-adapter/types'

import {
  CreateMaintenanceNotification,
  SapStatusCode
} from '../../types/maintenanceNotification.types'
import {mockStore} from '../mockStoreSingleton'

import {updateEventsReply} from './events'

const findEvent = (eventId: string) => {
  const mockState = mockStore.scenario()
  return mockState.events.find((event) => event.id === eventId)
}

const createEventsReply = (config: AxiosRequestConfig) => {
  const requestDto = JSON.parse(config.data as string) as CreateMaintenanceNotification
  const newId: string = Math.floor(Math.random() * 10000000).toString()
  const matchedEvent = findEvent(requestDto.sourceEventId)
  if (!matchedEvent) return [404, {}]

  const modifiedEvent = {
    ...matchedEvent,
    sapStatusCode: SapStatusCode.SUBMITTED
  }

  const eventConfig = {...config, data: JSON.stringify(modifiedEvent)}
  const [code] = updateEventsReply(eventConfig)

  setTimeout(() => {
    const updatedMatchedEvent = findEvent(requestDto.sourceEventId)

    const updatedEvent = {
      ...updatedMatchedEvent,
      sapStatusCode: SapStatusCode.PENDING,
      sapMaintenanceNotificationId: newId
    }

    const updateEventConfig = {...config, data: JSON.stringify(updatedEvent)}
    updateEventsReply(updateEventConfig)
  }, 500)

  return code === 201 ? [201, newId] : [code, {}]
}

export const enableMaintenanceNotificationMock = (apiMockAdapter: MockAdapter) => {
  apiMockAdapter.onPost(/\/shifthandover\/\w+\/maintenance-notification/).reply(createEventsReply)
}
