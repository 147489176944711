import {ManualKpiToUpdate} from '@hconnect/common/types'
import {generateKpi} from '@hconnect/common/utils'
import {Box, Button, TextField} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {TFunction} from 'i18next'
import React, {FC} from 'react'
import {Controller, RegisterOptions, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {requiredValidator, uniqueTitleValidator} from '../../validators'

type ManualKpiCreateDialog = {
  isDialogOpen: boolean
  onSave: (data: ManualKpiToUpdate) => void
  onClose: () => void
  existingKpiNames: string[]
}

const titleValidate = (t: TFunction, existingTitles: string[]): RegisterOptions => ({
  ...requiredValidator(t),
  validate: uniqueTitleValidator(t, existingTitles)
})

export const ManualKpiCreateDialog: FC<ManualKpiCreateDialog> = ({
  isDialogOpen,
  onClose,
  onSave,
  existingKpiNames
}) => {
  const {t} = useTranslation()

  const {control, handleSubmit, reset} = useForm<Partial<ManualKpiToUpdate>>()

  const handleClose = () => {
    onClose()
    reset()
  }

  const onSubmit = (data: Partial<ManualKpiToUpdate>) => {
    const newKpi = generateKpi(data)
    onSave(newKpi)
    handleClose()
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      data-test-id="manual-kpi-create-dialog"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle variant="h3">{t('performance.label.enterKpi')}</DialogTitle>
        <Box pr={3}>
          <Button
            onClick={handleClose}
            variant="text"
            data-test-id="manual-kpi-create-close-button"
          >
            {t('action.cancel')}
          </Button>
          <Button
            onClick={() => void handleSubmit(onSubmit)()}
            variant="contained"
            data-test-id="manual-kpi-create-save-button"
          >
            {t('action.save')}
          </Button>
        </Box>
      </Box>
      <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: 2, pt: 0}}>
        <Controller
          control={control}
          name="name"
          rules={titleValidate(t, existingKpiNames)}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <TextField
              label={t('performance.label.title')}
              value={value}
              onChange={onChange}
              inputRef={ref}
              variant="filled"
              size="small"
              fullWidth={true}
              helperText={error?.message}
              error={!!error?.message}
              data-test-id="manual-kpi-create-name"
            />
          )}
        />
        <Controller
          control={control}
          name="unit"
          rules={requiredValidator(t)}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <TextField
              label={t('performance.label.unit')}
              value={value}
              onChange={onChange}
              inputRef={ref}
              variant="filled"
              size="small"
              fullWidth={true}
              helperText={error?.message}
              error={!!error?.message}
              data-test-id="manual-kpi-create-unit"
            />
          )}
        />
      </DialogContent>
    </Dialog>
  )
}
