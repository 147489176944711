import {EventStatusLabel} from '@hconnect/common/components/eventProperties/EventStatusLabel'
import {CommonDropDown, CommonProps} from '@hconnect/common/components/shiftEventFormFields'
import React from 'react'

import {
  statusList,
  EventStatus,
  statusListWithNoneOption,
  StatusWithNoneAndCancellation,
  statusListWithCancellationOption
} from '../../types/shiftHandover.types'

export const EventStatusDropDown = <S extends EventStatus<StatusWithNoneAndCancellation>>(
  props: CommonProps<S> & {allow?: Extract<StatusWithNoneAndCancellation, 'none' | 'cancelled'>}
): React.ReactElement => {
  const {allow, ...rest} = props
  const commonProps: CommonProps<S> = rest as CommonProps<S>

  const options = (allow === 'none'
    ? statusListWithNoneOption
    : allow === 'cancelled'
      ? statusListWithCancellationOption
      : statusList) as unknown as ReadonlyArray<S>

  return (
    <CommonDropDown<S>
      {...commonProps}
      keyName="status"
      options={options}
      customTemplate={(status) => {
        return <EventStatusLabel status={status} />
      }}
    />
  )
}
