import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import {Box, SxProps, Theme, Typography} from '@mui/material'
import React, {FC} from 'react'

type CommentCounter = {
  id: string
  count: number
  sx?: SxProps<Theme>
}

export const CommentCounter: FC<CommentCounter> = ({id, count, sx}) => (
  <Box
    component="span"
    sx={{
      display: 'flex',
      alignItems: 'center',
      color: 'grey.600',
      gap: 0.5,
      ...sx
    }}
  >
    <ChatBubbleOutlineOutlinedIcon />

    {!!count && (
      <Typography variant="h5" data-test-id={`comments-counter-${id}`}>
        {count}
      </Typography>
    )}
  </Box>
)
