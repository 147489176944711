import React, {useState, useContext, createContext} from 'react'

import {Status, ShiftEvent} from '../types/shiftHandover.types'

import {useEventUpdateStatus} from './api/useEventUpdateStatus'

export type Section = Status | undefined

type DraggableContext = {
  draggedFrom: Section
  draggingOver: Section
  draggingTask: ShiftEvent | undefined
  onDragStart: (section, task) => void
  onDragOver: (section, cb) => void
  onDragEnd: (section) => void
}

const useDraggableTask = (): DraggableContext => {
  const [draggedFrom, setDraggedFromSection] = useState<Section>(undefined)
  const [draggingOver, setDraggingOver] = useState<Section>(undefined)
  const [task, setTask] = useState<ShiftEvent | undefined>(undefined)

  const updateAction = useEventUpdateStatus()

  const onDragStart = (section: Section, task: ShiftEvent) => {
    setDraggedFromSection(section)
    setTask(task)
  }

  const onDragOver = (section: Section, callback: () => void) => {
    if (section !== draggedFrom && task) {
      setDraggingOver(section)
      callback()
    }
  }

  const onDragEnd = (section: Section) => {
    section &&
      task &&
      task?.status !== section &&
      updateAction.mutate({
        task,
        status: section
      })

    setDraggedFromSection(undefined)
    setDraggingOver(undefined)
    setTask(undefined)
  }

  return {
    draggedFrom,
    draggingOver,
    draggingTask: task,
    onDragStart,
    onDragOver,
    onDragEnd
  }
}

const context = createContext<DraggableContext | undefined>(undefined)

export const DraggableTaskContextProvider = ({children}: {children: React.ReactNode}) => {
  const values = useDraggableTask()

  return <context.Provider value={values}>{children}</context.Provider>
}

export function useDraggableTaskContext(): DraggableContext {
  const ctx = useContext(context)
  if (ctx === undefined) {
    throw new Error(
      'useDraggableTaskContext was used outside the scope of a DraggableTaskContextProvider!'
    )
  } else {
    return ctx
  }
}
