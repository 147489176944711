import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import {PriorityTitle} from '@hconnect/common/components/shiftEventFormFields'
import {usePriorities} from '@hconnect/common/hooks/usePriorities'
import {Priority} from '@hconnect/common/types'
import React from 'react'

import {SelectAllButton} from '../common/SelectAllButton'

import {CommonFilterDropDownProps, FilterDropDown} from './FilterDropDown'

export const PrioritiesFilterDropDown: React.FC<CommonFilterDropDownProps<Priority>> = (props) => {
  const priorities = usePriorities().data
  const options = priorities ? Array.from(priorities.keys()) : []
  const {selectAll} = props

  return (
    <FilterDropDown
      {...props}
      options={options}
      keyName="priority"
      fullWidth={false}
      renderValue={(value: number[]) => {
        return value.map((val) => (priorities ? priorities.get(val) : '')).join(',')
      }}
      customTemplate={(key) => <PriorityTitle priority={key} />}
      customDropDownFooter={(keyName) =>
        props.isCollapsible ? null : (
          <>
            <SelectAllButton
              keyName={keyName}
              selectAll={() => selectAll && selectAll(keyName, options)}
            />
            <RemoveFilterButton keyName={keyName} removeFilter={props.removeFilter} />
          </>
        )
      }
    />
  )
}
