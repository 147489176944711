import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import React from 'react'

import {workOrderStatusListApprovalStates, WorkOrderStatus} from '../../types/shiftHandover.types'
import {SelectAllButton} from '../common/SelectAllButton'
import {WorkOrderStatusLabel} from '../eventProperties/WorkOrderStatusLabel'

import {CommonFilterDropDownProps, FilterDropDown} from './FilterDropDown'

export const WorkOrderStatusFilterDropDown: React.FC<CommonFilterDropDownProps<WorkOrderStatus>> = (
  props
) => {
  const {selectAll} = props
  return (
    <FilterDropDown
      {...props}
      options={workOrderStatusListApprovalStates}
      keyName="workOrderStatus"
      customTemplate={(status) => {
        return <WorkOrderStatusLabel status={status} />
      }}
      fullWidth={false}
      customDropDownFooter={(keyName) =>
        props.isCollapsible ? null : (
          <>
            <SelectAllButton
              keyName={keyName}
              selectAll={() => selectAll && selectAll(keyName, workOrderStatusListApprovalStates)}
            />
            <RemoveFilterButton keyName={keyName} removeFilter={props.removeFilter} />
          </>
        )
      }
    />
  )
}
