/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type MockAdapter from 'axios-mock-adapter/types'

import {mockStore} from '../mockStoreSingleton'
import {MockStorageImage} from '../scenarios/scenario.type'

import {base64UrlToArrayBuffer} from './attachmentsUtil'

export const enableImageMock = (apiMockAdapter: MockAdapter) => {
  apiMockAdapter.onGet(/\/documents\/.+/).reply((config) => {
    const imageId = config.url!.split('/documents/')[1]
    const mockImage: MockStorageImage = mockStore
      .scenario()
      .images.find((img) => img.id === imageId)!
    const arrayBuffer = base64UrlToArrayBuffer(mockImage.dataUrl)
    if (arrayBuffer) return [200, arrayBuffer]
    else return [404, undefined]
  })
}
