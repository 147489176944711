import {IconWithLabel} from '@hconnect/common/components/shiftEventLabels'
import {Block, Done, Remove, Check, QuestionMark} from '@mui/icons-material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {WorkOrderStatus} from '../../types/shiftHandover.types'

export const WorkOrderStatusLabel: FC<{
  status: WorkOrderStatus
  iconOnly?: boolean
  sx?: SystemStyleObject
}> = (props) => {
  const {t} = useTranslation()

  const icons: Record<WorkOrderStatus, React.ReactNode> = {
    [WorkOrderStatus.Unknown]: <QuestionMark />,
    [WorkOrderStatus.Closed]: <Block color={'secondary'} />,
    [WorkOrderStatus.Released]: <Done color={'success'} />,
    [WorkOrderStatus.Completed]: <Check color={'success'} />,
    [WorkOrderStatus.Created]: <Check />
  }

  return (
    <IconWithLabel
      sx={props.sx}
      icon={icons[props.status as string] ?? <Remove />}
      iconOnly={props.iconOnly}
      label={t(`shiftEvent.workOrderStatus.${props.status}`)}
    />
  )
}
