// add here flags that should be on for all environments
import {FeatureFlag} from '@hconnect/common/types'

import {FeatureFlagKey} from '../../types/shiftHandover.types'

const canadaPlants = ['C102', 'C203', 'C205']
const usaPlants = [
  'U007',
  'U020',
  'U100',
  'U116',
  'U137',
  'U145',
  'U150',
  'U155',
  'U157',
  'U158',
  'U159',
  'U160',
  'U174',
  'U175',
  'U256'
]
export const checklistsWhitelist = [canadaPlants, usaPlants].flat()

// usually at the end of the live-cycle of the feature flag, short before removal
const defaultFlags: FeatureFlag<FeatureFlagKey> = {
  sapMaintenanceNotification: {whitelist: [], blacklist: []},
  inforMaintenanceNotification: {whitelist: [], blacklist: []},
  notifications: {whitelist: [], blacklist: []},
  productionVolumes: {whitelist: [], blacklist: []},
  movieAttachment: {whitelist: [], blacklist: []},
  mainEquipmentList: {whitelist: ['U100', 'U145', 'U160'], blacklist: []}
}

// define here, which Flags should be enabled for each REACT_APP_STAGE
const stageSpecific: Record<string, FeatureFlag<FeatureFlagKey>> = {
  qa: {
    allProcessStagesSummary: {whitelist: ['HU11'], blacklist: []},
    expertSystem: {whitelist: [], blacklist: []},
    checklists: {whitelist: checklistsWhitelist, blacklist: []},
    enableSplitStoppage: {whitelist: [], blacklist: []}
  },
  uat: {
    allProcessStagesSummary: {whitelist: ['HU11'], blacklist: []},
    checklists: {whitelist: checklistsWhitelist, blacklist: []},
    enableSplitStoppage: {whitelist: [], blacklist: []}
  },
  prod: {
    allProcessStagesSummary: {whitelist: ['HU11'], blacklist: []},
    checklists: {whitelist: checklistsWhitelist, blacklist: []},
    enableSplitStoppage: {whitelist: [], blacklist: []}
  }
}

// should be used by the config request function only
export const getDefaultFeatureFlags = (baseFlags = defaultFlags) => {
  const stage = process.env.REACT_APP_STAGE
  const stageSpecificFlags = (stage && stageSpecific[stage]) || {}

  // TBD add here a way for the mock backend to override it
  // that way we could remove it from the config handling
  // which is currently used to inject the MockFlags into the system via the config endpoint
  // and create a provider instead

  return {...stageSpecificFlags, ...baseFlags}
}
