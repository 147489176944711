import {ContentMode} from '@hconnect/common/components/ContentBox'
import {MaterialStorageContainer} from '@hconnect/common/components/materialStorageContainer'
import {MaterialStorage, MaterialVM} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Stack, Tooltip, Typography} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useMaterialStorage} from '../../hooks/api/useMaterialStorage'
import {useConfig} from '../../hooks/useConfig'
import {TimeRange} from '../../types/timeAndDate.types'
import {formatDateTime} from '../../utils'

type Props = {
  contentMode: ContentMode
  timeRange: TimeRange
  isSingleShift?: boolean
  materials?: MaterialVM[]
}

export const sortMaterialStorage = (data: MaterialStorage[]) =>
  data.sort((a, b) => {
    if (
      a.capacity === 0 &&
      a.currentStorageLevel.level === 0 &&
      b.capacity === 0 &&
      b.currentStorageLevel.level === 0
    ) {
      return 0
    } else if (a.capacity === 0 && a.currentStorageLevel.level === 0) {
      return 1
    } else if (b.capacity === 0 && b.currentStorageLevel.level === 0) {
      return -1
    } else if (a.currentStorageLevel.level === 0 && b.currentStorageLevel.level === 0) {
      return b.capacity - a.capacity
    } else if (a.currentStorageLevel.level === 0) {
      return 1
    } else if (b.currentStorageLevel.level === 0) {
      return -1
    } else {
      const fillLevelA = a.currentStorageLevel.level / a.capacity
      const fillLevelB = b.currentStorageLevel.level / b.capacity
      return fillLevelB - fillLevelA
    }
  })

export const SiloFillsContainer: React.FC<Props> = ({
  contentMode,
  timeRange,
  isSingleShift,
  materials
}) => {
  const {endDate: snapshotDateTime} = timeRange
  const {
    data: materialStorageData,
    isLoading: isMaterialStorageDataLoading,
    error: materialStorageDataError
  } = useMaterialStorage(snapshotDateTime, {
    select: sortMaterialStorage
  })

  const {t} = useTranslation()
  const {timezone} = useConfig()

  return (
    <Tooltip
      placement="top"
      title={
        !isSingleShift ? (
          <Stack>
            <Typography {...dataTestId('silo_fill_level_information_tooltip')}>
              {t('productionVolume.label.siloFillLevelForTheLatestShift')}
            </Typography>
            {formatDateTime(moment.utc(snapshotDateTime).tz(timezone))}
          </Stack>
        ) : (
          ''
        )
      }
    >
      <span>
        <MaterialStorageContainer
          contentMode={contentMode}
          materials={materials}
          materialStorageData={materialStorageData}
          isLoading={isMaterialStorageDataLoading}
          error={materialStorageDataError}
        />
      </span>
    </Tooltip>
  )
}
