import {Shift, ShiftInput} from '@hconnect/common/types/shift.types'
import type MockAdapter from 'axios-mock-adapter/types'
import {isArray} from 'lodash'

import {mockStore} from '../mockStoreSingleton'

export const enableShiftMock = (apiMockAdapter: MockAdapter) => {
  apiMockAdapter.onPost(/\/shifthandover\/\w+\/shifts$/).reply((options) => {
    const mockState = mockStore.scenario()
    const inputData = typeof options.data === 'string' && JSON.parse(options.data)
    const shiftInput =
      isArray(inputData) && 'weekDays' in inputData[0] && (inputData as ShiftInput[])

    if (mockState.config.shifts?.length > 0) {
      return [406, 'the shift system is already setup']
    }

    if (!shiftInput) {
      return [400, 'invalid request structure']
    }

    // TODO improve the overlap check, it is simplified just for the cypress tests
    if (
      shiftInput.length > 1 &&
      shiftInput[0].start === shiftInput[1].start &&
      shiftInput[0].weekDays[0] === shiftInput[1].weekDays[0]
    ) {
      return [400, 'overlap of two shifts']
    }

    const shifts: Shift[] = shiftInput.map((shift, id) => {
      // WARNING the values are not mapped correctly , check out ../data/shifts.ts for more details
      return {
        ...shift,
        id,
        endTechnical: shift.end,
        endTechnicalUTC: shift.end,
        endUTC: shift.end,
        startUTC: shift.start
      }
    })

    mockState.config.shifts = shifts
    mockStore.setScenarioData(mockState)

    return [200, shifts]
  })
}
