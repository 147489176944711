import {AxiosError} from 'axios'
import React, {ErrorInfo} from 'react'

import {HLog} from '../../common/utils/logging'
import {ErrorPage} from '../../pages/ErrorPage'

type ErrorTypes = AxiosError | Error

export type HttpErrorHandler = (err: ErrorTypes) => void

export const HttpErrorHandlerContext = React.createContext<HttpErrorHandler | undefined>(undefined)

export const useErrorHandler = () => {
  const ctx = React.useContext(HttpErrorHandlerContext)
  if (ctx === undefined) {
    throw new Error('useErrorHandler called outside ErrorHandler context')
  } else return ctx
}

export type HttpErrorHandlingComponentProps = {
  error: ErrorTypes | undefined
}

export function withHttpErrorHandler<TProps>(
  Component: React.ComponentType<TProps & HttpErrorHandlingComponentProps>
) {
  return class HttpErrorWrapper extends React.Component<
    Omit<TProps, 'error'>,
    {error: ErrorTypes | undefined}
  > {
    private readonly errorHandler: HttpErrorHandler

    constructor(props: Omit<TProps, 'error'>) {
      super(props)
      this.state = {error: undefined}
      this.errorHandler = (error: ErrorTypes) => {
        this.setState({error})
      }
    }

    render() {
      return (
        <HttpErrorHandlerContext.Provider value={this.errorHandler}>
          <Component {...(this.props as TProps)} error={this.state.error} />
        </HttpErrorHandlerContext.Provider>
      )
    }
  }
}

export class GlobalErrorBoundary extends React.Component<
  Record<string, unknown>,
  {error: ErrorTypes | undefined}
> {
  /**
   * This is the default HttpErrorHandler, i.e. whenever an HTTP error occurs and is not handled
   * by the calling component, we handle it globally and show the HTTP error details on the
   * global error page
   */
  private readonly errorHandler: HttpErrorHandler

  constructor(props: Record<string, unknown>) {
    super(props)
    this.state = {error: undefined}

    this.errorHandler = (err: ErrorTypes) => {
      this.setState({error: err})
      HLog('cockpit').error(err)
    }
  }

  static getDerivedStateFromError(error) {
    return {hasError: true, error}
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    HLog('cockpit').extras({info}).error(error)
  }

  render() {
    if (this.state.error !== undefined) {
      return (
        <div onClick={() => this.setState({error: undefined})}>
          <ErrorPage error={this.state.error} />
        </div>
      )
    } else {
      return (
        <HttpErrorHandlerContext.Provider value={this.errorHandler}>
          {this.props.children as React.ReactNode}
        </HttpErrorHandlerContext.Provider>
      )
    }
  }
}
