import {ExpandableListItem} from '@hconnect/uikit/src/lib2'
import {Add as AddIcon} from '@mui/icons-material'
import {Divider, Box, Typography, Button} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {EventListGroup, generalPinnedGroups, maintenancePinnedGroups} from '../../consts'
import {Group} from '../../hooks/api/useShiftSummaryEvents'
import {EventSummaryComponent} from '../../layouts/EventSummaryComponent'
import {ShiftEvent} from '../../types/shiftHandover.types'

type Props = {
  title: string
  showCompleteList?: boolean
  openGroups: string[]
  toggleGroup: (id: string) => void
  previousActiveItem?: string | null
  onItemClick: (item: ShiftEvent) => void
  onCreateClick?: (categoryId: EventListGroup, string: string) => void
  isLast?: boolean
} & (
  | {
      id: EventListGroup.General
      events: {
        groupMap: Map<string, Group>
        pinnedGroups: typeof generalPinnedGroups
      }
    }
  | {
      id: EventListGroup.Production
      events: {
        groupMap: Map<string, Group>
        pinnedGroups?: never
      }
    }
  | {
      id: EventListGroup.Maintenance
      events: {
        groupMap: Map<string, Group>
        pinnedGroups: typeof maintenancePinnedGroups
      }
    }
)

export const GroupEventsSummary: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const {
    id: categoryId,
    title,
    events: {groupMap},
    openGroups,
    toggleGroup,
    previousActiveItem,
    onItemClick,
    onCreateClick,
    isLast
  } = props

  const finalEventList = useMemo(() => Array.from(groupMap.entries()), [groupMap])

  return (
    <Box>
      <Typography variant="h4" mx={1.5} my={2}>
        {title}
      </Typography>
      {finalEventList.length > 0 && <Divider color="light" />}
      {finalEventList.map(([groupName, group], groupIndex) => {
        const isOpen = openGroups?.includes(group.id)
        if (
          !props.showCompleteList &&
          group.list.length === 0 &&
          !props.events?.pinnedGroups?.includes(groupName)
        )
          return null

        return (
          <ExpandableListItem
            data-test-id={`summary-${categoryId}-group-${group.id}`}
            key={group.id}
            id={group.id}
            action={
              onCreateClick && (
                <Button
                  variant="text"
                  data-test-id={`summary-${categoryId}-group-${group.id}-event-create`}
                  onClick={() => {
                    onCreateClick(categoryId, group.id)
                  }}
                  startIcon={<AddIcon />}
                >
                  {t('eventsTable.action.createEvent')}
                </Button>
              )
            }
            showActionOnHover={true}
            isOpen={isOpen}
            toggleState={toggleGroup}
            label={`${group.label} (${group.list.length})`}
            showDivider={
              !(isLast && finalEventList.length === groupIndex + 1 && group.list.length < 0)
            }
            content={
              group.list.length !== 0
                ? group.list.map((item, i) => (
                    <React.Fragment key={item.id}>
                      <EventSummaryComponent
                        sx={{
                          ...(previousActiveItem === item.id && {
                            animation: 'scrollToMarker 3s',
                            '@keyframes scrollToMarker': {
                              '0%': {
                                backgroundColor: '#e5f4ff'
                              },
                              '30%': {
                                backgroundColor: '#e5f4ff'
                              },
                              '100%': {
                                backgroundColor: 'transparent'
                              }
                            }
                          })
                        }}
                        data-test-id={`summary-group-item-${i}`}
                        item={item}
                        onClick={onItemClick}
                      />
                      {isLast && group.list.length === i + 1 && <Divider color="light" />}
                    </React.Fragment>
                  ))
                : undefined
            }
          />
        )
      })}
    </Box>
  )
}
