import {useProcessStages} from '@hconnect/common/hooks/useProcessStages'
import {EquipmentData} from '@hconnect/common/types'
import {TFunction} from 'i18next'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {FilterOptions} from '../../common/utils/filterParameter'
import {
  ELECTRICAL,
  ENVIRONMENT,
  HEALTH_AND_SAFETY,
  MECHANICAL,
  OTHER,
  QUALITY,
  SHIFT_SUMMARY_EVENTS_PAGE_SIZE
} from '../../consts'
import {ShiftEvent, SortBy} from '../../types/shiftHandover.types'
import {useConfig} from '../useConfig'
import {useFeatureFlag} from '../useFeatureFlag'
import {useOnPageWithoutUnmountFocus} from '../useOnPageWithoutUnmountFocus'

import {useEventsSearch} from './useEventsSearch'
import {useMainEquipments} from './useMainEquipments'

export type Group = {
  id: string
  label: string
  list: ShiftEvent[]
}

type GroupMap = Map<string, Group>

const getGeneralGroupingMap = (t: TFunction) => {
  const map = new Map<string, Group>()

  map.set(HEALTH_AND_SAFETY, {
    label: t('shiftEvent.eventType.healthAndSafety'),
    id: HEALTH_AND_SAFETY,
    list: []
  })
  map.set(ENVIRONMENT, {
    label: t('shiftEvent.category.environment'),
    id: ENVIRONMENT,
    list: []
  })
  map.set(QUALITY, {
    label: t('shiftEvent.category.quality'),
    id: QUALITY,
    list: []
  })
  return map
}

const getProductionGroupingMap = (t: TFunction, productionListMap: Map<string, string>) => {
  const names = [...productionListMap.values()]
  const keys = [...productionListMap.keys()]
  const map = new Map<string, Group>()

  names.forEach((label, i) => {
    const id = keys[i]
    map.set(id, {
      label,
      id,
      list: []
    })
  })

  map.set(OTHER, {
    label: t('shiftEvent.category.other'),
    id: OTHER,
    list: []
  })

  return map
}

const getMaintenanceGroupingMap = (t: TFunction) => {
  const map = new Map<string, Group>()
  map.set(MECHANICAL, {
    label: t('shiftEvent.category.mechanical'),
    id: MECHANICAL,
    list: []
  })
  map.set(ELECTRICAL, {
    label: t('shiftEvent.category.electrical'),
    id: ELECTRICAL,
    list: []
  })
  return map
}

const grouping = (
  events: ShiftEvent[],
  productionListMap: Map<string, string>,
  t: TFunction,
  showSummaryPageMainEquipmentEvents: boolean
): {
  generalMap: GroupMap
  productionMap: GroupMap
  maintenanceMap: GroupMap
} => {
  const generalMap = getGeneralGroupingMap(t)
  const productionMap = getProductionGroupingMap(t, productionListMap)
  const maintenanceMap = getMaintenanceGroupingMap(t)

  events.forEach((event: ShiftEvent) => {
    if (event.eventType === HEALTH_AND_SAFETY || event.category === 'healthAndSafety') {
      generalMap.get(HEALTH_AND_SAFETY)?.list.push(event)
    } else if (event.category === QUALITY || event.eventType === QUALITY) {
      generalMap.get(QUALITY)?.list.push(event)
    } else if (event.category === ENVIRONMENT || event.eventType === ENVIRONMENT) {
      generalMap.get(ENVIRONMENT)?.list.push(event)
    } else if (event.category === MECHANICAL || event.category === ELECTRICAL) {
      maintenanceMap.get(event.category)?.list.push(event)
    } else if (!showSummaryPageMainEquipmentEvents && event.processStage) {
      productionMap.get(event.processStage)?.list.push(event)
    } else if (showSummaryPageMainEquipmentEvents && event.mainEquipment) {
      productionMap.get(event.mainEquipment.id)?.list.push(event)
    } else {
      productionMap.get(OTHER)?.list.push(event)
    }
  })

  return {generalMap, productionMap, maintenanceMap}
}

export const useShiftSummaryEvents = (
  options: FilterOptions
): [
  generalGroup: GroupMap,
  productionGroup: GroupMap,
  maintenanceGroup: GroupMap,
  isLoading: boolean,
  totalItemCount: number
] => {
  const {plantId} = useConfig()
  const {refetch, ...eventQuery} = useEventsSearch(
    options,
    SortBy.Details,
    undefined,
    0,
    SHIFT_SUMMARY_EVENTS_PAGE_SIZE,
    {
      refetchOnWindowFocus: false
    }
  )
  useOnPageWithoutUnmountFocus(refetch)

  const showSummaryPageMainEquipmentEvents = useFeatureFlag('mainEquipmentList')

  const processStages = useProcessStages(plantId, {
    enabled: !showSummaryPageMainEquipmentEvents
  })
  const {t} = useTranslation()

  const mainEquipmentsQuery = useMainEquipments({
    enabled: showSummaryPageMainEquipmentEvents
  })

  const isLoading =
    eventQuery.isLoading ||
    eventQuery.isFetching ||
    processStages.isLoading ||
    processStages.isFetching ||
    mainEquipmentsQuery.isLoading ||
    mainEquipmentsQuery.isFetching

  return useMemo(() => {
    if (
      !eventQuery.data ||
      (showSummaryPageMainEquipmentEvents ? !mainEquipmentsQuery.data : !processStages.data)
    ) {
      return [new Map(), new Map(), new Map(), isLoading, 0]
    }

    const {generalMap, productionMap, maintenanceMap} = grouping(
      eventQuery.data.events,
      showSummaryPageMainEquipmentEvents
        ? new Map(
            (mainEquipmentsQuery.data || []).map((mainEquipment: EquipmentData) => {
              return [mainEquipment.id, mainEquipment.text || mainEquipment.id]
            })
          )
        : processStages.data || new Map<string, string>(),
      t,
      showSummaryPageMainEquipmentEvents
    )
    const totalItemCount = eventQuery.data.totalItemCount

    return [generalMap, productionMap, maintenanceMap, isLoading, totalItemCount]
  }, [
    eventQuery.data,
    processStages.data,
    mainEquipmentsQuery.data,
    showSummaryPageMainEquipmentEvents,
    t,
    isLoading
  ])
}
