/** @jsxImportSource @emotion/react */
import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {FlexPage} from '@hconnect/common/components/FlexPage'
import {
  noSideCard,
  sideCardSize,
  tableWithOutSideCard,
  tableWithSideCard,
  withSideCard
} from '@hconnect/common/consts'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {CardBox} from '@hconnect/uikit/src/lib2'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {Button, Grid, Box, useTheme} from '@mui/material'
import React, {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, Link} from 'react-router-dom'

import {
  FilterOptions,
  recurrenceTasksFilterOptions,
  RecurrenceTasksFilterOptionsKeys
} from '../../common/utils/filterParameter'
import {useConfig} from '../../hooks/useConfig'
import {EventsFilterBar} from '../../layouts/FilterBar'
import {routeToTasks} from '../../routes'
import {getActionContentSXWithFilter} from '../../styles/common'
import {ShiftEvent} from '../../types/shiftHandover.types'
import {SideCard} from '../common/SideCard'

import {RecurringTasksProps, RecurringTasksTable} from './RecurringTasksTable'

const SecondaryTitle: FC = () => {
  const {t} = useTranslation()
  const {plantId} = useConfig()
  const theme = useTheme()
  return (
    <Link
      css={{
        marginBottom: theme.spacing(-3),
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        display: 'block',
        textDecoration: 'none',
        width: 'fit-content'
      }}
      to={generatePath(routeToTasks, {plantId})}
    >
      <Button
        variant="text"
        startIcon={<ChevronLeftIcon />}
        sx={{
          textTransform: 'none',
          color: '#fff',
          opacity: 0.6,
          fontSize: 16,
          fontWeight: 400
        }}
        data-test-id="recurring-task-page-back-to-task-button"
      >
        {t('recurringTasks.buttons.backToTasks')}
      </Button>
    </Link>
  )
}

type RecurringTasksViewProps = RecurringTasksProps & {
  setViewMode: (mode: ViewMode<ShiftEvent>) => void
  onSideCardClose: () => void
  selectedSchedule?: ShiftEvent
  activeFilter: FilterOptions
  onFilterChange: (next: FilterOptions) => void
}

export const RecurringTasksView: FC<RecurringTasksViewProps> = ({
  isLoading,
  data,
  paginationOptions,
  openDetailsMode,
  onSideCardClose,
  selectedSchedule,
  activeFilter,
  onFilterChange,
  viewMode,
  setViewMode
}) => {
  const {t} = useTranslation()
  const breakPoint = useWidth()
  const [isMobileFilterOpen, openMobileFilter] = useState(false)

  const isSideCardShown = viewMode.mode !== 'tableOnlyMode'
  const isSmall = breakPoint === 'xs' || breakPoint === 'sm'
  const tableSize = (isSideCardShown ? withSideCard : noSideCard).get(breakPoint) ?? 'S'
  const isTableShown = !isSideCardShown || !isSmall

  return (
    <FlexPage
      appName="Cockpit"
      title={
        isMobileFilterOpen ? (
          t('shiftEvent.action.filter')
        ) : (
          <Box component={'span'} ml={3}>
            {t('recurringTasks.pageName')}
          </Box>
        )
      }
      aboveTitle={isMobileFilterOpen ? '' : <SecondaryTitle />}
      sxActionContent={getActionContentSXWithFilter(isMobileFilterOpen)}
      headerActionContent={
        isTableShown && (
          <EventsFilterBar<RecurrenceTasksFilterOptionsKeys>
            activeSettings={activeFilter}
            isCollapsible={isSmall}
            options={recurrenceTasksFilterOptions}
            onChange={onFilterChange}
            openMobileFilter={openMobileFilter}
            isMobileFilterOpen={isMobileFilterOpen}
            hideDateFilter={true}
          />
        )
      }
      useViewportHeight={!isSmall}
    >
      {!isMobileFilterOpen && (
        <Grid container flexGrow={1} overflow={'hidden'} spacing={2}>
          {isTableShown && (
            <Grid
              item
              height={'100%'}
              {...(isSideCardShown ? tableWithSideCard : tableWithOutSideCard)}
            >
              <CardBox sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <RecurringTasksTable
                  tableSize={tableSize}
                  isLoading={isLoading}
                  data={data}
                  paginationOptions={paginationOptions}
                  openDetailsMode={openDetailsMode}
                  viewMode={viewMode}
                />
              </CardBox>
            </Grid>
          )}

          {isSideCardShown && (viewMode.mode as string) !== 'tableOnlyMode' && (
            <Grid item height={'100%'} {...sideCardSize}>
              <SideCard
                {...viewMode}
                setMode={setViewMode}
                doClose={onSideCardClose}
                item={selectedSchedule}
                disableSap={true}
                isSeriesPreview={true}
              />
            </Grid>
          )}
        </Grid>
      )}

      <p data-test-id="page-event-mode-indicator" style={{display: 'none'}}>
        {/* shall be used in cypress tests to validate the mode this page is in */}
        {viewMode.mode}
      </p>
    </FlexPage>
  )
}
