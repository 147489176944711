import {StatusWithNoneAndCancellationAndApproverAndWorkOrderStates} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useMutation, UseMutationOptions, useQueryClient} from 'react-query'

import {EventUpdate, ShiftEvent, Status} from '../../types/shiftHandover.types'
import {useConfig} from '../useConfig'

import {updateEvent} from './updateEvent'
import {useApi} from './useApi'
import {QueryKey as EventsQueryKey} from './useEvents'
import {QueryKey as EventsSearchQueryKey} from './useEventsSearch'

type Variables<S> = {
  task: ShiftEvent
  status: S
}

export const useEventUpdateStatus = <
  S extends StatusWithNoneAndCancellationAndApproverAndWorkOrderStates = Status
>(
  options?: UseMutationOptions<ShiftEvent, AxiosError, Variables<S>>
) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId, timezone} = useConfig()

  return useMutation<ShiftEvent, AxiosError, Variables<S>, unknown>(
    async ({task, status}) => {
      const updatedEvent = {...task, status} as unknown as EventUpdate
      return updateEvent(axiosInstance, plantId, updatedEvent, timezone)
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        // promise results are not required, hence we can just trigger the promises
        // This is a quick-fix for HCP-23572
        void options?.onSuccess?.(data, variables, context)
        void queryClient.invalidateQueries(EventsSearchQueryKey)
        void queryClient.invalidateQueries(EventsQueryKey)
      }
    }
  )
}
