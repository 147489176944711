import {FeatureDefinition} from '@hconnect/common/types'
import {isFeatureSupported} from '@hconnect/common/utils'
import {useMemo} from 'react'

import {FeatureFlagKey} from '../types/shiftHandover.types'

import {useConfig} from './useConfig'

export const useFeatureFlag = (flag: FeatureFlagKey) => {
  const config = useConfig()
  return useMemo(() => {
    const feature: FeatureDefinition | undefined = config.featureFlags[flag]

    return isFeatureSupported(feature, config.plantId)
  }, [config.featureFlags, config.plantId, flag])
}
