import {AxiosError} from 'axios'
import React from 'react'
import {UseMutationResult} from 'react-query'

import {CreateMaintenanceNotification} from '../../types/maintenanceNotification.types'
import {ShiftEvent} from '../../types/shiftHandover.types'

import {EquipmentUpdateDialog} from './EquipmentUpdateDialog'

type Props = {
  event: ShiftEvent
  showDialog: boolean
  setDialog: (state: boolean) => void
  onNotificationCreated(): void
  onError?()
  createNotification: UseMutationResult<string, AxiosError, CreateMaintenanceNotification>
}

export const CreateSapMaintenanceNotification: React.FC<Props> = ({
  event,
  showDialog,
  setDialog,
  onError,
  onNotificationCreated,
  createNotification: createNotificationProp
}) => {
  const createNotification = (notification: CreateMaintenanceNotification) => {
    createNotificationProp.mutate(
      {
        ...notification
      },
      {
        onError: onError,
        onSuccess: onNotificationCreated
      }
    )
    setDialog(false)
  }

  return (
    <EquipmentUpdateDialog
      open={showDialog}
      onClose={() => {
        setDialog(false)
      }}
      onConfirm={createNotification}
      event={event}
    />
  )
}
