import {ConfirmationDialog, ErrorDialog} from '@hconnect/common/components/dialogs'
import {ManualKpiWrapper} from '@hconnect/common/components/kpiPerformance/ManualKpiWrapper'
import {ExpandedGroupIds} from '@hconnect/common/hooks/useExpandGroups'
import {ManualKpiToUpdate, ManualKpisProcessStage} from '@hconnect/common/types'
import {Column} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined} from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import {Button} from '@mui/material'
import {AxiosError} from 'axios'
import React, {FC, useCallback, useMemo, useState} from 'react'
import {Control} from 'react-hook-form'
import {Trans, useTranslation} from 'react-i18next'

import {useProcessStagesForManualKpisDelete} from '../../hooks/api/kpis'
import {KpiFormData} from '../../types/kpi.types'
import {generateEditColumns} from '../../utils'
import {MenuButton, MenuConfig} from '../common/Menu'

import {ManualKpiCreateDialog} from './ManualKpiCreateDialog'

type ManualKpiEditProps = {
  manualKpiList: ManualKpiToUpdate[]
  setManualKpiList: (manualKpiList: ManualKpiToUpdate[]) => void
  processStages: ManualKpisProcessStage[]
  isProcessStagesLoading: boolean
  openProcessStages: string[]
  setOpenProcessStages: (id: ExpandedGroupIds) => void
  control: Control<KpiFormData>
  updateError: AxiosError | null
  updateReset: () => void
}

export const ManualKpiEdit: FC<ManualKpiEditProps> = ({
  processStages,
  manualKpiList,
  setManualKpiList,
  isProcessStagesLoading,
  openProcessStages,
  setOpenProcessStages,
  control,
  updateError,
  updateReset
}) => {
  const {t} = useTranslation()

  const [activeProcessStage, setActiveProcessStage] = useState<string>()
  const [processStageToDelete, setProcessStageToDelete] = useState<ManualKpisProcessStage>()

  const {
    mutate: deleteProcessStage,
    isLoading: isDeletingProcessStage,
    error: deleteProcessStageError,
    reset: resetProcessStageDeletionState
  } = useProcessStagesForManualKpisDelete({
    onSuccess: () => {
      setProcessStageToDelete(undefined)
    }
  })

  const getMenuConfig = useCallback<(processStage: ManualKpisProcessStage) => MenuConfig>(
    (processStage: ManualKpisProcessStage) => [
      {
        dataTestId: 'process-stage-delete-button',
        label: t('action.delete'),
        onClick: () => {
          setProcessStageToDelete(processStage)
        },
        icon: <DeleteOutlined sx={{mr: 2, verticalAlign: 'text-bottom'}} />
      }
    ],
    [t]
  )

  const addManualKpi = (data: ManualKpiToUpdate) => {
    if (activeProcessStage) {
      setManualKpiList([...manualKpiList, {...data, processStage: activeProcessStage}])
    }
  }

  const removeManualKpi = useCallback(
    (processStageId: string, name: string) => {
      const newList = manualKpiList.filter(
        (kpi) => !(kpi.name === name && kpi.processStage === processStageId)
      )
      setManualKpiList(newList)
    },
    [manualKpiList, setManualKpiList]
  )

  const columns = useMemo<Column<ManualKpiToUpdate>[]>(
    () => generateEditColumns(t, control, removeManualKpi),
    [t, control, removeManualKpi]
  )

  const kpiNames = useMemo(
    () =>
      manualKpiList
        .filter(({processStage}) => processStage === activeProcessStage)
        .map((kpi) => kpi.name),
    [manualKpiList, activeProcessStage]
  )

  return (
    <>
      <ManualKpiWrapper
        getExpandableListItemAction={(code, name) => (
          <MenuButton
            config={getMenuConfig({code, name})}
            menuDataTestId={`process-stage-more-menu-${code}`}
            data-test-id={`process-stage-more-button-${code}`}
          />
        )}
        getSectionBottomAction={(code) => (
          <Button
            onClick={() => setActiveProcessStage(code)}
            startIcon={<AddIcon />}
            variant="text"
            data-test-id={`performance-new-kpi-button-${code}`}
          >
            {t('performance.label.createNewKpi')}
          </Button>
        )}
        setOpenProcessStages={setOpenProcessStages}
        openProcessStages={openProcessStages}
        isProcessStagesLoading={isProcessStagesLoading}
        processStages={processStages}
        manualKpiList={manualKpiList}
        columns={columns}
      />

      <ManualKpiCreateDialog
        onSave={addManualKpi}
        isDialogOpen={!!activeProcessStage}
        onClose={() => setActiveProcessStage(undefined)}
        existingKpiNames={kpiNames}
      />

      {updateError && (
        <ErrorDialog
          httpCode={updateError.code}
          statusCode={updateError?.response?.status}
          onClose={() => {
            updateReset()
          }}
          message={t('performance.label.saveError')}
          errorText={updateError.message}
        />
      )}
      {deleteProcessStageError && (
        <ErrorDialog
          httpCode={deleteProcessStageError.code}
          onClose={() => {
            resetProcessStageDeletionState()
          }}
          message={t('performance.label.deleteProcessStageError')}
          errorText={deleteProcessStageError.message}
        />
      )}

      {!!processStageToDelete && (
        <ConfirmationDialog
          onDeleteSingle={() => {
            deleteProcessStage(processStageToDelete.code)
          }}
          onClose={() => setProcessStageToDelete(undefined)}
          isSingleLoading={isDeletingProcessStage}
          text={
            <Trans
              i18nkey="performance.label.sureToDelete"
              values={{kpi: processStageToDelete.name}}
            >
              Are you sure you want to delete <strong>{processStageToDelete.name}</strong>? This
              action cannot be undone.
            </Trans>
          }
          isSeriesItem={false}
        />
      )}
    </>
  )
}
