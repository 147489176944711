import {isUndefined, omit} from 'lodash'
import {useCallback, useMemo, useState} from 'react'

import {
  AllFilterOptions,
  FilterOptions,
  filterPageKeys,
  filterSettingsToUrlParams,
  getPageScopedFilters,
  Page
} from '../common/utils/filterParameter'
import {plantTimezonedDateRangeValidator} from '../utils'

import {useConfig} from './useConfig'
import {useQueryParamValue} from './useQueryParamValue'

export const EVENT_FILTERS = 'eventsFilters'

type PageFiltersProps = [FilterOptions, (filterOptions: FilterOptions) => void]

export const usePageFilters = (
  page: Page,
  defaultFilters?: Partial<AllFilterOptions>
): PageFiltersProps => {
  const {timezone} = useConfig()
  const keys = useMemo(() => ['timeRange', ...filterPageKeys[page]], [page])
  const [eventFilters = '', setEventFilters] = useQueryParamValue(EVENT_FILTERS, keys, {
    keepInLocalStorage: true
  })

  const [filters, setFilters] = useState<FilterOptions>(
    getPageScopedFilters(eventFilters, page, timezone, defaultFilters)
  )

  const setFilterValue = useCallback(
    (filterOptions: FilterOptions) => {
      if (Object.values(filterOptions).every(isUndefined)) {
        setEventFilters('')
        setFilters({})
        return
      }
      const filtersWithoutFreeText = omit(filterOptions, 'freeText')
      plantTimezonedDateRangeValidator(timezone, filtersWithoutFreeText.timeRange)
      setEventFilters(filterSettingsToUrlParams(filtersWithoutFreeText).toString())
      setFilters(filterOptions)
    },
    [setEventFilters, timezone]
  )

  return [filters, setFilterValue]
}
