import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions, useQueryClient} from 'react-query'

import {mapShiftEvent} from '../../mappers'
import {SapStatusCode} from '../../types/maintenanceNotification.types'
import {EventSearchResult, ShiftEvent, ShiftEventDto} from '../../types/shiftHandover.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'
import {QueryKey as EventsSearchQueryKey} from './useEventsSearch'

export const QueryKey = 'events-events'
const INTERVAL_PERIOD_IN_MS = 30000

type UseEventOptions = Omit<UseQueryOptions<ShiftEvent | undefined, AxiosError>, 'initialData'>

export const useEvent = (eventId: string | undefined, options?: UseEventOptions) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId, timezone} = useConfig()
  const path = `/shifthandover/${plantId}/events/${eventId}`

  return useQuery<ShiftEvent | undefined, AxiosError>(
    [QueryKey, eventId, plantId, timezone],
    async () => {
      if (eventId === undefined) return undefined
      const response = await axiosInstance.get<ShiftEventDto>(path)
      return mapShiftEvent(response.data, timezone)
    },
    {
      refetchInterval: (data) =>
        data?.sapStatusCode === SapStatusCode.SUBMITTED ? INTERVAL_PERIOD_IN_MS : false,
      ...options,
      initialData: () => {
        // https://react-query.tanstack.com/guides/initial-query-data#initial-data-from-cache
        return queryClient
          .getQueryData<EventSearchResult>(EventsSearchQueryKey, {exact: false})
          ?.events.find((event: ShiftEvent) => event.id === eventId)
      }
    }
  )
}

export const useEvents = (
  eventsPerPage = 50,
  pageNumber = 0,
  options?: UseQueryOptions<ShiftEvent[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  const path = `/shifthandover/${plantId}/events`
  const params = {params: {eventsPerPage, pageNumber}}

  return useQuery<ShiftEvent[], AxiosError>(
    [QueryKey, eventsPerPage, pageNumber, plantId],
    async () => {
      const response = await axiosInstance.get<ShiftEvent[]>(path, params)
      return response.data
    },
    options
  )
}
