import {EventStatusLabel} from '@hconnect/common/components/eventProperties/EventStatusLabel'
import {CommonDropDown, CommonProps} from '@hconnect/common/components/shiftEventFormFields'
import React, {useMemo} from 'react'

import {useGlobalContext} from '../../hooks/useGlobalContext'
import {
  statusList,
  statusListApprovalStates,
  StatusListWithApproverStates,
  ShiftEvent
} from '../../types/shiftHandover.types'

const shouldDisableStatusfield = (
  isApprovalNeeded: boolean,
  editMode: boolean,
  isOwner: boolean,
  isApprover: boolean
) => isApprovalNeeded && editMode && ((isOwner && !isApprover) || !isApprover)

export const ParameterChangeStatusDropDown = ({
  eventData,
  isEditMode,
  onChange,
  errorText,
  disabled,
  ...rest
}: CommonProps<StatusListWithApproverStates> & {
  eventData: ShiftEvent
  isEditMode: boolean
  errorText?: string
}): React.ReactElement => {
  const {status: value, createdBy} = eventData
  const approvers = ('parameterChange' in eventData && eventData.parameterChange?.approvers) || []
  const isApprovalNeeded = !!(
    'parameterChange' in eventData && eventData.parameterChange?.needsApproval
  )
  const {user} = useGlobalContext()
  const options = isApprovalNeeded ? statusListApprovalStates : statusList
  const isOwner = createdBy ? createdBy.id === user.userId : true
  const onlyWaitingForApprovalStatus = !isEditMode || isOwner
  const isApprover = !!approvers.find(({id}) => id === user.userId)
  const disableStatusField = shouldDisableStatusfield(
    isApprovalNeeded,
    isEditMode,
    isOwner,
    isApprover
  )

  const excluded = useMemo<StatusListWithApproverStates[]>(() => {
    if (isApprover) {
      return ['waitingForApproval']
    }
    if (onlyWaitingForApprovalStatus) {
      return ['approved', 'rejected']
    }
    return []
  }, [isApprover, onlyWaitingForApprovalStatus])

  return (
    <CommonDropDown<StatusListWithApproverStates>
      {...rest}
      disabled={disableStatusField || disabled}
      data-test-id="event-form-status"
      errorText={errorText}
      onChange={onChange}
      required
      value={value as StatusListWithApproverStates}
      hiddenOptions={excluded}
      keyName="status"
      options={options}
      customTemplate={(status) => {
        return <EventStatusLabel status={status} />
      }}
    />
  )
}
