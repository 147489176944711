import {ProcessStageTitle} from '@hconnect/common/components/shiftEventLabels'
import {useProcessStages} from '@hconnect/common/hooks/useProcessStages'
import React from 'react'

import {useConfig} from '../../hooks/useConfig'

export const EventProcessStageTitle: React.FC<{stage?: string}> = ({stage}) => {
  const {plantId} = useConfig()
  const stages = useProcessStages(plantId).data
  return <ProcessStageTitle stages={stages} stage={stage} />
}
