import moment, {Moment} from 'moment-timezone'
import {useCallback, useMemo} from 'react'
import {useLocalStorage} from 'react-use'

import {DateUTC} from '../types/atomic.types'

import {useConfig} from './useConfig'

export enum PageList {
  Instructions = 'Instructions'
}

export const getLastVisitedKey = (page: PageList) => {
  return `${PageList[page]}_last_visited`
}

export const useLastVisited = (page: PageList): [Moment | undefined, () => void] => {
  const {timezone} = useConfig()

  const [lastVisited, setLastVisited] = useLocalStorage<DateUTC | undefined>(
    getLastVisitedKey(page)
  )

  const setMomentLastVisited = useCallback(() => {
    const date = moment().utc().toISOString()
    setLastVisited(date)
  }, [setLastVisited])

  const useMomentLastVisitedState: [Moment | undefined, () => void] = [
    useMemo(
      () => (lastVisited ? moment(lastVisited).tz(timezone) : undefined),
      [lastVisited, timezone]
    ),
    setMomentLastVisited
  ]

  return useMomentLastVisitedState
}
