import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import {categories, Category} from '@hconnect/common/types'
import React from 'react'

import {SelectAllButton} from '../common/SelectAllButton'

import {CommonFilterDropDownProps, FilterDropDown} from './FilterDropDown'

export const CategoryFilterDropDown: React.FC<CommonFilterDropDownProps<Category>> = (props) => {
  const {selectAll} = props
  return (
    <FilterDropDown
      {...props}
      options={categories}
      keyName="category"
      fullWidth={false}
      customDropDownFooter={(keyName) =>
        props.isCollapsible ? null : (
          <>
            <SelectAllButton
              keyName={keyName}
              selectAll={() => selectAll && selectAll(keyName, categories as unknown as Category[])}
            />
            <RemoveFilterButton keyName={keyName} removeFilter={props.removeFilter} />
          </>
        )
      }
    />
  )
}
