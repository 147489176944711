import {IconWithLabel} from '@hconnect/common/components/shiftEventLabels/IconWithLabel'
import {Priority} from '@hconnect/common/types'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

type Props = {
  priority: Priority
}

export const PriorityIcon: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  return (
    <IconWithLabel
      icon={
        <Box
          component="span"
          sx={{
            width: '24px',
            height: '24px',
            background: 'rgba(218, 9, 1, 0.1)',
            border: '1px solid rgba(218, 9, 1, 0.2)',
            borderRadius: '50%',
            color: 'rgb(218, 9, 1)',
            fontSize: '14px',
            fontWeight: 600,
            display: 'inline-block',
            textAlign: 'center'
          }}
        >
          !
        </Box>
      }
      iconOnly
      label={t('shiftEvent.label.priority') + ': ' + t(`shiftEvent.priority.${props.priority}`)}
    />
  )
}
