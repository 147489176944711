import {PermissionType} from '@hconnect/apiclient'
import {plantIdCheckSucceed} from '@hconnect/common/utils'
import {useMemo} from 'react'

import {useGlobalContext} from './useGlobalContext'

/**
 * returns a helper function to validate multiple permissions
 */
export function useHasPermission() {
  const {permissions: dfPermissions, config} = useGlobalContext()
  const plantId = config.plantId

  return useMemo(() => {
    // filter out all permissions that are set for a different plantId
    const permissionTypes = dfPermissions.reduce<PermissionType[]>((list, dfPermission) => {
      if (plantIdCheckSucceed(dfPermission.dataScope, plantId)) {
        list.push(dfPermission.permissionType)
      }
      return list
    }, [])

    // helper function
    return (permission: PermissionType) => {
      return permissionTypes.includes(permission)
    }
  }, [dfPermissions, plantId])
}

export function usePermission(permissionType: PermissionType) {
  const hasPermission = useHasPermission()
  return hasPermission(permissionType)
}
