import {decodeAccessToken} from '@hconnect/apiclient'
import {TaskPreviewData, TaskRepetitionUnit} from '@hconnect/common/types'
import {AxiosInstance} from 'axios'
import type MockAdapter from 'axios-mock-adapter/types'
import {DurationInputArg2} from 'moment-timezone'

import {UseRepetitiveTasksParams} from '../../hooks/api/types'
import {EventCreate, EventUpdate} from '../../types/shiftHandover.types'

import {
  scheduleCreate,
  seriesTaskDelete,
  scheduleEdit,
  calculateNextDates,
  scheduleGetAll
} from './taskHelper'

const mapping: Record<TaskRepetitionUnit, DurationInputArg2> = {
  daily: 'days',
  weekly: 'weeks',
  monthly: 'months',
  yearly: 'years'
}

export const enableTasksMock = (apiMockAdapter: MockAdapter, axiosInstance: AxiosInstance) => {
  apiMockAdapter.onPost(/\/shifthandover\/\w+\/search\/taskschedules/).reply((config) => {
    const requestDto: UseRepetitiveTasksParams = JSON.parse(config.data as string)
    return scheduleGetAll(requestDto)
  })

  apiMockAdapter.onPost(/\/shifthandover\/\w+\/tasks\/schedules/).reply(async (config) => {
    const requestDto = JSON.parse(config.data as string) as EventCreate
    return scheduleCreate(requestDto, axiosInstance)
  })

  apiMockAdapter.onPut(/\/shifthandover\/\w+\/tasks\/schedules/).reply(async (config) => {
    const requestDto = JSON.parse(config.data as string) as EventUpdate
    const {user_id: userId} = decodeAccessToken<{user_id: string}>(
      config.headers?.['Authorization'] as string
    )
    return scheduleEdit(requestDto, axiosInstance, userId)
  })

  apiMockAdapter.onDelete(/\/shifthandover\/\w+\/tasks\/schedules/).reply((config) => {
    const taskId = (config.url || '').split('?')[1].split('=')[1]
    const {user_id: userId} = decodeAccessToken<{user_id: string}>(
      config.headers?.['Authorization'] as string
    )
    return seriesTaskDelete(taskId, userId)
  })

  apiMockAdapter.onPost(/\/shifthandover\/\w+\/tasks\/duedatespreview/).reply((config) => {
    const data: TaskPreviewData = JSON.parse(config.data as string)
    const repetition: TaskRepetitionUnit = data.repetition
    const itemsCount: number = data.itemsCount ?? 5

    if (!repetition || !mapping[repetition]) {
      return [420, `parameter "repetition" is missing or invalid (${mapping[repetition]})`]
    }

    const result = calculateNextDates(data, itemsCount)
    return [200, result]
  })
}
