import {sampleProcessStagesEN} from '@hconnect/common/__mock__/processStages'
import {ProcessStage} from '@hconnect/common/types'

const plantId = 'NotUsedJet'

export const sampleProcessStagesDE: ProcessStage[] = [
  {plantId, processStep: '10', processStepValue: '10 - Steinbruch'},
  {plantId, processStep: '20', processStepValue: '20 - Brecher'},
  {plantId, processStep: '30', processStepValue: '30 - Rohmühle'},
  {plantId, processStep: '40', processStepValue: '40 - Brennerei'},
  {plantId, processStep: '50', processStepValue: '50 - Zementmahlung'},
  {plantId, processStep: '60', processStepValue: '60 - Packerei'}
]

export const sampleProcessStages = {
  'de-DE': sampleProcessStagesDE,
  'en-US': sampleProcessStagesEN,
  cimode: sampleProcessStagesEN
}
