import {
  CustomTaskRepetition,
  QuickSelectRepetition,
  TaskRepetition,
  TaskRepetitionUnit
} from '@hconnect/common/types'
import {Moment} from 'moment-timezone'

import {IsoWeekDay, isoWeekDays} from '../../types/atomic.types'

export const getDefaultWeekdays = (startDateString: Moment): IsoWeekDay[] => {
  return [isoWeekDays[startDateString.clone().isoWeekday() - 1]]
}

export const quickSelectRepetition = (repetition?: TaskRepetitionUnit) => {
  const repetitionInfo: TaskRepetition = {
    repetition: repetition || 'weekly'
  }
  return repetitionInfo
}

export const fromQuickSelectToCustomTaskRepetition = (
  current: QuickSelectRepetition,
  startDate: Moment
): CustomTaskRepetition => {
  if (current.repetition === 'daily') {
    return {
      repeatEvery: 1,
      repetition: 'daily',
      startDate
    }
  } // else
  return {
    repeatEvery: 1,
    repetition: 'weekly',
    startDate,
    weekDays: getDefaultWeekdays(startDate)
  }
}

export const changeCustomTaskRepetition = (
  next: 'daily' | 'weekly',
  current: CustomTaskRepetition
): CustomTaskRepetition => {
  const endingsOptions = current.endDate
    ? {endDate: current.endDate}
    : current.endsAfterRepetitions
      ? {endsAfterRepetitions: current.endsAfterRepetitions}
      : {}

  if (next === 'daily') {
    return {
      repeatEvery: current.repeatEvery,
      repetition: 'daily',
      startDate: current.startDate,
      ...endingsOptions
    }
  } // else
  return {
    repeatEvery: current.repeatEvery,
    repetition: 'weekly',
    startDate: current.startDate,
    weekDays: getDefaultWeekdays(current.startDate),
    ...endingsOptions
  }
}
