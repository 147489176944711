import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import {EventProcessStageTitle} from '@hconnect/common/components/shiftEventFormFields'
import {useProcessStages} from '@hconnect/common/hooks/useProcessStages'
import React from 'react'

import {useConfig} from '../../hooks/useConfig'
import {SelectAllButton} from '../common/SelectAllButton'

import {CommonFilterDropDownProps, FilterDropDown} from './FilterDropDown'

export const ProcessStageFilterDropDown: React.FC<CommonFilterDropDownProps<string>> = (props) => {
  const {plantId} = useConfig()
  const stages = useProcessStages(plantId).data
  const options = stages ? Array.from(stages.keys()) : []

  const {removeFilter, isCollapsible, selectAll} = props

  return (
    <FilterDropDown
      {...props}
      options={options}
      keyName="processStage"
      renderValue={(value: string[]) => {
        return value.map((val) => (stages ? stages.get(val) : '')).join(',')
      }}
      customTemplate={(key) => <EventProcessStageTitle plantId={plantId} stage={key} />}
      customDropDownFooter={(keyName) =>
        isCollapsible ? null : (
          <>
            <SelectAllButton
              keyName={keyName}
              selectAll={() => selectAll && selectAll(keyName, options)}
            />
            <RemoveFilterButton keyName={keyName} removeFilter={removeFilter} />
          </>
        )
      }
      fullWidth={false}
    />
  )
}
