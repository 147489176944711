import {WorkCenter} from '@hconnect/common/types'
import React from 'react'

import {WorkCenterSearchHierarchy} from '../../components/eventProperties/WorkCenterSearchHierarchy'

export type Props = {
  label?: React.ReactNode
  errorText?: React.ReactNode
  required?: boolean
  handleWorkCenterSelection: (workCenter: WorkCenter | undefined) => void
  workCenter: WorkCenter | undefined
}

export const WorkCenterSearchContainer = (props: Props) => {
  return (
    <WorkCenterSearchHierarchy
      data-test-id="work-center-search-dropdown"
      errorText={props.errorText}
      onChange={(value) => props.handleWorkCenterSelection(value)}
      value={props.workCenter}
      required={props.required}
    />
  )
}
