import {AxiosInstance} from 'axios'
import type MockAdapter from 'axios-mock-adapter/types'
import moment from 'moment-timezone'

import {
  EventSearchCriteria,
  ShiftEventDto,
  SortBy,
  SortOrder
} from '../../types/shiftHandover.types'
import {mockStore} from '../mockStoreSingleton'

import {getRelevantTimeStamp, getSortedResult} from './sortBy'

export function matchOne<T>(item: ShiftEventDto, filterSettings: T, key) {
  const a = item[key]
  const b = filterSettings[key]

  if (!a || !b) {
    return false
  }

  for (let i = 0; i < b.length; i++) {
    if (a === b[i]) {
      return true
    }
  }
}

export const mockFilter = (events: ShiftEventDto[], filterSettings: EventSearchCriteria) => {
  const filterCriteria = Object.keys(filterSettings) as (keyof EventSearchCriteria)[]

  if (filterCriteria.length === 0) {
    return events
  }

  const startDate = moment(filterSettings.timeRange?.startDate ?? '2000-01-01T00:01:27.045789Z')
  const endDate = moment(filterSettings.timeRange?.endDate ?? '2050-01-01T00:01:27.045789Z')
  const sortBy = filterSettings.sortBy || SortBy.Details
  const sortOrder = filterSettings.sortOrder || SortOrder.Descending

  const result = events.filter((item) => {
    const matchList = filterCriteria.filter((key) => {
      switch (key) {
        case 'timeRange':
          return !!getRelevantTimeStamp(item, sortBy, startDate, endDate)

        case 'equipment':
          return (
            filterSettings.equipment?.id &&
            item.equipment?.id?.startsWith(filterSettings.equipment.id)
          )

        case 'processStage':
        case 'eventType':
        case 'category':
        case 'status':
        case 'priority':
          return matchOne<EventSearchCriteria>(item, filterSettings, key)

        case 'itemsPerPage':
        case 'pageNumber':
        case 'sortBy':
          // to allow an easy comparison if all criteria match, we have to keep thes items even if there are not relevant
          return true

        default:
          throw new Error(`unknown filter option ${key} in mockFilter.ts`)
      }
    })

    // keep only events that match all of the given criteria
    return matchList.length === filterCriteria.length
  })

  return getSortedResult(result, sortBy, sortOrder, startDate, endDate)
}

export const enableEventsSearchMock = (
  apiMockAdapter: MockAdapter,
  axiosInstance: AxiosInstance
) => {
  apiMockAdapter.onPost(/\/shifthandover\/\w+\/search\/events/).reply(async (config) => {
    const requestDto = JSON.parse(config.data as string) as EventSearchCriteria

    // use the events endpoint to get a list of events and filter them by hand
    const path = `/shifthandover/${mockStore.scenario().config.plantId}/events`
    const eventsPerPage = requestDto.itemsPerPage ?? 50
    const pageNumber = requestDto.pageNumber ?? 0
    const options = {
      params: {
        eventsPerPage,
        pageNumber
      }
    }
    const {data: allEvents} = await axiosInstance.get<ShiftEventDto[]>(path, options)

    const events = mockFilter(allEvents, requestDto)

    const start = pageNumber * eventsPerPage
    const end = start + eventsPerPage
    const range = events.slice(start, end)
    const totalPages = Math.round(events.length / eventsPerPage + 0.5)

    return [
      200,
      {currentPage: pageNumber, events: range, totalItemCount: events.length, totalPages}
    ]
  })
}
