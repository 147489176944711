import {ContentBox} from '@hconnect/common/components/ContentBox'
import {EquipmentKpiTableOverview} from '@hconnect/common/components/kpiPerformance/EquipmentKpiTableOverview'
import {useExpandedGroups} from '@hconnect/common/hooks/useExpandGroups'
import {EquipmentPerformance} from '@hconnect/common/types'
import {ExpandCollapseButton} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import {isEmpty} from 'lodash'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

type PerformanceSummaryProps = {
  isLoading: boolean
  'data-test-id': string
  equipmentPerformances?: EquipmentPerformance[]
}

export const PerformanceSummary: FC<PerformanceSummaryProps> = ({
  isLoading,
  equipmentPerformances,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()

  const equipmentIndexes: string[] = Array.from(
    {length: equipmentPerformances?.length || 0},
    (_, index) => String(index)
  )
  const [expandedEquipments, toggleExpandedEquipments] = useExpandedGroups(
    equipmentIndexes,
    'summary-expanded-equipments'
  )

  return (
    <ContentBox
      title={
        !isEmpty(equipmentPerformances) && (
          <Box display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap" gap={1}>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <ExpandCollapseButton
                variant="text"
                expanded={
                  expandedEquipments && expandedEquipments.length === equipmentIndexes.length
                }
                onClick={() => toggleExpandedEquipments('all')}
                data-test-id="performance-expand-all-button"
                expandLabel={t('action.expand')}
                collapseLabel={t('action.collapse')}
              />
            </Box>
          </Box>
        )
      }
      mode="contentDependentHeight"
      bodyWithPadding
      isLoading={isLoading}
      minHeight="450px"
      data-test-id={dataTestId}
    >
      {equipmentPerformances && (
        <EquipmentKpiTableOverview
          data={equipmentPerformances}
          expandedEquipments={expandedEquipments}
          toggleExpandedEquipments={toggleExpandedEquipments}
        />
      )}
    </ContentBox>
  )
}
