import {StoppageDto} from '@hconnect/common/types'
import moment, {Moment} from 'moment-timezone'

import {isStoppageDto, isTaskDto} from '../../common/utils/eventType'
import {SortBy, SortOrder, ShiftEventDto, TaskDto, InfoDto} from '../../types/shiftHandover.types'

const getTimeStampForTask = (
  item: TaskDto & InfoDto,
  sortBy: SortBy,
  startDate: Moment,
  endDate: Moment
) => {
  switch (sortBy) {
    case SortBy.Details:
      if ((item.status === 'pending' || item.status === 'ongoing') && item.lastModifiedStatus) {
        if (moment(item.lastModifiedStatus).isBetween(startDate, endDate))
          return item.lastModifiedStatus
        else return false
      }

      if (moment(item.lastModified).isBetween(startDate, endDate)) {
        return item.lastModified
      }

      if (moment(item.dueDate).isBetween(startDate, endDate)) {
        return item.dueDate
      }

      return false
    case SortBy.Created:
    default:
      return moment(item.dueDate).isBetween(startDate, endDate) && item.dueDate
  }
}

const getTimeStampForStoppage = (
  item: StoppageDto & InfoDto,
  sortBy: SortBy,
  startDate: Moment,
  endDate: Moment
) => {
  switch (sortBy) {
    case SortBy.Details:
      if (moment(item.stoppageStart).isBetween(startDate, endDate)) {
        return item.stoppageStart
      }
      if (item.stoppageEnd && moment(item.stoppageEnd).isBetween(startDate, endDate)) {
        return item.stoppageEnd
      }
      return false
    case SortBy.Created:
    default:
      return moment(item.stoppageStart).isBetween(startDate, endDate) && item.stoppageStart
  }
}

export const getRelevantTimeStamp = (
  item: ShiftEventDto,
  sortBy: SortBy,
  startDate: Moment,
  endDate: Moment
): string | false => {
  if (isTaskDto(item)) {
    return getTimeStampForTask(item, sortBy, startDate, endDate)
  }

  if (isStoppageDto(item)) {
    return getTimeStampForStoppage(item, sortBy, startDate, endDate)
  }

  // all other
  switch (sortBy) {
    case SortBy.Details:
      return (
        (moment(item.lastModified).isBetween(startDate, endDate) && item.lastModified) ||
        (moment(item.created).isBetween(startDate, endDate) && item.created)
      )
    case SortBy.LastModified:
      return moment(item.lastModified).isBetween(startDate, endDate) && item.lastModified
    case SortBy.Created:
    default:
      return moment(item.created).isBetween(startDate, endDate) && item.created
  }
}

// eslint-disable-next-line complexity
export const getSortedResult = (
  results: ShiftEventDto[],
  sortBy: SortBy,
  sortOrder: SortOrder,
  startDate: Moment,
  endDate: Moment
) => {
  switch (sortBy) {
    case SortBy.Created:
    case SortBy.Details:
      results.sort((event1, event2) => {
        const timeStamp1 = getRelevantTimeStamp(event1, sortBy, startDate, endDate)
        const timeStamp2 = getRelevantTimeStamp(event2, sortBy, startDate, endDate)

        return (timeStamp1 && timeStamp2 && timeStamp2.localeCompare(timeStamp1)) || 0
      })
      return results
    case SortBy.Category:
      sortOrder === SortOrder.Descending
        ? results.sort((event1, event2) => {
            if (!event1.category || !event2.category) return 0
            return event1.category > event2.category ? -1 : 1
          })
        : results.sort((event1, event2) => {
            if (!event1.category || !event2.category) return 0
            return event1.category < event2.category ? -1 : 1
          })
      return results
    case SortBy.EquipmentNo:
      sortOrder === SortOrder.Descending
        ? results.sort((event1, event2) => {
            if (!event1.equipment?.text || !event2.equipment?.text) return 0
            return event1.equipment?.text > event2.equipment?.text ? -1 : 1
          })
        : results.sort((event1, event2) => {
            if (!event1.equipment?.text || !event2.equipment?.text) return 0
            return event1.equipment?.text < event2.equipment?.text ? -1 : 1
          })
      return results
    case SortBy.EventType:
      sortOrder === SortOrder.Descending
        ? results.sort((event1, event2) => {
            return event1.eventType > event2.eventType ? -1 : 1
          })
        : results.sort((event1, event2) => {
            return event1.eventType < event2.eventType ? -1 : 1
          })
      return results
    case SortBy.ProcessStage:
      sortOrder === SortOrder.Descending
        ? results.sort((event1, event2) => {
            if (!event1.processStage || !event2.processStage) return 0
            return event1.processStage > event2.processStage ? -1 : 1
          })
        : results.sort((event1, event2) => {
            if (!event1.processStage || !event2.processStage) return 0
            return event1.processStage < event2.processStage ? -1 : 1
          })
      return results
    case SortBy.MaintenanceNotificationId:
      sortOrder === SortOrder.Descending
        ? results.sort((event1, event2) => {
            if (!event1.sapMaintenanceNotificationId || !event2.sapMaintenanceNotificationId)
              return 0
            return event1.sapMaintenanceNotificationId > event2.sapMaintenanceNotificationId
              ? -1
              : 1
          })
        : results.sort((event1, event2) => {
            if (!event1.sapMaintenanceNotificationId || !event2.sapMaintenanceNotificationId)
              return 0
            return event1.sapMaintenanceNotificationId < event2.sapMaintenanceNotificationId
              ? -1
              : 1
          })
      return results
    case SortBy.Status:
      sortOrder === SortOrder.Descending
        ? results.sort((event1, event2) => {
            return event1.status > event2.status ? -1 : 1
          })
        : results.sort((event1, event2) => {
            return event1.status < event2.status ? -1 : 1
          })
      return results
    case SortBy.Title:
      sortOrder === SortOrder.Descending
        ? results.sort((event1, event2) => {
            return event1.title > event2.title ? -1 : 1
          })
        : results.sort((event1, event2) => {
            return event1.title < event2.title ? -1 : 1
          })
      return results
    case SortBy.LastModified:
      sortOrder === SortOrder.Descending
        ? results.sort((event1, event2) => {
            const dateA = moment(event1.lastModified)
            const dateB = moment(event2.lastModified)
            return dateB.diff(dateA)
          })
        : results.sort((event1, event2) => {
            const dateA = moment(event1.lastModified)
            const dateB = moment(event2.lastModified)
            return dateA.diff(dateB)
          })
      return results
  }
}
