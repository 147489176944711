import {Category, EventType} from '@hconnect/common/types'

export const OTHER = 'other'

// extract type enums that we treat specially, i.e. show on the top of the table
export const HEALTH_AND_SAFETY: Extract<EventType, 'healthAndSafety'> = 'healthAndSafety'
export const ENVIRONMENT: Extract<Category, 'environment'> = 'environment'
export const QUALITY: Extract<Category, 'quality'> = 'quality'
export const MECHANICAL: Extract<Category, 'mechanical'> = 'mechanical'
export const ELECTRICAL: Extract<Category, 'electrical'> = 'electrical'

export const generalPinnedGroups: string[] = [HEALTH_AND_SAFETY, ENVIRONMENT, QUALITY]
export const maintenancePinnedGroups: string[] = [MECHANICAL, ELECTRICAL]

export enum EventListGroup {
  General = 'general',
  Production = 'production',
  Maintenance = 'maintenance'
}
