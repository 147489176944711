import CloseIcon from '@mui/icons-material/Close'
import {IconButton} from '@mui/material'
import React from 'react'

export type Props = {
  'data-test-id'?: string
  onClick(): void
}

export const CloseButton: React.FC<Props> = ({'data-test-id': dataTestId, onClick}) => {
  return (
    <IconButton data-test-id={dataTestId || 'events-details-close'} onClick={() => onClick()}>
      <CloseIcon />
    </IconButton>
  )
}
