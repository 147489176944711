import CheckIcon from '@mui/icons-material/Check'
import {Button, Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

type SelectAllButtonProps = {
  selectAll: () => void
  keyName: string
}

export const SelectAllButton: React.FC<SelectAllButtonProps> = (props) => {
  const {t} = useTranslation()
  const {selectAll, keyName} = props
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Button
        sx={{
          border: '1px solid rgba(31, 73, 94, 0.1)',
          marginTop: '10px'
        }}
        variant="text"
        onClick={selectAll}
        startIcon={<CheckIcon />}
        data-test-id={`select-all-filter-button-${keyName}`}
      >
        {t('shiftEvent.label.selectAll')}
      </Button>
    </Box>
  )
}
