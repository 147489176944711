import {EventStatusLabel} from '@hconnect/common/components/eventProperties/EventStatusLabel'
import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import {StatusWithNoneAndCancellationAndApproverAndWorkOrderStates} from '@hconnect/common/types'
import React from 'react'

import {statusListWithAllStates} from '../../types/shiftHandover.types'
import {SelectAllButton} from '../common/SelectAllButton'

import {CommonFilterDropDownProps, FilterDropDown} from './FilterDropDown'

export const StatusFilterDropDown: React.FC<
  CommonFilterDropDownProps<StatusWithNoneAndCancellationAndApproverAndWorkOrderStates>
> = (props) => {
  const {selectAll} = props
  return (
    <FilterDropDown
      {...props}
      options={statusListWithAllStates}
      keyName="status"
      customTemplate={(status) => {
        return <EventStatusLabel status={status} />
      }}
      fullWidth={false}
      customDropDownFooter={(keyName) =>
        props.isCollapsible ? null : (
          <>
            <SelectAllButton
              keyName={keyName}
              selectAll={() =>
                selectAll &&
                selectAll(
                  keyName,
                  statusListWithAllStates as unknown as StatusWithNoneAndCancellationAndApproverAndWorkOrderStates[]
                )
              }
            />
            <RemoveFilterButton keyName={keyName} removeFilter={props.removeFilter} />
          </>
        )
      }
    />
  )
}
