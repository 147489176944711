import type MockAdapter from 'axios-mock-adapter/types'

import {mockStore} from '../mockStoreSingleton'

export const enableUserSearchMock = (apiMockAdapter: MockAdapter) => {
  apiMockAdapter.onPost(/\/shifthandover\/\w+\/search\/taskassignee?.+/).reply((config) => {
    const namePattern = (config.url || '').split('?')[1].split('&')[0].split('=')[1]

    const matches = mockStore.users
      .getAllUsers()
      .filter(
        (u) => u.name && u.name.toLocaleLowerCase().indexOf(namePattern.toLocaleLowerCase()) >= 0
      )
      .map((p) => ({name: p.name, id: p.id}))

    return [200, matches]
  })
}
