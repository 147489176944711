import {hpTheme} from '@hconnect/uikit/src/lib2'
import {CssBaseline, ThemeProvider} from '@mui/material'
import React, {Suspense} from 'react'
import ReactDOM from 'react-dom/client'

import {mockStore} from './__mock__/mockStoreSingleton'
import {AppWrapper} from './AppWrapper'
import {PageLoadingSpinner} from './components/common/PageLoadingSpinner'
import {createApi, ApiContextProvider} from './hooks/api/useApi'
import {ServiceWorkerProvider} from './hooks/useServiceWorkerContext'
import {init as initLocalization} from './localization'

const api = mockStore.isEnabled()
  ? createApi({throwOnNoMatch: true, mockEverything: true})
  : createApi({throwOnNoMatch: false, mockEverything: false})

void initLocalization()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Suspense fallback={<PageLoadingSpinner />}>
    <ServiceWorkerProvider>
      <ThemeProvider theme={hpTheme}>
        <CssBaseline />
        <ApiContextProvider api={api}>
          <AppWrapper api={api} />
        </ApiContextProvider>
      </ThemeProvider>
    </ServiceWorkerProvider>
  </Suspense>
)
