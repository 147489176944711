import {AxiosError} from 'axios'
import {useQuery, UseQueryResult} from 'react-query'

import {WorkOrderPmNotification} from '../../types/workOrders.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const QueryKey = 'work-orders'

export const useSapWorkOrders = (
  notificationId: string
): UseQueryResult<WorkOrderPmNotification, AxiosError> => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()

  return useQuery([QueryKey, plantId, notificationId], async () => {
    const {data} = await axiosInstance.get<WorkOrderPmNotification>(
      `/work-orders/${plantId}/notifications/${notificationId}`
    )
    return data
  })
}
