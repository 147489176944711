import {mapEquipmentPerformances} from '@hconnect/common/mappers'
import {EquipmentPerformance, EquipmentPerformanceDto} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {TimeRange} from '../../types/timeAndDate.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const QueryKey = 'equipment-performances'

export const useEquipmentPerformances = (
  queryRange: TimeRange,
  options?: UseQueryOptions<EquipmentPerformance[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()
  const path = `/shifthandover/${plantId}/equipmentPerformances`

  return useQuery<EquipmentPerformance[], AxiosError>(
    [QueryKey, queryRange, plantId, timezone],
    async () => {
      const {data} = await axiosInstance.get<EquipmentPerformanceDto[]>(path, {
        params: queryRange
      })
      return mapEquipmentPerformances(data, timezone)
    },
    options
  )
}
