import React, {createContext, useContext, useState, useEffect} from 'react'

import * as serviceWorker from '../serviceWorkerRegistration'

const ServiceWorkerContext = createContext({})

export const useServiceWorker = () => useContext(ServiceWorkerContext)

export const ServiceWorkerProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [isRegistered, setIsRegistered] = useState<boolean>(false)
  useEffect(() => {
    serviceWorker.register()
    setIsRegistered(true)
  }, [])

  return (
    <ServiceWorkerContext.Provider value={isRegistered}>{children}</ServiceWorkerContext.Provider>
  )
}
