import {decodeAccessToken} from '@hconnect/apiclient'
import type MockAdapter from 'axios-mock-adapter/types'
import moment from 'moment-timezone'

import {isTaskDto} from '../../common/utils/eventType'
import {
  CockpitPermission,
  EventCreateDto,
  EventUpdateDto,
  ShiftEventDto
} from '../../types/shiftHandover.types'
import {mockStore} from '../mockStoreSingleton'

import {getAttachmentsFromMockState, processImageAttachments} from './attachmentsUtil'

export const updateEventsReply = (config) => {
  const mockState = mockStore.scenario()
  const requestDto = JSON.parse(config.data as string) as EventUpdateDto
  const user: string = config.headers['Authorization']
  const token: {given_name: string} = decodeAccessToken<{given_name: string}>(user)
  const now = mockState.currentTimeUtc ?? moment.utc().toISOString()

  const matchedEvent = mockState.events.find((event) => event.id === requestDto.id)

  // TODO proper HTTP RFC7808 error response
  if (!matchedEvent) return [404, {}]

  // TODO proper HTTP RFC7808 error response
  if (matchedEvent.lastModified !== requestDto.lastModified) return [409, {}]

  const eventId = requestDto.id
  if (Array.isArray(requestDto.newAttachments)) {
    // attachments should be base64 strings
    // eslint-disable-next-line
    const images = processImageAttachments((requestDto.newAttachments as any) ?? [])
    mockState.images = [...mockState.images, ...images]
    const previousImages = mockState.eventsToImages[eventId] ?? []
    mockState.eventsToImages[eventId] = [...previousImages, ...images.map((i) => i.id)]
  }

  const removeIds = requestDto.attachmentsToRemove
  if (removeIds && Array.isArray(removeIds)) {
    const previousImages = mockState.eventsToImages[eventId] ?? []
    mockState.eventsToImages[eventId] = previousImages.filter(
      (id: string) => !removeIds.includes(id)
    )
  }

  const modifiedEvent: ShiftEventDto = {
    created: matchedEvent.created,
    createdBy: matchedEvent.createdBy,
    ...requestDto,
    modifiedBy: token.given_name,
    lastModified: now
  }

  // task specifics
  if (isTaskDto(modifiedEvent) && isTaskDto(requestDto)) {
    if (requestDto.status && requestDto.status !== matchedEvent.status) {
      modifiedEvent.lastModifiedStatus = now
    }
  }

  // update in-place
  mockState.events = mockState.events.map((event) => {
    if (event.id !== requestDto.id) return event
    else return modifiedEvent
  })

  // save the changes
  mockStore.setScenarioData(mockState)

  return [201, modifiedEvent]
}

export const enableEventsMock = (apiMockAdapter: MockAdapter) => {
  // get all events
  apiMockAdapter.onGet(/\/shifthandover\/\w+\/events$/).reply(() => {
    const mockState = mockStore.scenario()

    // TODO respect the itemsPerPage & pageNumber from the DTO
    // currently we return all item's

    // please make sure on create and update to use "sortEvents" after every update to the list
    return [200, mockState.events]
  })

  // get a single event
  apiMockAdapter.onGet(/\/shifthandover\/\w+\/events\/((?!comments).)*$/).reply((config) => {
    const eventId = config.url?.split('/')[4]
    const mockState = mockStore.scenario()
    const event = eventId && mockState.events.find((event: ShiftEventDto) => event.id === eventId)

    if (!event || typeof eventId !== 'string') {
      return [404, 'Request can not be proceeded, event for the supplied Id could not be found']
    }

    event.attachments = getAttachmentsFromMockState(eventId, mockState)
    return [200, event]
  })

  const createEventsReply = (config) => {
    const mockState = mockStore.scenario()
    const requestDto = JSON.parse(config.data as string) as EventCreateDto
    const newId: string = Math.floor(Math.random() * 10000000).toString()
    const user: string = config.headers['Authorization']
    const token: {given_name: string; user_id: string} = decodeAccessToken<{
      given_name: string
      user_id: string
    }>(user)
    const now = mockState.currentTimeUtc ?? moment.utc().toISOString()

    const event: ShiftEventDto = {
      ...requestDto,
      id: newId,
      createdBy: {
        name: token.given_name,
        id: token.user_id
      },
      modifiedBy: token.given_name,
      created: now,
      lastModified: now,
      attachments: []
    }

    // update and sort the events list
    mockState.events = [...mockState.events, event]

    // eslint-disable-next-line
    const images = processImageAttachments((requestDto.attachments as any) ?? [])
    mockState.images = [...mockState.images, ...images]
    mockState.eventsToImages[event.id] = images.map((i) => i.id)
    mockStore.setScenarioData(mockState)
    return [201, event]
  }

  // create event
  apiMockAdapter.onPost(/\/shifthandover\/\w+\/events/).reply(createEventsReply)
  apiMockAdapter.onPost(/\/shifthandover\/\w+\/stoppages/).reply(createEventsReply)

  // edit event
  apiMockAdapter.onPut(/\/shifthandover\/\w+\/events/).reply(updateEventsReply)
  apiMockAdapter.onPut(/\/shifthandover\/\w+\/stoppages/).reply(updateEventsReply)

  // delete event
  apiMockAdapter.onDelete(/\/shifthandover\/\w+\/events\/.+/).reply((config) => {
    const eventId = config.url?.split('/')[4]
    const mockState = mockStore.scenario()
    const event = eventId && mockState.events.find((event: ShiftEventDto) => event.id === eventId)

    // check that the event exist
    if (!event || typeof eventId !== 'string') {
      return [404, 'Request can not be proceeded, event for the supplied Id could not be found']
    }

    // extract the users permission
    const {user_id: userID} = decodeAccessToken<{user_id: string}>(
      config.headers?.['Authorization'] as string
    )
    const permissions = mockStore.permissions
      .getPermissions(userID)
      .map((dfp) => dfp.permissionType as CockpitPermission)

    // check that the user has the correct permission
    if (isTaskDto(event)) {
      if (!permissions.includes('DELETE_PLANT_TASKS')) {
        return [403, 'no permission to delete a task']
      }
    } else if (!permissions.includes('DELETE_PLANT_EVENTS')) {
      return [403, 'no permission to delete an event']
    }

    // remove the item from the list
    mockState.events = mockState.events.filter((item) => {
      return item.id !== event.id
    })

    // delete all its attachments
    if (mockState.eventsToImages[eventId]) {
      delete mockState.eventsToImages[eventId]
    }

    // save the changes
    mockStore.setScenarioData(mockState)

    return [200, event]
  })
}
