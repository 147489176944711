import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import {TaskRepetitionUnit, taskRepetitionUnits} from '@hconnect/common/types'
import React from 'react'

import {SelectAllButton} from '../common/SelectAllButton'

import {CommonFilterDropDownProps, FilterDropDown} from './FilterDropDown'

export const RecurrenceFilterDropDown: React.FC<CommonFilterDropDownProps<TaskRepetitionUnit>> = (
  props
) => {
  const {selectAll} = props
  return (
    <FilterDropDown
      {...props}
      options={taskRepetitionUnits}
      keyName="repetition"
      fullWidth={false}
      customDropDownFooter={(keyName) =>
        props.isCollapsible ? null : (
          <>
            <SelectAllButton
              keyName={keyName}
              selectAll={() =>
                selectAll &&
                selectAll(keyName, taskRepetitionUnits as unknown as TaskRepetitionUnit[])
              }
            />
            <RemoveFilterButton keyName={keyName} removeFilter={props.removeFilter} />
          </>
        )
      }
    />
  )
}
