import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Category, EventType} from '@hconnect/common/types'

type BaseAnalyticsEvent =
  | {
      name: 'onSapPmNotificationFeature'
      data: {timezone: string}
    }
  | {
      name: 'onErrorPageTracking'
      data: {errorCode?: string; errorMessage?: string}
    }
  | {
      name: 'onCategoryStoppageCodeEvent'
      data: {stoppageCode?: string; eventType: EventType; category?: Category}
    }
  | {
      name: 'onCreationStartEvent'
      data: {uuid: string}
    }
  | {
      name: 'onCreationFinishEvent'
      data: {uuid: string}
    }

interface CommonAnalyticsData {
  data: {
    plantId?: string
    userId?: string
  }
}

type AnalyticsEvent = BaseAnalyticsEvent & CommonAnalyticsData

export const trackAnalyticsEvent = <T extends AnalyticsEvent['name']>(
  eventName: T,
  data: Extract<AnalyticsEvent, {name: T}>['data']
) => trackEvent(eventName, {product: 'cockpit', ...data})
