import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {MoreVert} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Menu, MenuItem, Typography} from '@mui/material'
import React, {useCallback, useState} from 'react'

export interface ActionListItem {
  label: string | JSX.Element
  startIcon?: JSX.Element
  onClick: () => void
  dataTestId?: string
  disabled?: boolean
  isLoading?: boolean
}

type ActionListProps = {
  listItems: ActionListItem[]
}

export const ActionList: React.FC<ActionListProps> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget as Element)
    setIsMenuOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setIsMenuOpen(false)
  }, [])

  const {listItems} = props
  return (
    <>
      <Typography
        onClick={handleOpen}
        sx={[
          (theme) => ({
            width: '48px',
            height: '48px',
            borderRadius: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }),
          isMenuOpen && {
            background: customThemeConstants().palette.buttonFillColor
          }
        ]}
        data-test-id="action-list-btn"
      >
        <MoreVert fontSize="medium" color="primary" />
      </Typography>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        PaperProps={{
          sx: (theme) => ({
            borderRadius: theme.spacing(1),
            padding: theme.spacing(0, 2),
            marginTop: theme.spacing(2)
          })
        }}
      >
        {listItems.map(({label, startIcon, onClick, dataTestId, disabled, isLoading}, index) => (
          <MenuItem
            key={index}
            disableGutters={true}
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              '&:hover, &:active, &:focus': {
                backgroundColor: '#f7fcff'
              }
            }}
          >
            <LoadingButton
              sx={{justifyContent: 'flex-start'}}
              variant="text"
              disabled={disabled}
              loading={isLoading}
              fullWidth
              onClick={() => {
                onClick?.()
                handleClose()
              }}
              data-test-id={dataTestId}
              startIcon={startIcon}
              loadingPosition="start"
            >
              {label}
            </LoadingButton>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
