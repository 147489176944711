import {useState} from 'react'

/**
 * this function returns the previous value as long as the current value stays the same
 *
 * usePreview form react-use returns the actual last value no matter if it was different from the one before or not
 *
 * Sample
 * const [value, setValue] = setState<string | null> ('initial')
 * const a = usePrevious(value)
 * const b = usePreviousValue(value)
 *
 * return `${a}, ${b}`

 * initial                // initial, initial
 * setValue('something')  // initial, initial
 * setValue('something')  // initial, something
 * setValue(null)         // something, something
 * setValue(null)         // something, null
 * setValue(null)         // something, null
 */

export function usePreviousValue<T>(current: T): T | null {
  const [{lastCurrent, previous}, setPrevious] = useState<{
    lastCurrent: T | null
    previous: T | null
  }>({
    lastCurrent: null,
    previous: null
  })

  if (current !== lastCurrent) {
    setPrevious({
      lastCurrent: current,
      previous: lastCurrent
    })
  }
  return previous
}
