import {getHoursAndMinutesFromTimeString} from '@hconnect/common/components/shiftEventFormFields'
import {ParticularShift, Shift, DateRange} from '@hconnect/common/types'
import {getAllPossibleShiftsOfDay, getShift, timezonedDateValidator} from '@hconnect/common/utils'
import moment, {Moment} from 'moment-timezone'

export function getShiftsForDay(localDate: Moment, shifts: Shift[]): ParticularShift[] {
  const {list} = getAllPossibleShiftsOfDay(localDate, shifts)
  return list
}

export function isSingleShiftRange(startDate: Moment, endDate: Moment, allShifts: Shift[]) {
  timezonedDateValidator(startDate, endDate)
  const startingShift = getShift(startDate, allShifts)
  const endingShift = getShift(endDate, allShifts)
  return startingShift.startDate.isSame(endingShift.startDate)
}

/*
 *
 *
 * Previous code that can get phased out (hopefully soon) ->
 *
 *
 */

export function alignToShiftEnd(date: Moment, shift: Shift): Moment {
  const newDate = date.clone()
  const [endHours, endMinutes] = getHoursAndMinutesFromTimeString(shift.end)
  if (timeIsSameOrBefore(shift.end, shift.start)) {
    // night shift!
    newDate.add(1, 'day')
  }
  return newDate
    .hours(endHours)
    .minutes(endMinutes)
    .seconds(0)
    .milliseconds(0)
    .subtract(1, 'millisecond')
}

export function alignToShiftStart(date: Moment, shift: Shift): Moment {
  const newDate = date.clone()
  const [startHours, startMinutes] = getHoursAndMinutesFromTimeString(shift.start)
  return newDate.hours(startHours).minutes(startMinutes).seconds(0).milliseconds(0)
}

export function getTimeRangeForShift(date: Moment, shift: Shift): DateRange {
  const newDate = moment(date).clone()
  // Note: assumption that whole day is covered with shifts
  // ^ this would change in future
  if (timeIsSameOrBefore(shift.end, shift.start)) {
    newDate.subtract(1, 'day')
  }
  const start = alignToShiftStart(newDate, shift)
  const end = alignToShiftEnd(newDate, shift)
  return {startDate: start, endDate: end}
}

/**
 * determines if a time if before another, where time is encoded as string in HH:mm:ss format
 */
export function timeIsSameOrBefore(a: string, b: string) {
  if (a.length !== 8 || b.length !== 8) {
    throw new Error('Invalid time input length passed')
  }
  return a.localeCompare(b, 'de') <= 0
}

/**
 * sorts the given shifts for a day based on the start time
 * @param date
 * @param shifts
 * @returns sorted array of shifts based on the start time
 */
export function sortDateShiftsOnStartTime(date: Moment, shifts: Shift[]): ParticularShift[] {
  const dateShifts = getShiftsForDay(date, shifts)
  return dateShifts.sort((a, b) => (a.start < b.start ? 0 : 1))
}

/**
 * getShiftBounds returns the start and end bounds based on shifts
 * uses first shift of the fromDate and last or current shift of the toDate
 * @param shifts
 * @param fromDate
 * @param toDate
 * @param toCurrentShift
 * @returns array with start bound and end bound values
 */
export function getShiftBounds(
  shifts: Shift[],
  fromDate: Moment,
  toDate: Moment,
  toCurrentShift?: boolean
): [start: Moment, end: Moment] {
  timezonedDateValidator(fromDate, toDate)
  // TODO: check why not just use getShift with fromDate directly
  const fromDateShifts = sortDateShiftsOnStartTime(fromDate, shifts)
  const startDate = fromDateShifts[0].startDate
  if (toCurrentShift) {
    const currentShift = getShift(toDate, shifts)
    const endDate = currentShift.endDate
    return [startDate, endDate]
  }
  const toDateShifts = sortDateShiftsOnStartTime(toDate, shifts)
  const endDate = toDateShifts[toDateShifts.length - 1].endDate
  return [startDate, endDate]
}

export function roundUpToTheNearestMinute(date: Moment): Moment {
  return date.second() || date.millisecond() ? date.add(1, 'minute').startOf('minute') : date
}
