import {EventAttachmentsDisplay} from '@hconnect/common/components/attachments'
import {Attachment} from '@hconnect/common/types'
import {Box, Link, Typography} from '@mui/material'
import React, {FC, useState} from 'react'

import {DOCUMENT_ICON_MAP} from '../../consts'
import {InstructionVM} from '../../types/documents.types'

type DocumentLinkProps = {
  document: InstructionVM
  noUnderline?: boolean
  gap?: number
  iconSize?: number
}

const ATTACHMENT_ID = 0

export const DocumentLink: FC<DocumentLinkProps> = ({
  document,
  noUnderline,
  gap = 2,
  iconSize = 16
}) => {
  const [showPreview, setShowPreview] = useState<boolean>(false)

  const attachment: Attachment | undefined =
    document.attachment && document.attachmentFileName
      ? {
          id: ATTACHMENT_ID,
          fileName: document.attachmentFileName,
          mediaType: 'application/pdf',
          previewMediaType: 'application/pdf',
          previewUrl: document.attachment,
          url: document.attachment
        }
      : undefined

  return (
    <Box display="flex" alignItems="center" gap={gap}>
      <Box display="flex" fontSize={iconSize}>
        {DOCUMENT_ICON_MAP[document.type]}
      </Box>{' '}
      {document.link ? (
        <Link
          href={document.link}
          rel="noreferrer noopener"
          target="_blank"
          sx={[
            !!noUnderline && {textDecoration: 'none'},
            {lineHeight: '22px', wordBreak: 'break-word'}
          ]}
          data-test-id="document-link-title"
        >
          {document.title}
        </Link>
      ) : (
        <>
          <Link
            component={Typography}
            sx={[!!noUnderline && {textDecoration: 'none'}, {wordBreak: 'break-word'}]}
            data-test-id="document-title"
            onClick={() => setShowPreview(true)}
          >
            {document.title}
          </Link>
          {showPreview && attachment && (
            <EventAttachmentsDisplay
              attachments={[attachment]}
              activeAttachmentId={ATTACHMENT_ID}
              onClose={() => setShowPreview(false)}
            />
          )}
        </>
      )}
    </Box>
  )
}
