import {AxiosError} from 'axios'
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'

import {mapComment} from '../../mappers'
import {Comment, CommentDto, Iso8601} from '../../types/shiftHandover.types'
import {TimeRange} from '../../types/timeAndDate.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const QueryKey = 'events-comments'

export type CreateCommentParameter = {
  eventId?: string
  submittedForTimestamp?: Iso8601
  description: string
}

export const useFetchShiftComments = (
  timeRange: TimeRange,
  options?: UseQueryOptions<Comment[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()
  const path = `/shifthandover/${plantId}/shiftcomments`

  return useQuery<Comment[], AxiosError>(
    [QueryKey, timeRange, plantId, timezone],
    async () => {
      const response = await axiosInstance.get<CommentDto[]>(path, {
        params: {
          startDate: timeRange.startDate,
          endDate: timeRange.endDate
        }
      })
      return (response.data || []).map((comment) => mapComment(comment, timezone))
    },
    options
  )
}

export const useCreateShiftComment = (
  options?: UseMutationOptions<Comment, AxiosError, CreateCommentParameter>
) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId, timezone} = useConfig()

  return useMutation<Comment, AxiosError, CreateCommentParameter>(
    async ({submittedForTimestamp, description}) => {
      const path = `/shifthandover/${plantId}/shiftcomments`
      const payload = {
        submittedFor: submittedForTimestamp,
        description
      }

      const mutation = await axiosInstance.post<CommentDto>(path, payload)
      return mapComment(mutation.data, timezone)
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(QueryKey)
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
