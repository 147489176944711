import {CommonDropDown, CommonProps} from '@hconnect/common/components/shiftEventFormFields'
import React from 'react'

import {InforProblemCode} from '../../types/maintenanceNotification.types'

const inforProblemCodeOptions = Object.values(InforProblemCode).map((value) => value)

export const InforProblemCodeDropdown: React.FC<CommonProps<InforProblemCode>> = ({
  disabled,
  ...props
}) => {
  return (
    <CommonDropDown
      {...props}
      disabled={disabled || inforProblemCodeOptions.length === 0}
      keyName="inforProblemCode"
      options={inforProblemCodeOptions}
    />
  )
}
