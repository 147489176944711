import {EquipmentData, EquipmentPlain, Priority, ProcessStage} from '@hconnect/common/types'
import type MockAdapter from 'axios-mock-adapter/types'

import {samplePriorities} from '../data/priorities'
import {sampleProcessStages} from '../data/processStages'

import {searchEquipment, searchEquipmentPlain, searchMainEquipment} from './equipment'

export const enableExistingPlantConfigMock = (apiMockAdapter: MockAdapter) => {
  // searchEquipment `/plantconfig/${plantId}/equipmentnumbers`
  apiMockAdapter.onGet(/\/plantconfig\/.+\/equipmentnumbers/).reply((config) => {
    const plantId = config.url?.split('/')[2]
    const language = config.params.language
    const searchPattern: string | undefined = config.params.searchPattern?.toUpperCase()

    if (!plantId) {
      return [
        404,
        'Request can not be proceeded, plant for the supplied plantId could not be found ' + plantId
      ]
    }

    if (!searchPattern) {
      return [400, 'parameter searchPattern is missing']
    }

    let result: EquipmentPlain[] = searchEquipment(searchPattern)

    result = result.map((r, i) => ({
      ...r,
      processStage: sampleProcessStages[language][i % sampleProcessStages[language].length]
    }))

    return [200, result]
  })

  apiMockAdapter.onGet(/\/plantconfig\/.+\/equipment/).reply((config) => {
    const plantId = config.url?.split('/')[2]
    const language = config.params.language

    const id: string | undefined = config.params.id

    if (!plantId) {
      return [
        404,
        'Request can not be proceeded, plant for the supplied plantId could not be found ' + plantId
      ]
    }

    if (!id) {
      return [400, 'parameter searchPattern is missing']
    }

    let result: EquipmentPlain[] = searchEquipment(id)

    result = result.map((r, i) => ({
      ...r,
      processStage: sampleProcessStages[language][i % sampleProcessStages[language].length]
    }))

    return [200, result[0]]
  })

  // getProcessStages `/plantconfig/${plantId}/processstages`
  apiMockAdapter.onGet(/\/plantconfig\/.+\/processstages/).reply((config) => {
    const plantId = config.url?.split('/')[2]
    const language = config.params.language

    if (!plantId) {
      return [
        404,
        'Request can not be proceeded, plant for the supplied plantId could not be found ' + plantId
      ]
    }

    const result: ProcessStage[] | undefined = sampleProcessStages[config.params.language]

    if (!language || !result) {
      return [400, `${config.params.language} is not supported as a language key`]
    }
    return [200, result]
  })

  apiMockAdapter.onGet(/\/plantconfig\/.+\/priorities/).reply((config) => {
    const language = config.url?.split('/')[2]

    const result: Priority[] | undefined = language && samplePriorities[language]

    if (!result) {
      return [400, `${config.params.language} is not supported as a language key`]
    }
    return [200, result]
  })

  // getMainEquipments `/plantconfig/${plantId}/mainequipmentnumbers`
  apiMockAdapter.onGet(/\/plantconfig\/.+\/mainequipmentnumbers/).reply((config) => {
    const plantId = config.url?.split('/')[2]
    const language = config.params.language
    const searchPattern: string | undefined = config.params.searchPattern?.toUpperCase()

    if (!plantId) {
      return [
        404,
        'Request can not be proceeded, plant for the supplied plantId could not be found ' + plantId
      ]
    }

    const result: EquipmentData[] | undefined = searchMainEquipment(searchPattern)

    if (!language || !result) {
      return [400, `${config.params.language} is not supported as a language key`]
    }
    return [200, result]
  })

  // getEquipmentsById `/plantconfig/${plantId}/mainequipmentnumbers`
  apiMockAdapter.onGet(/\/plantconfig\/.+\/equipmentsById$/).reply((config) => {
    const plantId = config.url?.split('/')[2]
    const mainEquipmentId = config.url?.split('/')[4]
    const language = config.params.language
    const searchPattern: string | undefined = config.params.searchPattern?.toUpperCase()

    if (!plantId || !mainEquipmentId) {
      return [
        404,
        'Request can not be proceeded, plant for the supplied plantId could not be found ' +
          plantId +
          ' and main equipment id ' +
          mainEquipmentId
      ]
    }

    const equipmentList: EquipmentPlain[] = searchEquipmentPlain(searchPattern)

    const result = equipmentList.map((r, i) => ({
      ...r,
      processStage: sampleProcessStages[language][i % sampleProcessStages[language].length]
    }))

    return [200, result]
  })
}
