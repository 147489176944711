import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import {MaintenanceNotification, maintenanceNotifications} from '@hconnect/common/types'
import React from 'react'

import {CommonFilterDropDownProps, FilterDropDown} from './FilterDropDown'

export const MaintenanceNotificationFilterDropdown: React.FC<
  CommonFilterDropDownProps<MaintenanceNotification>
> = (props) => {
  return (
    <FilterDropDown
      {...props}
      options={maintenanceNotifications}
      keyName="maintenanceNotificationExists"
      fullWidth={false}
      customDropDownFooter={(keyName) => {
        return props.isCollapsible ? null : (
          <RemoveFilterButton keyName={keyName} removeFilter={props.removeFilter} />
        )
      }}
    />
  )
}
