import {AppInsightsAdapter, createDefaultLogger} from '@hconnect/common/logging'
import {Project} from '@hconnect/common/project'

const isDev = process.env.NODE_ENV === 'development' || 'Cypress' in window
const isQA = process.env.REACT_APP_STAGE === 'qa'

const target = isDev || isQA ? 'console' : 'azure'

if (!isDev) {
  // activate the userTracking of Azure
  AppInsightsAdapter.init()
  AppInsightsAdapter.allowTracking()
}

export const HLog = (project: Project) => {
  return createDefaultLogger(project, target)
}
