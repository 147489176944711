import {User} from '@hconnect/common/types'
import {Autocomplete, CircularProgress, Paper, TextField, MenuItem} from '@mui/material'
import {isEqual, isString} from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useUserSearch} from '../../hooks/api/useUserSearch'

export type Props = {
  label: string
  'data-test-id': string
  errorText?: React.ReactNode
  required?: boolean
  disabled?: boolean
  onChange?: (user?: User[]) => void
  value: User[]
  variant?: 'standard' | 'filled' | 'outlined'
}

const paper = (props) => <Paper elevation={8} {...props} />

const getOptionLabel = (option: User | string) => (isString(option) ? option : option?.name)

export const UserSearchField: React.FC<Props> = ({
  required = false,
  onChange = () => undefined,
  value,
  disabled,
  errorText,
  label,
  'data-test-id': dataTestId
}: Props) => {
  const {t} = useTranslation()

  const [open, setOpen] = React.useState(false)
  const [searchTerm, setSelectedSearchTerm] = React.useState<string | undefined>(undefined)

  // to speed up the search we fetch a list of users that include the first 3 Characters of the user input
  // and let the Autocomplete component filter the rest
  const choppedSearchTerm = searchTerm && searchTerm.length >= 3 ? searchTerm.slice(0, 3) : ''

  const query = useUserSearch(choppedSearchTerm)

  const suggestions = useMemo(
    () =>
      ((query.data as User[]) ?? []).filter(
        (user) => !value.find((assigned) => assigned.id === user.id)
      ),
    [query.data, value]
  )

  return (
    <Autocomplete
      multiple
      ChipProps={{
        sx: {
          borderRadius: 1,
          marginRight: 1
        },
        variant: 'outlined',
        size: 'small'
      }}
      data-test-id={dataTestId}
      value={value}
      disableClearable
      onChange={(event, newValue) => {
        if (newValue?.length === value?.length || isEqual(newValue, value)) return
        else onChange(newValue as User[])
      }}
      onInputChange={(event, newValue) => {
        if (searchTerm !== newValue) {
          setSelectedSearchTerm(newValue)
        }
      }}
      open={open && suggestions.length >= 0}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      options={suggestions}
      filterSelectedOptions
      getOptionLabel={getOptionLabel}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={!!errorText}
            helperText={errorText}
            required={required}
            label={label}
            placeholder={t('pleaseStartTyping')}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {query.isFetching ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )
      }}
      PaperComponent={paper}
      forcePopupIcon={false}
      freeSolo
      disabled={disabled}
      renderOption={(props, option) => (
        <MenuItem {...props} data-test-id={`option-label-${isString(option) ? option : option.id}`}>
          {getOptionLabel(option)}
        </MenuItem>
      )}
    />
  )
}
