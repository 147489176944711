import {useSorting} from '@hconnect/common/hooks/useSorting'
import {Column, customThemeConstants, DataTable, PaginationOptions} from '@hconnect/uikit/src/lib2'
import {Box, SortDirection, SxProps, Theme} from '@mui/material'
import {get, orderBy} from 'lodash'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {InstructionVM} from '../../types/documents.types'

type DocumentTableColumnKey = 'name' | 'processStage' | 'status'

const rowSx = (selected: boolean): SxProps<Theme> => [
  selected &&
    ((theme) => ({
      backgroundColor: theme.palette.grey[200]
    })),
  {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: customThemeConstants().palette.primaryBackgroundColor
    },
    td: {
      fontSize: 16,
      lineHeight: 3
    }
  }
]

const sortDocuments = (
  documents: InstructionVM[],
  orderByKey: DocumentTableColumnKey,
  sortDir: SortDirection
): InstructionVM[] =>
  orderBy(
    documents,
    [
      (document) => {
        return get(document, orderByKey, '').toLowerCase()
      }
    ],
    [sortDir]
  )

type DocumentsTableProps = {
  isLoading: boolean
  columns: Column<InstructionVM>[]
  selectedDocumentId?: string
  onRowClick(item: InstructionVM, i: number): void
  documents?: InstructionVM[]
  paginationOptions?: PaginationOptions
}

export const DocumentsTable: FC<DocumentsTableProps> = ({
  isLoading,
  documents,
  paginationOptions,
  columns,
  selectedDocumentId,
  onRowClick
}) => {
  const {t} = useTranslation()

  const {sortedList, onSortClick, orderByKey, sortDir} = useSorting<
    InstructionVM,
    DocumentTableColumnKey
  >({
    initialSortDir: false,
    data: documents || [],
    sort: sortDocuments
  })
  const sortDirection = !sortDir ? undefined : sortDir

  return (
    <Box
      data-test-id="documents-table"
      display="flex"
      flexDirection="column"
      flex={1}
      overflow="hidden"
      justifyContent="space-between"
    >
      <DataTable<InstructionVM>
        columns={columns}
        data={sortedList || []}
        onSort={(event, key: DocumentTableColumnKey) => onSortClick(key)}
        sortedBy={orderByKey}
        sortDirection={sortDirection}
        emptyMessage={(!isLoading && t('documents.label.noData')) ?? ''}
        paginationOptions={paginationOptions}
        rowSx={(document) => rowSx(selectedDocumentId === document.id)}
        onRowClick={(keyEvent: React.MouseEvent, clickedItem: InstructionVM, i: number) =>
          onRowClick(clickedItem, i)
        }
      />
    </Box>
  )
}
