import {appUrl} from '@hconnect/common/hproduce/config'
import {cockpit} from '@hconnect/common/hproduce/config/cockpit'
import {GuidedToursProvider} from '@hconnect/uikit/src/lib2'
import type {GuidedTour} from '@hconnect/uikit/src/lib2/components/guidedTour/types'
import {OverlayState} from '@hconnect/uikit/src/lib2/shell/Overlay'
import {Theme, useMediaQuery} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router'

import {useGlobalContext} from './useGlobalContext'

const getStepTranslationKey = (index: number, key: 'title' | 'description') =>
  `guidedTours.introductionTour.steps.${index}.${key}`

export const useCockpitTours = (): Record<string, GuidedTour> => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {config} = useGlobalContext()
  const selectedPlantId = config.plantId
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return useMemo(
    (): Record<string, GuidedTour> => ({
      recentChangesTour: {
        title: t('guidedTours.introductionTour.title'),
        description: t('guidedTours.introductionTour.description'),
        image: <></>,
        steps: [
          {
            target: '#notifications-icon',
            title: t(getStepTranslationKey(0, 'title')),
            content: t(getStepTranslationKey(0, 'description')),
            placement: 'bottom'
          },
          {
            target: '#nav-item-1',
            title: t(getStepTranslationKey(1, 'title')),
            content: t(getStepTranslationKey(1, 'description')),
            placement: 'bottom',
            onGoNextToStep: () => {
              const isOpen =
                document.getElementById('burger-menu-overlay')?.getAttribute('data-value') ===
                OverlayState.Open
              if (isMobile && !isOpen) {
                document.getElementById('burger-menu')?.click()
              }
              navigate(generatePath(appUrl(cockpit.url), {plantId: selectedPlantId}))
            },
            onGoPrevToStep: () => {
              const isOpen =
                document.getElementById('burger-menu-overlay')?.getAttribute('data-value') ===
                OverlayState.Open
              if (isMobile && !isOpen) {
                document.getElementById('burger-menu')?.click()
              }
            }
          },
          {
            disableScrolling: true,
            target: isMobile
              ? '#events-filter-bar-toggle-button'
              : '#events-filter-bar-add-filter-button',
            title: t(getStepTranslationKey(2, 'title')),
            content: '',
            placement: 'auto',
            onGoNextToStep: () => {
              if (isMobile) {
                document.getElementById('burger-menu')?.click()
              } else {
                document.getElementById('events-filter-bar-add-filter-button')?.click()
              }
            },
            onGoPrevToStep: () => {
              if (isMobile) {
                document.getElementById('events-filter-bar-cancel-button')?.click()
              }
            }
          },
          {
            disableScrolling: true,
            target: isMobile
              ? '#events-filter-bar-maintenanceNotificationExists'
              : '#events-filter-bar-add-filter-maintenanceNotificationExists',
            title: t(getStepTranslationKey(3, 'title')),
            content: t(getStepTranslationKey(3, 'description')),
            placement: 'auto',
            spotlightClicks: true,
            onGoNextToStep: () => {
              if (isMobile) {
                document.getElementById('events-filter-bar-toggle-button')?.click()
              }
            }
          }
        ]
      }
    }),
    [isMobile, navigate, selectedPlantId, t]
  )
}

export const CockpitToursProvider = ({children}: {children: React.ReactNode}) => {
  const tours = useCockpitTours()

  const enabledTours = {recentChangesTour: true}

  return (
    <GuidedToursProvider tours={tours} enabledTours={enabledTours}>
      {children}
    </GuidedToursProvider>
  )
}
