import {
  ProcessStageDropDown,
  SimpleDateTimePicker
} from '@hconnect/common/components/shiftEventFormFields'
import {INPUT_FORM_FIELD_SX, REGULAR_SIZE} from '@hconnect/common/consts'
import {Grid, TextField} from '@mui/material'
import moment from 'moment-timezone'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {useConfig} from '../../hooks/useConfig'
import {InstructionVM, PossibleErrors} from '../../types/documents.types'

import {AttachmentPicker} from './AttachmentPicker'

type DocumentFormProps = {
  item: Partial<InstructionVM>
  updateMultiPart: (delta: Partial<InstructionVM>) => void
  validationError: Map<PossibleErrors, string>
}

export const DocumentForm: FC<DocumentFormProps> = ({item, updateMultiPart, validationError}) => {
  const {t} = useTranslation()
  const {plantId, timezone} = useConfig()

  const getErrorText = (key: PossibleErrors) => {
    const errorCode = validationError.get(key)
    return errorCode && t(errorCode)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          data-test-id="document-form-title"
          error={validationError.has('title')}
          fullWidth={true}
          helperText={getErrorText('title')}
          label={t('documents.label.title')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updateMultiPart({title: event.target.value})
          }
          required
          value={item.title}
          variant="filled"
          InputProps={INPUT_FORM_FIELD_SX}
        />
      </Grid>

      <Grid item xs={12}>
        <ProcessStageDropDown
          plantId={plantId}
          required
          data-test-id="document-form-processStage"
          errorText={getErrorText('processStage')}
          onChange={(value) => updateMultiPart({processStage: value})}
          value={item.processStage}
        />
      </Grid>

      <Grid item {...REGULAR_SIZE} data-test-id="valid-from-item-grid">
        <SimpleDateTimePicker
          label={t('documents.label.validFrom')}
          date={item.validFrom ? moment(item.validFrom) : null}
          errorText={getErrorText('validFrom') || validationError.get('validFrom')}
          required={true}
          handleDateChange={(date) => {
            updateMultiPart({validFrom: date || undefined})
          }}
          timezone={timezone}
        />
      </Grid>
      <Grid item {...REGULAR_SIZE} data-test-id="valid-to-item-grid">
        <SimpleDateTimePicker
          label={t('documents.label.validTo')}
          showClearButton
          date={item.validTo ? moment(item.validTo) : null}
          errorText={getErrorText('validTo') || validationError.get('validTo')}
          handleDateChange={(date) => {
            if (date === null) {
              updateMultiPart({validTo: undefined})
              return
            }
            updateMultiPart({validTo: date})
          }}
          timezone={timezone}
        />
      </Grid>
      <Grid item xs={12}>
        <AttachmentPicker
          getErrorText={getErrorText}
          validationError={validationError}
          item={item}
          update={updateMultiPart}
        />
      </Grid>
    </Grid>
  )
}
