import {IconWithLabel} from '@hconnect/common/components/shiftEventLabels'
import {Block, Done, Remove, Loop, HourglassEmpty, QuestionMark} from '@mui/icons-material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {PmNotificationStatus} from '../../types/shiftHandover.types'

export const PmNotificationStatusLabel: FC<{
  status: PmNotificationStatus
  iconOnly?: boolean
  sx?: SystemStyleObject
}> = (props) => {
  const {t} = useTranslation()

  const icons: Record<PmNotificationStatus, React.ReactNode> = {
    [PmNotificationStatus.Unknown]: <QuestionMark />,
    [PmNotificationStatus.OrderAssigned]: <HourglassEmpty />,
    [PmNotificationStatus.OutstandingNotification]: <Done />,
    [PmNotificationStatus.Deleted]: <Block color={'secondary'} />,
    [PmNotificationStatus.InProgress]: <Loop color={'secondary'} />,
    [PmNotificationStatus.Completed]: <Done color={'success'} />
  }

  return (
    <IconWithLabel
      sx={props.sx}
      icon={icons[props.status as string] ?? <Remove />}
      iconOnly={props.iconOnly}
      label={t(`shiftEvent.pmNotificationStatus.${props.status}`)}
    />
  )
}
