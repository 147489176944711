import {join, take, split} from 'lodash'

export function convertMinsToHrsMins(minutes: number | undefined) {
  if (!minutes) {
    return '00:00'
  }

  const h = Math.floor(minutes / 60)
  const m = minutes % 60
  const hDisplay = h < 10 ? '0' + h : h
  const mDisplay = m < 10 ? '0' + m : m
  return `${hDisplay}:${mDisplay}`
}

export function formatTimeHHMM(time: string | undefined) {
  if (!time) return '00:00'

  return join(take(split(time, ':'), 2), ':')
}
