import {AppStage, MockStore} from '@hconnect/common/mocking'

import {initializeMockData} from './initializeMockData'
import type {MockScenario} from './scenarios/scenario.type'

// re-type and export the singleton
export const mockStore = MockStore.enableMocking<MockScenario>(
  'Cockpit',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  process.env.REACT_APP_STAGE! as AppStage
)

const asyncImportMockData = () => {
  return Promise.all([
    import('./scenarios'),
    import('./data/persona'),
    import('./MockPermissions')
  ]).then(([scenarios, personas, permissions]) => {
    return {scenarios, personas, permissions}
  })
}

/**
 */
if (process.env.REACT_APP_STAGE === 'qa') {
  mockStore.onEnable(() => {
    void asyncImportMockData().then(({scenarios, personas, permissions}) => {
      initializeMockData(
        mockStore,
        personas.personas,
        scenarios.standardScenario,
        permissions.rolesToPermission
      )
      mockStore.users.login(personas.shiftLeader)
    })
  })
}

export {MockStore} from '@hconnect/common/mocking'
