import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {INSTRUCTIONS_TENANT_ID} from '../../consts'
import {InstructionsDto, DocumentSearchCriteria, InstructionVM} from '../../types/documents.types'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const QueryKey = 'instructions-search'

export const useFilteredInstructions = (
  settings: DocumentSearchCriteria,
  options?: UseQueryOptions<InstructionVM[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()

  return useQuery<InstructionVM[], AxiosError>(
    [QueryKey, settings, plantId, timezone],
    async () => {
      const response = await axiosInstance.get<InstructionsDto>(
        `/instructions/${INSTRUCTIONS_TENANT_ID}/plants/${plantId}/filtered-instructions`,
        {
          params: settings
        }
      )
      return response.data.map((item) => new InstructionVM(item, timezone))
    },
    options
  )
}
