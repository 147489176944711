import {EquipmentData, EquipmentPlain} from '@hconnect/common/types'

import {mockStore} from '../mockStoreSingleton'

import {EquipmentLeaf} from './types'

// functions to perform search on the equipment tree go here:

function matchSearchTerm(searchTerm: string, equipment: EquipmentData) {
  const term = searchTerm.toLocaleLowerCase()
  const matchesEquipmentId = equipment.id.toLocaleLowerCase().indexOf(term) >= 0
  const matchesEquipmentText = (equipment.text || '').toLocaleLowerCase().indexOf(term) >= 0
  return matchesEquipmentId || matchesEquipmentText
}

function searchEquipmentTree(
  searchTerm: string,
  path: EquipmentData[],
  resultList: EquipmentPlain[],
  children: EquipmentLeaf[]
): void {
  children.map((leaf) => {
    if (matchSearchTerm(searchTerm, leaf.equipment)) {
      resultList.push({
        equipment: leaf.equipment,
        processStage: leaf.processStage,
        path
      })
    }

    if (leaf.children) {
      const subPath = [...path, leaf.equipment]
      searchEquipmentTree(searchTerm, subPath, resultList, leaf.children)
    }
  })
}

export function searchEquipment(searchTerm: string): EquipmentPlain[] {
  const results = []
  const mockState = mockStore.scenario()
  searchEquipmentTree(searchTerm, [], results, mockState.equipmentTree)
  return results
}

function getEquipmentPlain(children: EquipmentLeaf[], searchTerm?: string): EquipmentPlain[] {
  return children.reduce((acc: EquipmentPlain[], leaf: EquipmentLeaf) => {
    if (!searchTerm || matchSearchTerm(searchTerm, leaf.equipment)) {
      acc.push({
        equipment: leaf.equipment,
        processStage: leaf.processStage
      })
    }

    if (leaf.children) {
      acc.push(...getEquipmentPlain(leaf.children, searchTerm))
    }
    return acc
  }, [])
}

export function searchEquipmentPlain(searchTerm?: string): EquipmentPlain[] {
  const equipmentTree = mockStore.scenario().equipmentTree
  const results = getEquipmentPlain(equipmentTree, searchTerm)
  return results
}

function getMainEquipmentData(children: EquipmentLeaf[], searchTerm?: string): EquipmentData[] {
  return children.reduce((acc: EquipmentData[], leaf: EquipmentLeaf) => {
    if (leaf.mainEquipment && (!searchTerm || matchSearchTerm(searchTerm, leaf.mainEquipment))) {
      acc.push(leaf.mainEquipment)
    }

    if (leaf.children) {
      acc.push(...getMainEquipmentData(leaf.children, searchTerm))
    }
    return acc
  }, [])
}

export function searchMainEquipment(searchTerm?: string): EquipmentData[] {
  const mockState = mockStore.scenario()
  const results = getMainEquipmentData(mockState.equipmentTree, searchTerm)
  return results
}
