import {CommonDropDown} from '@hconnect/common/components/shiftEventFormFields'
import {EventType, eventTypesInterchangeable} from '@hconnect/common/types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {
  ShiftEvent,
  parameterChangeKeys,
  sharedEventStructureKeys,
  eventTypeToStatusMapping,
  taskKeys
} from '../../types/shiftHandover.types'

const EventTypeChangeAlertDialog: React.FC<{
  isOpen: boolean
  handleClose: (allowChange: boolean) => void
  body: React.ReactNode
}> = ({isOpen, handleClose, body}) => {
  const {t} = useTranslation()
  const handleCancel = () => {
    handleClose(false)
  }
  const handleSave = () => {
    handleClose(true)
  }
  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-body"
    >
      <DialogTitle id="dialog-title">{t('shiftEvent.form.changeEventType')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-body">{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} data-test-id="cancel-eventType-change">
          {t('shiftEvent.action.cancel')}
        </Button>
        <Button variant="contained" onClick={handleSave} data-test-id="save-eventType-change">
          {t('shiftEvent.action.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const eventTypeKeyStructure = {
  malfunction: sharedEventStructureKeys,
  information: sharedEventStructureKeys,
  idea: sharedEventStructureKeys,
  environment: sharedEventStructureKeys,
  quality: sharedEventStructureKeys,
  healthAndSafety: sharedEventStructureKeys,
  task: taskKeys,
  parameterChange: parameterChangeKeys
}

// these events have the same properties but Status can have different values
const sharedPropertiesEventTypes: Partial<EventType>[] = [
  // Status
  'malfunction',
  'environment',
  'quality',
  'healthAndSafety',
  // StatusWithNone
  'information',
  'idea'
]
const areEventTypesInterchangeable = (
  eventType1: Partial<EventType>,
  eventType2: Partial<EventType>
): boolean => {
  return (
    sharedPropertiesEventTypes.includes(eventType1) &&
    sharedPropertiesEventTypes.includes(eventType2)
  )
}

export const EventTypeDropdownBox = (props: {
  item: Partial<ShiftEvent>
  value: EventType
  updateMultiPart: (delta) => void
  disabled?: boolean
}): React.ReactElement => {
  const {t} = useTranslation()
  const {value, updateMultiPart, disabled, item} = props
  const selectedValue = React.useRef<EventType>(value)
  const isStatusInterchangeable = React.useRef(false)
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false)

  return (
    <>
      <CommonDropDown
        onChange={(inputValue, event) => {
          event.preventDefault()
          selectedValue.current = inputValue
          const eventsAreSimilar = areEventTypesInterchangeable(inputValue, value)
          const isStatusSupportedInNewType = eventTypeToStatusMapping[inputValue].includes(
            item.status
          )
          if (eventsAreSimilar && isStatusSupportedInNewType) {
            updateMultiPart({
              eventType: inputValue
            })
          } else {
            isStatusInterchangeable.current = isStatusSupportedInNewType
            setOpenAlertDialog(true)
          }
        }}
        value={value}
        keyName="eventType"
        options={eventTypesInterchangeable}
        data-test-id="event-form-eventType"
        disabled={disabled}
        required
      />
      <EventTypeChangeAlertDialog
        isOpen={openAlertDialog}
        handleClose={(allowChange) => {
          if (allowChange && selectedValue.current !== value) {
            const newEventType = selectedValue.current
            const convertedProperties = Object.keys(item).reduce((obj, key) => {
              const isNotAllowed = !eventTypeKeyStructure[newEventType].includes(key)
              if (isNotAllowed) obj[key] = undefined
              return obj
            }, {})
            if (!isStatusInterchangeable.current || newEventType === 'parameterChange') {
              convertedProperties['status'] = 'pending'
            }

            updateMultiPart({
              ...convertedProperties,
              eventType: newEventType
            })
          }
          setOpenAlertDialog(false)
          isStatusInterchangeable.current = false
        }}
        body={
          <>
            <Trans
              key="shiftEvent.form.changeEventTypeDescription"
              values={{
                currentEventType: t(`shiftEvent.eventType.${value}`),
                newEventType: t(`shiftEvent.eventType.${selectedValue.current}`)
              }}
            >
              Would you like to change event type from{' '}
              <strong>{t(`shiftEvent.eventType.${value}`)}</strong> to{' '}
              <strong>{t(`shiftEvent.eventType.${selectedValue.current}`)}</strong>?
            </Trans>
            {'\n'}
            {t('shiftEvent.form.changeEventTypeWarning', {
              statusReset: isStatusInterchangeable.current
                ? ''
                : t('shiftEvent.form.resetStatusWarning')
            })}
          </>
        }
      />
    </>
  )
}
