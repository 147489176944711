import {CardSectionTitle} from '@hconnect/uikit/src/lib2'
import {LinearProgress, Box, Divider} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {NewComment} from '../../components/common/CommentForm'
import {CommentsList} from '../../components/common/CommentList'
import {useComments, useCreateComment} from '../../hooks/api/useComments'
import {useDeleteComment, useEditComment} from '../../hooks/api/useCommentsAction'
import {useHasPermission} from '../../hooks/usePermission'

type CommentsProps = {eventId: string}

export const Comments: React.FC<CommentsProps> = ({eventId}) => {
  const comments = useComments(eventId)
  const createComment = useCreateComment()
  const deleteCommentAction = useDeleteComment()
  const editCommentAction = useEditComment()

  const hasPermission = useHasPermission()
  const {t} = useTranslation()

  return (
    <Box data-test-id="events-comments">
      <Box
        sx={{
          mt: 3,
          mb: 1
        }}
      >
        <CardSectionTitle sx={{mb: 1}} data-test-id="events-comments-heading">
          {t('shiftEvent.label.comments')}
          {comments.data && ` (${comments.data.length})`}
        </CardSectionTitle>
        {comments.isFetching || comments.isLoading ? (
          <LinearProgress data-test-id="events-comments-loading" />
        ) : (
          <Divider sx={{borderColor: 'common.black'}} />
        )}
      </Box>
      {comments.data && (
        <Box my={2}>
          {hasPermission('ADD_PLANT_EVENT_COMMENTS') && (
            <NewComment eventId={eventId} createCommentAction={createComment} />
          )}
          {hasPermission('VIEW_PLANT_EVENT_COMMENTS') && (
            <CommentsList
              comments={comments.data}
              withMarkdown={true}
              editCommentAction={editCommentAction}
              deleteCommentAction={deleteCommentAction}
            />
          )}
        </Box>
      )}
    </Box>
  )
}
