import {ConfigContext} from '@hconnect/common/hooks/ConfigContext'
import {Config, ConfigDto, Shift, ShiftDto} from '@hconnect/common/types'
import {AxiosError, AxiosInstance} from 'axios'
import React, {createContext, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {UseQueryOptions, useQuery} from 'react-query'

import {getConfig} from '../common/services'
import {FeatureFlagKey} from '../types/shiftHandover.types'

import {useGlobalContext} from './useGlobalContext'

const Context = createContext<ConfigContext<FeatureFlagKey> | undefined>(undefined)

const mapShifts = (shifts: ShiftDto[]): Shift[] =>
  shifts.map(
    (shift: ShiftDto): Shift => ({
      id: shift.id,
      start: shift.start,
      end: shift.end,
      weekDays: shift.weekDays,
      endTechnical: shift.endTechnical,
      name: shift.name
    })
  )
const mapConfig = (config: ConfigDto<FeatureFlagKey>): Config<FeatureFlagKey> => {
  return {...config, shifts: mapShifts(config.shifts)}
}

export const useConfigService = (
  axiosInstance: AxiosInstance,
  plantId: string,
  options?: UseQueryOptions<ConfigDto<FeatureFlagKey>, AxiosError, Config<FeatureFlagKey>>
) => {
  const {
    i18n: {language}
  } = useTranslation()

  return useQuery<ConfigDto<FeatureFlagKey>, AxiosError, Config<FeatureFlagKey>>(
    // refresh config on language change
    [language, plantId],
    async () => {
      return await getConfig(axiosInstance, plantId, language)
    },
    {
      ...options,
      // hook specific options
      select: (data) => mapConfig(data),
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  )
}

export const ConfigProvider = ({children}: {children: React.ReactNode}) => {
  const {config} = useGlobalContext()

  const configContext = React.useMemo(() => new ConfigContext(config), [config])

  return <Context.Provider value={configContext}>{children}</Context.Provider>
}

export const useConfig = () => {
  const ctx = useContext(Context)
  if (ctx === undefined) {
    throw new Error('useConfig used outside of AppConfigProvider or app-config not available')
  } else {
    return ctx
  }
}
