import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import React from 'react'

import {
  pmNotificationStatusListApprovalStates,
  PmNotificationStatus
} from '../../types/shiftHandover.types'
import {SelectAllButton} from '../common/SelectAllButton'
import {PmNotificationStatusLabel} from '../eventProperties/PmNotificationStatusLabel'

import {CommonFilterDropDownProps, FilterDropDown} from './FilterDropDown'

export const PmNotificationStatusFilterDropDown: React.FC<
  CommonFilterDropDownProps<PmNotificationStatus>
> = (props) => {
  const {selectAll} = props
  return (
    <FilterDropDown
      {...props}
      options={pmNotificationStatusListApprovalStates}
      keyName="pmNotificationStatus"
      customTemplate={(status) => {
        return <PmNotificationStatusLabel status={status} />
      }}
      fullWidth={false}
      customDropDownFooter={(keyName) =>
        props.isCollapsible ? null : (
          <>
            <SelectAllButton
              keyName={keyName}
              selectAll={() =>
                selectAll && selectAll(keyName, pmNotificationStatusListApprovalStates)
              }
            />
            <RemoveFilterButton keyName={keyName} removeFilter={props.removeFilter} />
          </>
        )
      }
    />
  )
}
