import {ManualKpisProcessStage} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useConfig} from '../../useConfig'
import {useApi} from '../useApi'

export const QueryKey = 'process-stages'

export const useProcessStagesForManualKpis = (
  language: string,
  options?: UseQueryOptions<ManualKpisProcessStage[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  const path = `/shifthandover/${plantId}/process-stages`

  return useQuery<ManualKpisProcessStage[], AxiosError>(
    [QueryKey, plantId, language],
    async () => {
      const response = await axiosInstance.get<ManualKpisProcessStage[]>(path, {
        params: {language}
      })
      return response.data
    },
    options
  )
}
