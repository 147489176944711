import {WhiteList} from '@hconnect/common/hproduce'

export enum AccessType {
  SinglePlant = 'singlePlant',
  MultiplePlants = 'multiplePlants',
  NoAccess = 'noAccess'
}

type PlantAccess = {
  accessType: AccessType
  plantId?: string
}

export const getPlantId = (urlPart: string, whiteList: WhiteList): PlantAccess => {
  if (urlPart) {
    return {
      accessType: AccessType.SinglePlant,
      plantId: urlPart
    }
  } else if (whiteList.optionsPerApp['Cockpit']) {
    if (whiteList.optionsPerApp['Cockpit']?.length === 1) {
      return {
        accessType: AccessType.SinglePlant,
        plantId: whiteList.optionsPerApp['Cockpit'][0].plantId
      }
    } else {
      return {accessType: AccessType.MultiplePlants}
    }
  } else return {accessType: AccessType.NoAccess}
}
