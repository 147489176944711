import type MockAdapter from 'axios-mock-adapter/types'

import {mockStore} from '../mockStoreSingleton'

export const enableShiftSummaryMock = (apiMockAdapter: MockAdapter) => {
  apiMockAdapter.onPost(/\/shifthandover\/\w+\/shiftsummary/).reply(() => {
    const mockState = mockStore.scenario()
    return [200, mockState.summaryPageData]
  })
}
