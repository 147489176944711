import {
  PrioritiesTranslationMap,
  PriorityItem as PriorityConfig,
  PriorityItem
} from '@hconnect/common/types'
import {mockQueryReturn} from '@hconnect/common/utils'

export const samplePrioritiesDE: PriorityItem[] = [
  {
    priorityNumber: '1',
    priorityNumberText: '1-Sofort',
    language: 'DE'
  },
  {
    priorityNumber: '2',
    priorityNumberText: '2-Ein Tag',
    language: 'DE'
  },
  {
    priorityNumber: '3',
    priorityNumberText: '3-Eine Woche',
    language: 'DE'
  },
  {
    priorityNumber: '4',
    priorityNumberText: '4-Ein Monat',
    language: 'DE'
  },
  {
    priorityNumber: '5',
    priorityNumberText: '5-Nächster Stillst.',
    language: 'DE'
  },
  {
    priorityNumber: '6',
    priorityNumberText: '6-Winterreparatur',
    language: 'DE'
  },
  {
    priorityNumber: '9',
    priorityNumberText: '9-Puffer Arbeit',
    language: 'DE'
  }
]

export const samplePrioritiesEN: PriorityItem[] = [
  {
    priorityNumber: '1',
    priorityNumberText: '1-Immediately',
    language: 'EN'
  },
  {
    priorityNumber: '2',
    priorityNumberText: '2-One Day',
    language: 'EN'
  },
  {
    priorityNumber: '3',
    priorityNumberText: '3-One Week',
    language: 'EN'
  },
  {
    priorityNumber: '4',
    priorityNumberText: '4-One Month',
    language: 'EN'
  },
  {
    priorityNumber: '5',
    priorityNumberText: '5-Next Shutdown',
    language: 'EN'
  },
  {
    priorityNumber: '6',
    priorityNumberText: '6-Annual Shutdown',
    language: 'EN'
  },
  {
    priorityNumber: '9',
    priorityNumberText: '9-Buffer Work',
    language: 'EN'
  }
]

export const samplePriorities = {
  'de-DE': samplePrioritiesDE,
  'en-US': samplePrioritiesEN,
  cimode: samplePrioritiesEN
}

export const prioritiesData = mockQueryReturn<PrioritiesTranslationMap>(
  new Map(
    samplePrioritiesEN.map((priority: PriorityConfig) => {
      return [
        parseInt(priority.priorityNumber, 10),
        priority.priorityNumberText ?? priority.priorityNumber
      ]
    })
  )
)
