import {mapManualKpis} from '@hconnect/common/mappers'
import {ManualKpi, ManualKpiResolution, ManualKpiDto} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {Iso8601} from '../../../types/atomic.types'
import {useConfig} from '../../useConfig'
import {useApi} from '../useApi'

export const QueryKey = 'manual-kpis'

export const useManualKpis = (
  resolution: ManualKpiResolution,
  timestamp: Iso8601,
  options?: UseQueryOptions<ManualKpi[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()
  const path = `/shifthandover/${plantId}/kpi/${resolution}/${timestamp}`

  return useQuery<ManualKpi[], AxiosError>(
    [QueryKey, path, timezone],
    async () => {
      const response = await axiosInstance.get<ManualKpiDto[]>(path)
      return mapManualKpis(response.data, timezone)
    },
    options
  )
}
