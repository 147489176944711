import {ShellFooter} from '@hconnect/uikit/src/lib2'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {useFeatureFlag} from '../../hooks/useFeatureFlag'
import {routeToPrivacyPolicy, routeToTermsConditions} from '../../routes'

export const FooterContent = ({plantId}: {plantId?: string}) => {
  const {t} = useTranslation()

  const navigate = useNavigate()

  const isTermsAndConditionsEnabled = useFeatureFlag('termsAndConditions')

  const termsAndConditionsLinkProps = isTermsAndConditionsEnabled
    ? {
        footerLinks: [
          {
            label: t('legal.termsAndConditions'),
            link: generatePath(routeToTermsConditions, {plantId: plantId as string})
          },
          {
            label: t('legal.dataPrivacy'),
            link: generatePath(routeToPrivacyPolicy, {plantId: plantId as string})
          }
        ],
        navigate
      }
    : {}

  return <ShellFooter label={t('footer.poweredBy')} {...termsAndConditionsLinkProps} />
}
