import {EventStatusLabel} from '@hconnect/common/components/eventProperties/EventStatusLabel'
import {CommonDropDown, CommonProps} from '@hconnect/common/components/shiftEventFormFields'
import React from 'react'

import {WorkOrderEventStatus, eventStatusListWorkOrder} from '../../types/shiftHandover.types'

export const WorkOrderStatusDropDown = (
  props: CommonProps<WorkOrderEventStatus>
): React.ReactElement => {
  return (
    <CommonDropDown<WorkOrderEventStatus>
      {...props}
      required
      keyName="status"
      options={eventStatusListWorkOrder}
      customTemplate={(status) => {
        return <EventStatusLabel status={status} />
      }}
    />
  )
}
