import {User} from '@hconnect/common/types'
import {Box, Grid, Switch} from '@mui/material'
import {isEqual} from 'lodash'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {UserSearchField} from './UserSearchField'

type ApproversFieldProps = {
  onChange: (approvers: User[]) => void
  toggleApprovers: (needsApproval: boolean) => void
  needsApproval: boolean
  disabled?: boolean
  errorText?: string
  approvers?: User[]
}

export const ApproversField: FC<ApproversFieldProps> = ({
  onChange,
  needsApproval,
  toggleApprovers,
  errorText,
  approvers = [],
  disabled
}) => {
  const {t} = useTranslation()

  return (
    <Grid container m={0} alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Box pl={2}>
          {t('shiftEvent.action.needsApproval')}{' '}
          <Switch
            checked={needsApproval}
            data-test-id="approver-field-toggle"
            onChange={() => toggleApprovers(!needsApproval)}
            color="primary"
            disabled={disabled}
          />
        </Box>
      </Grid>
      {needsApproval && (
        <Grid item xs={12}>
          <UserSearchField
            label={t('shiftEvent.action.approvers')}
            errorText={errorText}
            onChange={(newAssignee) => {
              if (newAssignee && !isEqual(newAssignee, approvers)) {
                onChange(newAssignee)
              } else if (!newAssignee) {
                onChange([])
              }
            }}
            value={approvers}
            data-test-id="approverSearch"
            disabled={disabled}
          />
        </Grid>
      )}
    </Grid>
  )
}
