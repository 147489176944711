import {ContentBox} from '@hconnect/common/components/ContentBox'
import {eventTypeIcons} from '@hconnect/common/components/shiftEventLabels'
import {EventType, eventTypes, StoppageKind} from '@hconnect/common/types'
import {GridSetting} from '@hconnect/uikit'
import {Button, Grid} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {isStoppageEventType} from '../../common/utils/eventType'
import {CloseButton} from '../common/CloseButton'

type Props = {
  doClose: () => void
  onSelect: (eventType: EventType) => void
}

const regularSize: GridSetting = {
  xs: 12,
  sm: 6
}

const testId = 'event-type-select'

export const EventTypeSelectBox: React.FC<Props> = ({onSelect, doClose}) => {
  const {t} = useTranslation()

  return (
    <ContentBox
      data-test-id={testId}
      title={t('shiftEvent.form.headlineOnTypePick')}
      mode="contentDependentHeight"
      bodyWithPadding
      afterTitle={<CloseButton onClick={doClose} />}
    >
      <Grid container spacing={2} alignItems="center">
        {eventTypes
          .filter((type) => !isStoppageEventType(type) || type === StoppageKind.Incident)
          .map((eventType) => (
            <Grid item {...regularSize} key={eventType}>
              <Button
                data-test-id={`${testId}-${eventType}`}
                onClick={() => onSelect(eventType)}
                startIcon={eventTypeIcons[eventType]}
                fullWidth
                sx={(theme) => ({
                  borderColor: theme.palette.grey['400'],
                  color: theme.palette.text.primary,
                  fontWeight: 500,
                  '&:hover': {
                    borderColor: theme.palette.grey['400'],
                    backgroundColor: theme.palette.grey['100'],
                    boxShadow: theme.shadows[3]
                  }
                })}
              >
                {t(
                  `shiftEvent.eventType.${
                    eventType === StoppageKind.Incident ? 'stoppage' : eventType
                  }`
                )}
              </Button>
            </Grid>
          ))}
      </Grid>
    </ContentBox>
  )
}
