export const downloadBlob = (blobToDownload: Blob, fileName: string) => {
  const url = URL.createObjectURL(blobToDownload)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.id = 'blob-download-link-temp'
  link.download = fileName
  link.setAttribute('data-testid', `downloadLink-${fileName}`)
  document.body.appendChild(link)
  link.click()
  link.remove()
  URL.revokeObjectURL(url)
}
