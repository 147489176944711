import {
  FILE_MAX_SIZE_IN_BYTES,
  FILE_REJECTED_TRANSLATIONS,
  FileErrorCode,
  INPUT_FORM_FIELD_SX
} from '@hconnect/common/consts'
import {AttachmentFile} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit'
import {
  Add as AddIcon,
  FileUploadOutlined,
  OpenInNewOutlined,
  DeleteOutlined
} from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  TextField,
  ToggleButton as MUIToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import {ToggleButtonProps} from '@mui/material/ToggleButton/ToggleButton'
import React, {FC, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {DOCUMENT_ICON_MAP, SUPPORTED_DOCUMENTS_FORMAT} from '../../consts'
import {InstructionType, DocumentUpdate, PossibleErrors} from '../../types/documents.types'
import {isDocumentSupported} from '../../utils'

const TOGGLE_BUTTON_SX = {width: 48, height: 48}

const ToggleButton = ({children, value, ...rest}: ToggleButtonProps & {value: string}) => (
  <MUIToggleButton
    value={value}
    {...rest}
    sx={TOGGLE_BUTTON_SX}
    data-test-id={`document-form-attachment-type-${value}`}
  >
    {children}
  </MUIToggleButton>
)

type AttachmentPickerProps = {
  item: Partial<DocumentUpdate>
  update: (delta: Partial<DocumentUpdate>) => void
  getErrorText(key: PossibleErrors): string | undefined
  validationError: Map<PossibleErrors, string>
}

export const AttachmentPicker: FC<AttachmentPickerProps> = ({
  getErrorText,
  validationError,
  item,
  update
}) => {
  const {t} = useTranslation()
  const {notify} = useNotification()

  const onFileChange = useCallback(
    (newAttachment: File) => {
      if (!isDocumentSupported(newAttachment)) {
        const errorMessageKey = FILE_REJECTED_TRANSLATIONS[FileErrorCode.INVALID_TYPE]
        notify('info', `${t(errorMessageKey)} ${newAttachment.name}`)
        return
      }

      if (newAttachment.size > FILE_MAX_SIZE_IN_BYTES) {
        const errorMessageKey = FILE_REJECTED_TRANSLATIONS[FileErrorCode.TOO_LARGE]
        notify('info', `${t(errorMessageKey)} ${newAttachment.name}`)
        return
      }

      const attachmentToAppend: AttachmentFile = Object.assign(newAttachment, {
        contentType: newAttachment.type,
        preview: URL.createObjectURL(newAttachment),
        isImage: newAttachment.type.includes('image'),
        originalFile: newAttachment
      })

      update({newAttachment: attachmentToAppend})
    },
    [notify, t, update]
  )

  const hasAttachmentError = useMemo(
    () => (validationError.has('attachment') ? 'error' : 'secondary'),
    [validationError]
  )

  return !item.newAttachment && !item.attachmentFileName ? (
    <Box display="flex" gap={2}>
      <ToggleButtonGroup
        color="primary"
        sx={(theme) => ({
          border: `1px solid ${theme.palette.grey[300]}`,
          backgroundColor: theme.palette.grey[100]
        })}
        exclusive
        value={item.type}
        onChange={(event: React.MouseEvent<HTMLElement>, value: InstructionType) => {
          if (value) {
            update({type: value, link: undefined, newAttachment: undefined})
          }
        }}
      >
        <ToggleButton value={InstructionType.PDF}>
          <FileUploadOutlined />
        </ToggleButton>
        <ToggleButton value={InstructionType.LINK}>
          <OpenInNewOutlined />
        </ToggleButton>
      </ToggleButtonGroup>
      {item.type === InstructionType.PDF && (
        <Box display="flex" width="100%" position="relative">
          <Button
            data-test-id="add-document-button"
            color={hasAttachmentError}
            fullWidth
            component="label"
            htmlFor="attachmentInput"
            startIcon={<AddIcon />}
            sx={(theme) => ({
              borderColor: theme.palette.grey[100],
              boxShadow: theme.shadows[1],
              padding: '11px',
              '&:hover': {
                backgroundColor: theme.palette.grey[50],
                borderColor: theme.palette.grey[100]
              },
              '&:active': {
                borderColor: theme.palette.grey[100],
                backgroundColor: theme.palette.grey[200],
                boxShadow: 'none'
              }
            })}
          >
            {t('documents.action.create')}
          </Button>

          <Box
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files) {
                onFileChange(e.target.files[0])
              }
            }}
            component="input"
            type="file"
            multiple={true}
            accept={SUPPORTED_DOCUMENTS_FORMAT}
            id="attachmentInput"
            display="none"
          />
          {validationError.has('newAttachment') && (
            <Typography variant="caption" color="error" position="absolute" ml={2} top={58}>
              {getErrorText('newAttachment')}
            </Typography>
          )}
        </Box>
      )}
      {item.type === InstructionType.LINK && (
        <TextField
          data-test-id="document-form-attachmentLink"
          error={validationError.has('link')}
          fullWidth={true}
          helperText={getErrorText('link')}
          label={t('documents.label.attachmentLink')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            update({link: event.target.value})
          }
          required
          value={item.link}
          variant="filled"
          InputProps={INPUT_FORM_FIELD_SX}
        />
      )}
    </Box>
  ) : (
    <Box display="flex" alignItems="center" justifyContent="space-between" minHeight={58}>
      <Box display="flex" alignItems="center" gap={2} overflow="hidden">
        {item.type && (
          <Box display="flex" fontSize={32}>
            {DOCUMENT_ICON_MAP[item.type]}
          </Box>
        )}{' '}
        <Typography overflow="hidden" textOverflow="ellipsis">
          {item?.newAttachment?.name || item.attachmentFileName}
        </Typography>
      </Box>
      <IconButton
        sx={{p: 1}}
        onClick={() =>
          update({newAttachment: undefined, attachmentFileName: undefined, attachment: undefined})
        }
        color="error"
        data-test-id="document-delete-attachment-button"
      >
        <DeleteOutlined />
      </IconButton>
    </Box>
  )
}
