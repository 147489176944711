import {WorkCenter} from '@hconnect/common/types'
import {Autocomplete, Paper, TextField} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useConfig} from '../../hooks/useConfig'

const paper = (props) => <Paper elevation={8} style={{minWidth: '350px'}} {...props} />

const emptyArray = []

export type Props = {
  label?: React.ReactNode
  errorText?: React.ReactNode
  onChange: (workCenter: WorkCenter | undefined) => void
  value: WorkCenter | undefined
  required?: boolean
}
export const WorkCenterSearchHierarchy = (props: Props) => {
  const {t} = useTranslation()
  const [open, setOpen] = React.useState<boolean>(false)

  const [inputValue, setInputValue] = React.useState<string>('')
  const {workCenters} = useConfig()
  const options = workCenters ?? emptyArray

  const value = props.value?.text

  useEffect(() => {
    setInputValue(value ?? '')
  }, [value])

  return (
    <Autocomplete
      data-test-id="work-center-search"
      value={props.value ?? null}
      inputValue={inputValue}
      open={open && options.length > 0}
      onOpen={() => {
        setOpen(true)
      }}
      onChange={(_event, next, reason) => {
        if (reason === 'clear') {
          setInputValue('')
          props.onChange(undefined)
          return
        }
        if (next && typeof next !== 'string') {
          props.onChange(next)
        }
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(_event, newValue, reason) => {
        if (reason === 'input' && newValue !== inputValue) {
          setInputValue(newValue)

          if (!newValue && props.value) {
            props.onChange(undefined)
          }
        }
      }}
      options={options}
      getOptionLabel={(workCenter) => workCenter.text}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={!!props.errorText}
            data-test-id="work-center-search-input"
            helperText={props.errorText}
            label={t('sapPm.label.workCenterInput')}
            required={props.required}
            placeholder={t('pleaseStartTyping')}
            variant="filled"
          />
        )
      }}
      PaperComponent={paper}
      forcePopupIcon={false}
    />
  )
}
