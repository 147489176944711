import {ValidatorConfig, shouldNotBeEmpty, validateAll} from '@hconnect/common/validators'
import {Close, FileUpload} from '@mui/icons-material'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {EquipmentSearchContainer} from '../../components/eventProperties/EquipmentSearchContainer'
import {InforDepartmentCodeDropdown} from '../../components/eventProperties/InforDepartmentCodeDropdown'
import {InforPriorityDropdown} from '../../components/eventProperties/InforPriorityDropdown'
import {InforProblemCodeDropdown} from '../../components/eventProperties/InforProblemCodeDropdown'
import {useEventUpdate} from '../../hooks/api/useEventUpdate'
import {inforPriorityToShiftEventMapper} from '../../mappers/inforPriority.mappers'
import {sapPm} from '../../translations'
import {CreateInforWorkOrderRequest} from '../../types/maintenanceNotification.types'
import {BaseEvent, EventUpdate, ShiftEvent} from '../../types/shiftHandover.types'
import {getFormPrimaryButtonSx} from '../../utils'

const translate = sapPm.dialog.equipmentUpdate

type ErrorCase = 'conflictError' | 'validationError' | 'unknownError'

const emptyMap = new Map()
const validatorConfig: ValidatorConfig<Partial<CreateInforWorkOrderRequest>> = new Map([
  ['eventId', shouldNotBeEmpty()],
  ['equipmentTechnicalId', shouldNotBeEmpty()],
  ['priority', shouldNotBeEmpty()],
  ['departmentCode', shouldNotBeEmpty()],
  ['problemCode', shouldNotBeEmpty()]
])

type Props = {
  open: boolean
  onClose: () => void
  onConfirm: (data: CreateInforWorkOrderRequest) => void
  event: ShiftEvent
  initialFormData: Partial<CreateInforWorkOrderRequest>
  isSubmitDisabled?: boolean
}

type FormState = {
  values: Partial<CreateInforWorkOrderRequest>
  errors: Map<keyof CreateInforWorkOrderRequest, string>
  showValidation: boolean
}

export const CreateInforMaintenanceNotificationFormDialog = (props: Props) => {
  const {t} = useTranslation()
  const [error, setError] = useState<ErrorCase | null>(null)
  const [{values, showValidation, errors: validationErrors}, setFormState] = useState<FormState>({
    values: props.initialFormData,
    errors: emptyMap,
    showValidation: false
  })
  const isFormValid = !validationErrors.size
  const isSubmitDisabled = props.isSubmitDisabled || (showValidation && !isFormValid)
  const isEventEquipmentDefined = !!props.initialFormData.equipmentTechnicalId

  const handleChange = (key: keyof CreateInforWorkOrderRequest) => (newValue?: string) => {
    setFormState((prevState) => {
      const nextValues = {
        ...prevState.values,
        [key]: newValue
      }

      const validationErrors = prevState.showValidation
        ? validateAll(validatorConfig, nextValues)
        : prevState.errors

      return {
        ...prevState,
        values: nextValues,
        errors: validationErrors
      }
    })
  }

  const getErrorMessage = (key: keyof CreateInforWorkOrderRequest) => {
    const errorCode = validationErrors.get(key)

    return errorCode ? t(errorCode) : undefined
  }

  const {mutateAsync: eventUpdate} = useEventUpdate({
    onError: (updateError) => {
      if (updateError.response?.status === 409) {
        setError('conflictError')
      } else {
        setError('unknownError')
      }
    }
  })

  const handleCreateMaintenanceNotificationClick = async () => {
    const validationError = validateAll<Partial<CreateInforWorkOrderRequest>>(
      validatorConfig,
      values
    )

    if (validationError.size !== 0) {
      setFormState((prevState) => ({
        ...prevState,
        showValidation: true,
        errors: validationError
      }))
      return
    }

    let event = props.event
    const mappedFormPriority = inforPriorityToShiftEventMapper(values.priority)
    const isPriorityChanged = event.priority !== mappedFormPriority
    if (isPriorityChanged) {
      event = {
        ...event,
        priority: mappedFormPriority
      }
    }

    const {equipmentTechnicalId} = values
    const isEquipmentIdUpdated = !isEventEquipmentDefined && !!equipmentTechnicalId
    if (isEquipmentIdUpdated) {
      event = {
        ...event,
        equipment: {
          ...event.equipment,
          id: equipmentTechnicalId
        }
      }
    }
    if (isPriorityChanged || isEquipmentIdUpdated) {
      await eventUpdate({
        event: event as unknown as EventUpdate
      })
    }

    props.onConfirm(values as CreateInforWorkOrderRequest)
  }

  const handleEquipmentChange = ({equipment}: Partial<BaseEvent>) => {
    handleChange('equipmentTechnicalId')(equipment?.id)
  }

  const clearState = () => {
    setFormState({
      values: {},
      errors: emptyMap,
      showValidation: false
    })
    setError(null)
  }

  return (
    <Dialog
      TransitionProps={{
        onExit: clearState
      }}
      open
      maxWidth="sm"
      data-test-id="infor-maintenance-notification-dialog"
      onClose={props.onClose}
    >
      <DialogTitle>
        {!isEventEquipmentDefined
          ? t(translate.header)
          : t('sapPm.action.createMaintenanceNotification')}
      </DialogTitle>
      <DialogContent>
        {error ? (
          t(translate[error])
        ) : (
          <Stack spacing={2} useFlexGap>
            {!isEventEquipmentDefined && (
              <EquipmentSearchContainer
                required
                value={props.event}
                updateMultiPart={handleEquipmentChange}
                hideMainEquipmentField
                equipmentIdType="technicalId"
                errorText={getErrorMessage('equipmentTechnicalId')}
              />
            )}
            <InforPriorityDropdown
              value={values.priority}
              onChange={handleChange('priority')}
              required
              errorText={getErrorMessage('priority')}
              data-test-id="infor-priority-dropdown"
            />
            <InforDepartmentCodeDropdown
              value={values.departmentCode}
              onChange={handleChange('departmentCode')}
              required
              errorText={getErrorMessage('departmentCode')}
              data-test-id="infor-department-code-dropdown"
            />
            <InforProblemCodeDropdown
              value={values.problemCode}
              onChange={handleChange('problemCode')}
              required
              errorText={getErrorMessage('problemCode')}
              data-test-id="infor-problem-code-dropdown"
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<Close />}
          variant="text"
          onClick={props.onClose}
          data-test-id="infor-cancel-mn"
        >
          {t('action.cancel')}
        </Button>
        {!error && (
          <Button
            onClick={() => void handleCreateMaintenanceNotificationClick()}
            startIcon={<FileUpload />}
            color="primary"
            variant="text"
            disabled={isSubmitDisabled}
            sx={getFormPrimaryButtonSx}
            data-test-id="infor-send-mn"
          >
            {t('sapPm.action.createMaintenanceNotification')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
