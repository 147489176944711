import {DateRange} from '@hconnect/common/types'
import {Iso8601} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {mapValues} from 'lodash'
import moment from 'moment-timezone'
import {useQuery, UseQueryOptions} from 'react-query'

import {QuickSelectSlots} from '../../common/utils/filterParameter'
import {
  SummaryPageData,
  SummaryPageDataDto,
  SummaryPageDataSearchCriteria
} from '../../types/shiftHandover.types'
import {queryRangeToTimeRange} from '../../utils/timeRangeHelper'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const QueryKey = 'runningtimes-summarypage'

const mapSummaryPageData = (data: SummaryPageDataDto, timezone: string): SummaryPageData => {
  return {
    productionVolumesResult: {
      ...data.productionVolumesResult,
      productionVolumes: (data.productionVolumesResult.productionVolumes || []).map((volume) => ({
        ...volume,
        lastUpdatedAt: mapValues(volume.lastUpdatedAt, (value: Iso8601) =>
          moment.utc(value).tz(timezone)
        )
      }))
    }
  }
}

export const useShiftSummary = (
  queryRange: DateRange | QuickSelectSlots,
  options?: UseQueryOptions<SummaryPageData, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const config = useConfig()
  const {plantNow, shifts, timezone} = config
  const path = `/shifthandover/${config.plantId}/shiftsummary`

  return useQuery<SummaryPageData, AxiosError>(
    [QueryKey, queryRange, timezone, shifts, config.plantId],
    async () => {
      const timeRange = queryRangeToTimeRange(queryRange, shifts, plantNow())

      const settings: SummaryPageDataSearchCriteria = {
        startDate: timeRange.startDate,
        endDate: timeRange.endDate
      }

      const {data} = await axiosInstance.post<SummaryPageDataDto>(path, settings)

      return mapSummaryPageData(data, timezone)
    },
    options
  )
}
