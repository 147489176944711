import {
  BrowserBackendSelector,
  BrowserLoginFlow,
  BrowserLoginStorage,
  ClientConfig,
  createHttpClient,
  EnvAuthRequestProvider,
  getDefaultDFBackends
} from '@hconnect/apiclient'
import {AxiosError} from 'axios'
import MockAdapter from 'axios-mock-adapter'
import React from 'react'
import {QueryClient, QueryClientProvider, DefaultOptions} from 'react-query'

import {enableExistingApiMocks, enableNotYetExistingApiMocks} from '../../__mock__/mock'
import {mockStore} from '../../__mock__/mockStoreSingleton'

import {Api} from './types'

const STORAGE_KEY = `HC-${process.env.REACT_APP_CLIENT_NAME}-${process.env.REACT_APP_STAGE}`

type Props = {
  api: Api
  children: React.ReactNode
}

export const createApi = (options: {throwOnNoMatch: boolean; mockEverything: boolean}): Api => {
  const loginStorage = new BrowserLoginStorage(STORAGE_KEY)
  const backendSelector = new BrowserBackendSelector(getDefaultDFBackends())
  const authRequestProvider = new EnvAuthRequestProvider(backendSelector)
  const loginFlow = new BrowserLoginFlow(loginStorage, authRequestProvider, backendSelector)

  const clientConfig: ClientConfig = {
    authRequestProvider,
    backendSelector,
    loginFlow,
    loginStorage
  }

  const axiosInstance = createHttpClient(clientConfig)

  const apiMockAdapter = new MockAdapter(axiosInstance, {
    onNoMatch: options.throwOnNoMatch ? 'throwException' : 'passthrough'
  })

  // to start development of features that are not implemented on the backend yet
  enableNotYetExistingApiMocks(apiMockAdapter)

  // Mocks for already implemented APIs (i.e. for Cypress tests)
  if (options.mockEverything) {
    mockStore.enableDfApiMockForAxios(apiMockAdapter)
    enableExistingApiMocks(apiMockAdapter, axiosInstance)
  }
  return {
    ...clientConfig,
    axiosInstance
  }
}

const isNetworkError = (error: AxiosError) => error.message === 'Network Error'
const retryTries = 1
const retryFunction = (failureCount: number, error: AxiosError): boolean =>
  failureCount < retryTries && isNetworkError(error)

const context = React.createContext<Api | undefined>(undefined)
const defaultOptions: DefaultOptions<AxiosError> = {
  queries: {
    retry: retryFunction
  },
  mutations: {
    retry: retryFunction
  }
}
const queryClient = new QueryClient({
  defaultOptions: defaultOptions as DefaultOptions
})

// can be used to wrap the <App /> but also to wrap containers within tests
export const ApiContextProvider: React.FC<Props> = ({api, children}) => {
  return (
    <context.Provider value={api}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </context.Provider>
  )
}

// used by consumers
export function useApi(): Api {
  const ctx = React.useContext(context)
  if (ctx === undefined) {
    throw new Error('useApi was used outside the scope of an ApiContextProvider!')
  } else {
    return ctx
  }
}
