import {RoleType} from '@hconnect/apiclient'
import type {MockStore} from '@hconnect/common/mocking'

import type {Persona} from './data/persona'
import {RolesToPermissionsMap} from './MockPermissions'
import type {MockScenario} from './scenarios/scenario.type'

/**
 * When enabling our mocs in cypress, we add our standard set of personas along with their respective permissions
 */

export function initializeMockData(
  mockStore: MockStore,
  personas: Record<string, Persona>,
  scenario: MockScenario,
  rolesToPermissions: RolesToPermissionsMap
) {
  mockStore.setScenarioData(scenario)
  /** TODO: check this -- Permissions are getting copied on every load */
  for (const persona of Object.values(personas)) {
    mockStore.users.addUser(persona)

    const roleNames = Object.keys(rolesToPermissions) as RoleType[]

    roleNames.forEach((roleName) => {
      const permissions = rolesToPermissions[roleName]
      if (persona.hasRoles.includes(roleName) && Array.isArray(permissions)) {
        permissions.map((permission) =>
          /** TODO: Clean the data scope extension in permission */
          mockStore.permissions.addPermission(persona, permission, {
            dataScope: `{"plantId": "${scenario.config.plantId}"}`
          })
        )
      }
    })
  }
}
