import {mergeSx} from '@hconnect/uikit/src/lib2'
import {MoreVert} from '@mui/icons-material'
import {Divider, IconButton, Menu, MenuItem, Theme, Typography} from '@mui/material'
import {SxProps} from '@mui/system'
import React, {FC, ReactNode, useCallback, useRef, useState} from 'react'

export type MenuConfig = {
  dataTestId: string
  label: string
  onClick: () => void
  icon: ReactNode
}[]

type MenuProps = {
  'data-test-id': string
  menuDataTestId: string
  config: MenuConfig
  sx?: SxProps<Theme>
}

export const MenuButton: FC<MenuProps> = ({
  'data-test-id': dataTestId,
  sx,
  menuDataTestId,
  config
}) => {
  const menuButtonRef = useRef(null)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const openMenu = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setMenuOpen(true)
    },
    [setMenuOpen]
  )
  const closeMenu = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setMenuOpen(false)
    },
    [setMenuOpen]
  )

  return (
    <>
      <IconButton
        sx={mergeSx({p: 1}, sx)}
        ref={menuButtonRef}
        onClick={openMenu}
        color="primary"
        data-test-id={dataTestId}
      >
        <MoreVert />
      </IconButton>

      <Menu
        data-test-id={menuDataTestId}
        anchorEl={menuButtonRef.current}
        open={menuOpen}
        onClose={closeMenu}
      >
        {config.map((menuItem, index) => [
          <MenuItem
            key={menuItem.dataTestId}
            onClick={(e: React.MouseEvent) => {
              menuItem.onClick()
              closeMenu(e)
            }}
            data-test-id={menuItem.dataTestId}
          >
            <Typography variant="subtitle1">
              {menuItem.icon}
              {menuItem.label}
            </Typography>
          </MenuItem>,
          index !== config.length - 1 && <Divider key={`${menuItem.dataTestId}-divider`} />
        ])}
      </Menu>
    </>
  )
}
