import {EventsTab} from '@hconnect/common/types'
import {Column, PaginationOptions} from '@hconnect/uikit/src/lib2'
import {TFunction} from 'i18next'
import {isEqual} from 'lodash'
import React, {FC} from 'react'

import {
  AllFilterOptions,
  FilterOptions,
  workOrderFilterOptions,
  WorkOrdersFilterOptionsKeys
} from '../../common/utils/filterParameter'
import {WorkOrderStatusLabel} from '../../components/eventProperties/WorkOrderStatusLabel'
import {
  generateWorkOrderEquipmentNumberColumn,
  getTitleColumn,
  Label,
  Secondary
} from '../../components/tableHelpers'
import {SortOrder, WorkOrderSortBy} from '../../types/shiftHandover.types'
import {ColumnsMap} from '../../types/table.types'
import {WorkOrder} from '../../types/workOrders.types'
import {WorksOrderSideCard} from '../worksOrders/WorksOrderSideCard'

import {EventsListView} from './EventsListView'

const generateColumnsMap = (t: TFunction, freeSearchText?: string): ColumnsMap<WorkOrder> => {
  const workOrderIdColumn: Column<WorkOrder> = {
    key: 'id',
    label: <Label>{t('shiftEvent.label.workOrderId')}</Label>,
    customTemplate: ({id}) => <Secondary>{id}</Secondary>
  }
  const generateStatusColumn = (type?: 'small' | 'regular'): Column<WorkOrder> => ({
    headProps: type === 'small' ? {style: {paddingLeft: '4px'}} : {},
    key: 'status',
    sortable: true,
    width: type === 'small' ? 0 : undefined,
    label: <Label>{t('shiftEvent.label.status')}</Label>,
    customTemplate: (item: WorkOrder) => (
      <Secondary>
        <WorkOrderStatusLabel status={item.status} iconOnly={type === 'small'} />
      </Secondary>
    )
  })

  return {
    XS: [
      workOrderIdColumn,
      getTitleColumn(t, freeSearchText),
      generateWorkOrderEquipmentNumberColumn('small', t, freeSearchText),
      generateStatusColumn('small')
    ],
    S: [
      workOrderIdColumn,
      getTitleColumn(t, freeSearchText),
      generateWorkOrderEquipmentNumberColumn('small', t, freeSearchText),
      generateStatusColumn('small')
    ],
    M: [
      workOrderIdColumn,
      getTitleColumn(t, freeSearchText),
      generateWorkOrderEquipmentNumberColumn('small', t, freeSearchText),
      generateStatusColumn('regular')
    ],
    L: [
      workOrderIdColumn,
      getTitleColumn(t, freeSearchText),
      generateWorkOrderEquipmentNumberColumn('regular', t, freeSearchText),
      generateStatusColumn('regular')
    ],
    XL: [
      workOrderIdColumn,
      getTitleColumn(t, freeSearchText),
      generateWorkOrderEquipmentNumberColumn('regular', t, freeSearchText),
      generateStatusColumn('regular')
    ]
  }
}

type WorkOrdersContainerProps = {
  activeTab: EventsTab
  setActiveTab: (tab: EventsTab) => void
  activeFilter: AllFilterOptions
  events?: WorkOrder[]
  isLoading?: boolean
  showBottomProgress?: boolean
  onFilterChange: (next: FilterOptions) => void
  paginationOptions?: PaginationOptions
}

export const WorkOrdersListView: FC<WorkOrdersContainerProps> = (props) => {
  const onSort = (sortBy: string, sortOrder?: SortOrder) => {
    const next = {
      ...props.activeFilter,
      sortWorkOrderBy: sortBy as WorkOrderSortBy,
      woSortDir: sortOrder
    }
    if (!sortBy || !sortOrder || isEqual(props.activeFilter, next)) {
      return
    }
    props.onFilterChange(next)
  }

  return (
    <EventsListView<WorkOrder, WorkOrdersFilterOptionsKeys, WorkOrderSortBy>
      {...props}
      urlParamId="workOrderId"
      generateColumnsMap={generateColumnsMap}
      SideCardComponent={WorksOrderSideCard}
      filterOptions={workOrderFilterOptions}
      sortedBy={props.activeFilter.sortWorkOrderBy}
      sortDirection={props.activeFilter.woSortDir}
      onSort={onSort}
    />
  )
}
