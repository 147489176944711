import {useCallback, useEffect, useMemo} from 'react'
import {SetURLSearchParams, useSearchParams} from 'react-router-dom'
import {useLocalStorage} from 'react-use'

import {
  combineSearchParamsToQuery,
  filterSearchParams,
  pickKeysFromSearchParams,
  sameSearchParamsByKeys
} from '../utils'

type Options = {
  keepInLocalStorage?: boolean
}

export const useQueryParamValue = (
  id: string,
  keys: string[],
  options: Options
): [string | undefined, (value: string | undefined) => void] => {
  const [storageData, setStorageData, removeStorageData] = useLocalStorage<string>(id)
  const [params, setParams]: [URLSearchParams, SetURLSearchParams] = useSearchParams()
  const hasParamValue = keys.some((key) => params.has(key))
  const {keepInLocalStorage} = options

  useEffect(() => {
    if (storageData && !hasParamValue) {
      const currentParams = new URLSearchParams(params)
      const newParams = combineSearchParamsToQuery(
        currentParams,
        pickKeysFromSearchParams(new URLSearchParams(storageData), keys)
      )
      setParams(newParams, {replace: true})
    }
    if (
      keepInLocalStorage &&
      hasParamValue &&
      !sameSearchParamsByKeys(params, new URLSearchParams(storageData), keys)
    ) {
      setStorageData(pickKeysFromSearchParams(params, keys).toString())
    }
  }, [storageData, setParams, setStorageData, params, keepInLocalStorage, hasParamValue, keys])

  const setQueryParam = useCallback(
    (value: string | undefined) => {
      const currentParams = filterSearchParams(params, keys)
      const newParams = combineSearchParamsToQuery(currentParams, new URLSearchParams(value))
      if (keepInLocalStorage) {
        if (value) {
          setStorageData(value)
        } else {
          removeStorageData()
        }
      }
      setParams(newParams, {replace: true})
    },
    [keepInLocalStorage, keys, params, removeStorageData, setParams, setStorageData]
  )

  const value = useMemo<string | undefined>(
    () => pickKeysFromSearchParams(params, keys).toString() || storageData,
    [keys, params, storageData]
  )

  return [value, setQueryParam]
}
