import {CommonDropDown, CommonProps} from '@hconnect/common/components/shiftEventFormFields'
import React from 'react'

import {InforDepartmentCode} from '../../types/maintenanceNotification.types'

const inforDepartmentCodeOptions = Object.values(InforDepartmentCode).map((value) => value)

export const InforDepartmentCodeDropdown: React.FC<CommonProps<InforDepartmentCode>> = ({
  disabled,
  ...props
}) => {
  return (
    <CommonDropDown
      {...props}
      disabled={disabled || inforDepartmentCodeOptions.length === 0}
      keyName="inforDepartmentCode"
      options={inforDepartmentCodeOptions}
    />
  )
}
