import {CustomTaskRepetition} from '@hconnect/common/types'
import {MenuItem, TextField, Box} from '@mui/material'
import {isArray} from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useConfig} from '../../hooks/useConfig'
import {ShiftPicker} from '../dateTimeAndShift/ShiftPicker'

type Props = {
  settings: CustomTaskRepetition
  onChange: (next: CustomTaskRepetition) => void
}

type EndingOptions = 'never' | 'afterCount' | 'onDate'

export const TaskRepetitionEndingOptions: React.FC<Props> = ({settings, onChange}) => {
  const config = useConfig()
  const {t} = useTranslation()
  const defaultEnd = config.plantNow().endOf('day').toISOString()
  const mode =
    ('endDate' in settings && 'onDate') ||
    ('endsAfterRepetitions' in settings && 'afterCount') ||
    'never'

  return (
    <>
      <TextField
        data-test-id="task-custom-repetition-end-options"
        sx={{width: 100}}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const next = event.target.value as EndingOptions
          const result: CustomTaskRepetition = {...settings}

          delete result.endsAfterRepetitions
          delete result.endDate

          if (next === 'afterCount' && !settings.endsAfterRepetitions) {
            result.endsAfterRepetitions = 2
          }
          if (next === 'onDate' && !settings.endDate) {
            result.endDate = defaultEnd ? moment.tz(defaultEnd, config.timezone) : undefined
          }

          onChange(result)
        }}
        select
        value={mode}
        variant="outlined"
      >
        <MenuItem value={'never'} data-test-id="repetition-ends-never">
          {t('recurringDialog.endsNever')}
        </MenuItem>
        <MenuItem value={'onDate'} data-test-id="repetition-ends-on-date">
          {t('recurringDialog.endsOnDate')}
        </MenuItem>
        <MenuItem value={'afterCount'} data-test-id="repetition-ends-after-count">
          {t('recurringDialog.endsAfterCount')}
        </MenuItem>
      </TextField>
      {settings.endDate && (
        <Box component={'span'} mx={2}>
          <ShiftPicker
            localDate={settings.endDate}
            onChange={(nextShift) => {
              if (isArray(nextShift)) return
              const next = {
                ...settings,
                endDate: nextShift.endDate
                  ? moment.tz(nextShift.endDate, config.timezone)
                  : undefined
              }
              delete next.endsAfterRepetitions
              onChange(next)
            }}
          />
        </Box>
      )}
      {'endsAfterRepetitions' in settings && (
        <>
          <TextField
            sx={{
              mx: 2,
              width: 75
            }}
            data-test-id="task-custom-repetition-end-count"
            inputProps={{'data-test-id': 'task-custom-repetition-end-count-input'}}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const next = {...settings}
              const input = parseInt(event.target.value, 10)
              const endsAfterRepetitions = !input || input < 1 ? 1 : input > 99 ? 99 : input

              delete next.endDate
              next.endsAfterRepetitions = endsAfterRepetitions
              onChange(next)
            }}
            variant="outlined"
            type="number"
            value={settings.endsAfterRepetitions}
          />
          {t('recurringDialog.repetitions')}
        </>
      )}
    </>
  )
}
