import {FilterTimeRange} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {omit} from 'lodash'
import moment from 'moment-timezone'
import {useQuery, UseQueryOptions} from 'react-query'

import {PmNotificationFilterOptions} from '../../../common/utils/filterParameter'
import {
  Notification,
  NotificationPagedContainer,
  NotificationPagedContainerDto,
  NotificationDto,
  WorkOrdersPayload
} from '../../../types/workOrders.types'
import {preparePayload} from '../../../utils'
import {useConfig} from '../../useConfig'
import {useApi} from '../useApi'
import {generateTimeRange} from '../useEventsSearch'

export const NotificationsQueryKey = 'work-orders-notifications'
export const NotificationQueryKey = 'work-orders-notification'

const mapNotifications = (
  data: NotificationPagedContainerDto,
  timezone: string
): NotificationPagedContainer => ({
  ...data,
  items: data.items.map((item) => ({
    ...item,
    createdAt: moment.utc(item.createdAt).tz(timezone)
  }))
})

export const useNotifications = (
  {input, offset, limit}: WorkOrdersPayload<PmNotificationFilterOptions>,
  options?: UseQueryOptions<NotificationPagedContainer, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantNow, shifts, timezone, plantId} = useConfig()
  const path = `/work-orders/${plantId}/notifications`

  return useQuery<NotificationPagedContainer, AxiosError>(
    [NotificationsQueryKey, path, timezone, shifts, input, offset, limit],
    async () => {
      const timeRange: FilterTimeRange = generateTimeRange(input.timeRange, shifts, plantNow)
      const filters = {
        ...omit(input, [
          'timeRange',
          'pmNotificationStatus',
          'sortNotificationsBy',
          'notifSortDir'
        ]),
        statuses: input.pmNotificationStatus,
        sortBy: input.sortNotificationsBy,
        sortingDirection: input.notifSortDir
      }
      const response = await axiosInstance.get<NotificationPagedContainerDto>(path, {
        params: {
          from: timeRange.startDate,
          to: timeRange.endDate,
          offset,
          limit,
          ...preparePayload<PmNotificationFilterOptions>(filters)
        }
      })
      return mapNotifications(response.data, timezone)
    },
    options
  )
}

export const useNotification = (
  notificationId?: string,
  options?: UseQueryOptions<Notification | undefined, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId, timezone} = useConfig()
  const path = `/work-orders/${plantId}/notifications/${notificationId}`

  return useQuery<Notification | undefined, AxiosError>(
    [NotificationQueryKey, path, timezone],
    async () => {
      if (!notificationId) return undefined
      const response = await axiosInstance.get<NotificationDto>(path)
      return (
        response.data && {
          ...response.data,
          createdAt: moment.utc(response.data.createdAt).tz(timezone)
        }
      )
    },
    options
  )
}
