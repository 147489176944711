import {ErrorDialog, ConfirmationDialog} from '@hconnect/common/components/dialogs'
import React from 'react'

import {useInstructionDelete} from '../../hooks/api/useInstructionDelete'
import {InstructionVM} from '../../types/documents.types'

type DocumentsDeleteProps = {
  showDialog: boolean
  instruction: InstructionVM
  setDialogOpen: (state: boolean) => void
  onSuccess: () => void
  onlyDeleteSeries?: boolean
}

export const DocumentDelete: React.FC<DocumentsDeleteProps> = ({
  instruction,
  onSuccess,
  showDialog,
  setDialogOpen
}) => {
  const documentDeleteAction = useInstructionDelete({
    onSuccess
  })

  return (
    <>
      {documentDeleteAction.error ? (
        <ErrorDialog
          httpCode={documentDeleteAction.error.code}
          statusCode={documentDeleteAction.error?.response?.status}
          onClose={() => {
            setDialogOpen(false)
            documentDeleteAction.reset()
          }}
          errorText={documentDeleteAction.error.message}
        />
      ) : (
        showDialog && (
          <ConfirmationDialog
            onDeleteSingle={() => {
              documentDeleteAction.mutate(instruction.id)
            }}
            onClose={() => setDialogOpen(false)}
            isSingleLoading={documentDeleteAction.isLoading}
            title={instruction.title ?? ''}
            isSeriesItem={false}
          />
        )
      )}
    </>
  )
}
