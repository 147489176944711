import {useEffect} from 'react'

export const useOnPageWithoutUnmountFocus = <T>(callback: () => Promise<T> | void) => {
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined

    const onWindowFocus = () => {
      timeoutId = setTimeout(() => {
        void callback()
      }, 50)
    }

    window.addEventListener('focus', onWindowFocus)

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('focus', onWindowFocus)
    }
  }, [callback])
}
