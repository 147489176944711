import {LoadingSwitcher} from '@hconnect/uikit'
import React, {useCallback, FC} from 'react'

import {useDocumentCreate} from '../../hooks/api/useDocumentCreate'
import {DocumentUpdate} from '../../types/documents.types'

import {DocumentFormContainer} from './DocumentFormContainer'

type DocumentCreateProps = {
  doClose: () => void
  onSuccessSubmit: (documentId: string) => void
}

export const DocumentCreate: FC<DocumentCreateProps> = ({onSuccessSubmit, doClose}) => {
  const createAction = useDocumentCreate({
    onSuccess: ({id}) => onSuccessSubmit(id)
  })

  const doSubmit = useCallback(
    (item: DocumentUpdate) => {
      createAction.mutate(item)
    },
    [createAction]
  )

  return (
    <LoadingSwitcher isLoading={createAction.isLoading}>
      <DocumentFormContainer doClose={doClose} doSubmit={doSubmit} />
    </LoadingSwitcher>
  )
}
