import {CommonProps, PriorityDropDown} from '@hconnect/common/components/shiftEventFormFields'
import React from 'react'

import {
  shiftEventToInforPriorityMapper,
  inforPriorityToShiftEventMapper
} from '../../mappers/inforPriority.mappers'
import {InforPriority} from '../../types/maintenanceNotification.types'

// Maps priority values from ShiftHandover API to Infor API for use in CreateInforMaintenanceNotification
// mapping is needed because the priorities are different in both APIs
export const InforPriorityDropdown: React.FC<Omit<CommonProps<InforPriority>, 'hiddenOptions'>> = (
  props
) => {
  return (
    <PriorityDropDown
      {...props}
      value={inforPriorityToShiftEventMapper(props.value)}
      onChange={(newValue, event) =>
        props.onChange(shiftEventToInforPriorityMapper(newValue) as InforPriority, event)
      }
    />
  )
}
