import {TaskRepetitionLabel} from '@hconnect/common/components/shiftEventDetails/TaskRepetitionLabel'
import {TaskRepetition, TaskRepetitionUnit, taskRepetitionUnits} from '@hconnect/common/types'
import {GridSetting} from '@hconnect/uikit'
import {
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  MenuItem,
  Typography,
  Tooltip
} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  fromQuickSelectToCustomTaskRepetition,
  quickSelectRepetition
} from '../../common/utils/taskRepetitionHelper'
import {useConfig} from '../../hooks/useConfig'
import {isCustomRepetition, RepetitionInputsSettings} from '../../types/taskRepetitionInfo.types'

import {TaskRepetitionDialog} from './TaskRepetitionDialog'

type Props = {
  settings: TaskRepetition | null
  defaultStartDate: Moment
  repetitionInputsSettings: RepetitionInputsSettings
  gridSetting: GridSetting
  onChange: (next: TaskRepetition | null) => void
}

export const TaskRepetitionSettings: React.FC<Props> = (props) => {
  const [isCustomDialog, setCustomDialog] = useState(false)
  const {t} = useTranslation()
  const {plantId, timezone} = useConfig()

  // Toggle function for recurring check box
  const onToggle = () => {
    if (props.settings) {
      // disabled the recurring checkbox to remove recurring property
      props.onChange(null)
    } else {
      // On enabling recurring property for a task, the default value selected is 'weekly'
      props.onChange(quickSelectRepetition(undefined))
    }
  }

  // Recurring type dropdown change function
  const onDropDownChange = (next: TaskRepetitionUnit | 'custom') => {
    if (next === 'custom') {
      setCustomDialog(true)
    } else {
      // Note: start date is not sent for quick filter options
      props.onChange(quickSelectRepetition(next))
    }
  }

  return (
    <>
      <Grid item {...props.gridSetting}>
        <Tooltip title={props.repetitionInputsSettings.checkboxMessage} arrow placement="bottom">
          <FormControlLabel
            control={
              <Checkbox
                disabled={props.repetitionInputsSettings.disableCheckbox}
                data-test-id="task-recurrence-toggle"
                color={props.repetitionInputsSettings.disableCheckbox ? 'default' : 'primary'}
                checked={!!props.settings}
                onChange={onToggle}
              />
            }
            label={t('shiftEvent.label.recurring')}
          />
        </Tooltip>
      </Grid>
      {props.settings && (
        <Grid item {...props.gridSetting}>
          <TextField
            data-test-id="event-form-repetition"
            disabled={props.repetitionInputsSettings.disableSelect}
            fullWidth
            label={t('shiftEvent.label.recurring')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onDropDownChange(event.target.value as TaskRepetitionUnit | 'custom')
            }}
            required
            select
            value={isCustomRepetition(props.settings) ? 'custom' : props.settings.repetition}
          >
            {taskRepetitionUnits.map((option) => (
              <MenuItem
                data-test-id={`event-form-repetition-${option}`}
                key={option}
                value={option}
              >
                {t(`shiftEvent.recurring.${option}`)}
              </MenuItem>
            ))}

            <MenuItem
              value={'custom'}
              data-test-id={'event-form-repetition-custom'}
              onClick={() => {
                if (props.settings && isCustomRepetition(props.settings)) {
                  // open the dialog even if custom is already selected
                  setCustomDialog(true)
                }
              }}
            >
              {t('shiftEvent.recurring.custom')}
            </MenuItem>
          </TextField>
        </Grid>
      )}

      {props.settings && (
        <Grid item xs={12}>
          <Typography variant="caption">
            <TaskRepetitionLabel
              settings={props.settings}
              defaultStartDate={props.defaultStartDate}
              omitFirstOccurrence
              plantId={plantId}
              timezone={timezone}
            />
          </Typography>
        </Grid>
      )}

      {props.settings && isCustomDialog && (
        <TaskRepetitionDialog
          customTaskRepetition={
            isCustomRepetition(props.settings)
              ? props.settings
              : fromQuickSelectToCustomTaskRepetition(props.settings, props.defaultStartDate)
          }
          onClose={() => setCustomDialog(false)}
          onChange={(next) => {
            setCustomDialog(false)
            props.onChange(next)
          }}
        />
      )}
    </>
  )
}
