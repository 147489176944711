import {ManualKpiResolution, ManualKpiToUpdate} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useMutation, UseMutationOptions, useQueryClient} from 'react-query'

import {Iso8601} from '../../../types/atomic.types'
import {useConfig} from '../../useConfig'
import {useApi} from '../useApi'

import {QueryKey} from './useManualKpis'

export const useUpdateKpis = (
  resolution: ManualKpiResolution,
  timestamp: Iso8601,
  options?: UseMutationOptions<never, AxiosError, ManualKpiToUpdate[]>
) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId} = useConfig()
  const path = `/shifthandover/${plantId}/kpi/${resolution}/${timestamp}`

  return useMutation<never, AxiosError, ManualKpiToUpdate[]>(
    async (kpis) => {
      const response = await axiosInstance.put<never>(path, kpis)
      return response.data
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void options?.onSuccess?.(data, variables, context)
        void queryClient.invalidateQueries(QueryKey)
      }
    }
  )
}
