import {Attachment} from '@hconnect/common/types'
import {v4 as uuid} from 'uuid'

import {MockScenario, MockStorageImage, MockUploadImage} from '../scenarios/scenario.type'

/**
 * We can't store Blobs in localStorage, hence the mock api converts to base64 urls
 */
export function blobToDataUrl(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (e: ProgressEvent<FileReader>) {
      if (!e || !e.target) {
        reject('failure compressing')
      } else {
        const dataUrl = e.target.result
        if (dataUrl === null || typeof dataUrl !== 'string')
          throw new Error('could not create dataUrl for image')
        resolve(dataUrl)
      }
    }
    reader.readAsDataURL(blob)
  })
}

// we cannot pass Blobs/Files to axios-mock-adapter so before doing the request, we convert to base64
// and encode other properties
export async function prepareAttachmentsForMocks(
  attachments: File[] | undefined = []
): Promise<MockUploadImage[]> {
  const result = attachments.map(async (file) => {
    return {
      filename: file.name,
      dataUrl: await blobToDataUrl(file)
    }
  })
  return Promise.all(result)
}

export function processImageAttachments(attachments: MockUploadImage[]) {
  const images: MockStorageImage[] = attachments.map((at) => ({
    id: uuid(),
    dataUrl: at.dataUrl,
    filename: at.filename
  }))

  return images
}

export function base64UrlToArrayBuffer(base64Url: string): ArrayBuffer {
  const base64: string = base64Url.split(';base64,')[1]
  if (!base64) {
    throw new Error(`could not decode base64 string: ${base64Url}`)
  }
  return Uint8Array.from(window.atob(base64), (c) => c.charCodeAt(0)).buffer
}

export function getAttachmentsFromMockState(eventId: string, mockState: MockScenario) {
  // attachments / images
  const attachments: Attachment[] = []
  const listOfAttachments = mockState.eventsToImages[eventId.toString()]
  if (listOfAttachments && Array.isArray(listOfAttachments)) {
    listOfAttachments.map((imageId) => {
      const storageImage = mockState.images.find((i) => i.id === imageId)
      if (!storageImage) throw new Error('Invalid mock image id')
      const attachment: Attachment = {
        fileName: storageImage.filename,
        mediaType: 'image/jpeg',
        previewMediaType: 'image/jpeg',
        // here in mocks, we provide image ids instead of urls
        previewUrl: imageId,
        url: imageId,
        id: Math.floor(Math.random() * 10) + 1
      }
      attachments.push(attachment)
    })
  }
  return attachments
}
