import {ManualKpiSuggestion} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useConfig} from '../../useConfig'
import {useApi} from '../useApi'

const QueryKey = 'kpi-suggestions'

export const useKpiSuggestions = (options?: UseQueryOptions<ManualKpiSuggestion[], AxiosError>) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  const path = `/shifthandover/${plantId}/kpi-suggestions`

  return useQuery<ManualKpiSuggestion[], AxiosError>(
    [QueryKey, plantId],
    async () => {
      const response = await axiosInstance.get<ManualKpiSuggestion[]>(path)
      return response.data
    },
    options
  )
}
