import {MarkdownText} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined, EditOutlined} from '@mui/icons-material'
import {Box} from '@mui/material'
import {AxiosError} from 'axios'
import React, {memo, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {UseMutationResult} from 'react-query'

import {DeleteCommentParameter, EditCommentParameter} from '../../hooks/api/useCommentsAction'
import {useGlobalContext} from '../../hooks/useGlobalContext'
import {useHasPermission} from '../../hooks/usePermission'
import {Comment} from '../../types/shiftHandover.types'
import {Info} from '../eventProperties/EventInfo'

import {CommentDelete} from './CommentDelete'
import {EditComment} from './CommentForm'
import {MenuButton, MenuConfig} from './Menu'

type CommentComponentProps = {
  comment: Comment
  onDelete: () => void
  withMarkdown?: boolean
  editCommentAction: UseMutationResult<Comment, AxiosError, EditCommentParameter>
}

const CommentComponent: React.FC<CommentComponentProps> = memo(
  ({withMarkdown, comment, onDelete, editCommentAction}) => {
    const hasPermission = useHasPermission()
    const {t} = useTranslation()
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const {user} = useGlobalContext()

    const allowEdit = hasPermission('EDIT_PLANT_EVENT_COMMENTS')
    const allowDelete = hasPermission('DELETE_PLANT_EVENT_COMMENTS')

    const showMenu = !isEdit && (allowEdit || allowDelete) && user.userId === comment.creatorId

    const menuConfig = useMemo<MenuConfig>(
      () => [
        {
          dataTestId: 'comment-edit-button',
          label: t('summary.action.edit'),
          onClick: () => setIsEdit(true),
          icon: <EditOutlined sx={{mr: 2, verticalAlign: 'text-bottom'}} />
        },
        {
          dataTestId: 'comment-delete-button',
          label: t('summary.action.delete'),
          onClick: onDelete,
          icon: <DeleteOutlined sx={{mr: 2, verticalAlign: 'text-bottom'}} />
        }
      ],
      [onDelete, t]
    )

    return (
      <Box display="flex" justifyContent="space-between">
        {isEdit ? (
          <EditComment
            comment={comment}
            onCancel={() => setIsEdit(false)}
            editCommentAction={editCommentAction}
          />
        ) : (
          <Box
            my={2}
            display="flex"
            alignItems="flex-start"
            data-test-id={`comment-item-${comment.id}`}
          >
            <Box flex={1} whiteSpace={'pre-line'} sx={{wordBreak: 'break-word'}}>
              {withMarkdown ? (
                <MarkdownText>{comment.description}</MarkdownText>
              ) : (
                <>
                  {comment.description} <br />
                </>
              )}
              <Info userName={comment.creator} date={comment.createDate} />
            </Box>
          </Box>
        )}

        {showMenu && (
          <MenuButton
            sx={{m: 2}}
            config={menuConfig}
            menuDataTestId="comment-more-menu"
            data-test-id={`comment-more-button-${comment.id}`}
          />
        )}
      </Box>
    )
  }
)

type CommentsListProps = {
  comments: Comment[]
  withMarkdown?: boolean
  editCommentAction: UseMutationResult<Comment, AxiosError, EditCommentParameter>
  deleteCommentAction: UseMutationResult<Comment, AxiosError, DeleteCommentParameter, unknown>
}

export const CommentsList: React.FC<CommentsListProps> = ({
  comments,
  withMarkdown,
  editCommentAction,
  deleteCommentAction
}) => {
  const [commentIdToDelete, setCommentIdToDelete] = useState<string>('')

  return (
    <>
      {comments.length > 0 &&
        comments.map((comment: Comment) => (
          <CommentComponent
            key={comment.id}
            onDelete={() => setCommentIdToDelete(comment.id)}
            comment={comment}
            withMarkdown={withMarkdown}
            editCommentAction={editCommentAction}
          />
        ))}

      {!!commentIdToDelete && (
        <CommentDelete
          onClose={() => setCommentIdToDelete('')}
          commentId={commentIdToDelete}
          deleteCommentAction={deleteCommentAction}
        />
      )}
    </>
  )
}
