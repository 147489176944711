import moment, {Moment} from 'moment-timezone'
import React, {createContext, useContext, useMemo} from 'react'

import {hasNewUpdates} from '../utils'

import {useFilteredInstructions} from './api/useFilteredInstructions'
import {useConfig} from './useConfig'
import {PageList, useLastVisited} from './useLastVisited'

export type LastVisitedContext = {
  lastVisited: Moment | undefined
  setLastVisited: () => void
  hasUpdated: boolean
}

export const useInstructionsPageLastVisitedHook = (): LastVisitedContext => {
  const {data} = useFilteredInstructions({})
  const {timezone} = useConfig()
  const [lastVisited, setLastVisited] = useLastVisited(PageList.Instructions)

  const hasUpdated = useMemo(() => {
    if (!lastVisited) return true
    return data?.some(({createdAt, updatedAt}) => {
      const updateVisited =
        (createdAt && hasNewUpdates(moment(createdAt).tz(timezone), lastVisited)) ||
        (updatedAt && hasNewUpdates(moment(updatedAt).tz(timezone), lastVisited))
      return updateVisited
    })
  }, [data, lastVisited, timezone])

  return {
    lastVisited,
    setLastVisited,
    hasUpdated: !!hasUpdated
  }
}

const Context = createContext<LastVisitedContext | undefined>(undefined)

export const InstructionsPageLastVisitedProvider = ({children}: {children: React.ReactNode}) => {
  const context = useInstructionsPageLastVisitedHook()

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export const useInstructionsPageLastVisited = () => {
  const ctx = useContext(Context)
  if (ctx === undefined) {
    throw new Error(
      'useInstructionsPageLastVisited used outside of InstructionsPageLastVisitedProvider'
    )
  } else {
    return ctx
  }
}
