import {CircularProgress} from '@mui/material'
import React, {FC} from 'react'

export const PageLoadingSpinner: FC = () => (
  <CircularProgress
    sx={{
      color: 'white',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    }}
  />
)
