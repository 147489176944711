import {HttpError} from '@hconnect/common/types'
import {AxiosInstance, AxiosRequestHeaders} from 'axios'
import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {enableCommentsMock} from './businessLogic/comments'
import {enableEventsMock} from './businessLogic/events'
import {enableEventsSearchMock} from './businessLogic/filter'
import {enableImageMock} from './businessLogic/image'
import {enableMaintenanceNotificationMock} from './businessLogic/maintenanceNotification'
import {enableExistingPlantConfigMock} from './businessLogic/plantConfig'
import {enableShiftMock} from './businessLogic/shifts'
import {enableShiftSummaryMock} from './businessLogic/summaryPageData'
import {enableTasksMock} from './businessLogic/task'
import {enableUserSearchMock} from './businessLogic/usersearch'
import {mockStore} from './mockStoreSingleton'

const enableErrorsMock = (apiMockAdapter: MockAdapter) => {
  apiMockAdapter.onPost('/testerror').reply((config) => {
    ;(config.headers as AxiosRequestHeaders)['Content-Type'] = 'application/problem+json'
    const requestDto = JSON.parse(config.data as string) as HttpError
    const status = requestDto.code
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    delete (requestDto as any).code
    return [status, requestDto]
  })
}

const enableConfigMock = (apiMockAdapter: MockAdapter) => {
  apiMockAdapter.onGet(/\/shifthandover\/\w+\/config/).reply(() => {
    // if an override for the current server time is set, use it - else use current time
    // (used in cypress tests)
    const scenario = mockStore.scenario()
    const currentTimeUtc = scenario.currentTimeUtc ?? moment.utc().toISOString()
    return [200, mockStore.scenario().config, {Date: currentTimeUtc}]
  })
}

/**
 * Mocks for which a backend API exists at least on QA - these should be mocked for development
 * and cypress-testing purposes
 */
export const enableExistingApiMocks = (
  apiMockAdapter: MockAdapter,
  axiosInstance: AxiosInstance
) => {
  enableConfigMock(apiMockAdapter)
  enableCommentsMock(apiMockAdapter)
  enableEventsMock(apiMockAdapter)
  enableShiftSummaryMock(apiMockAdapter)
  enableEventsSearchMock(apiMockAdapter, axiosInstance)
  enableUserSearchMock(apiMockAdapter)
  enableExistingPlantConfigMock(apiMockAdapter)
  enableImageMock(apiMockAdapter)
  enableTasksMock(apiMockAdapter, axiosInstance)
  enableShiftMock(apiMockAdapter)
  enableMaintenanceNotificationMock(apiMockAdapter)
}

/**
 * Mocks for which a backend API does not yet exist and thus should always be enabled
 * This can be used when the Frontend starts with a task before a backend API is deployed/defined
 * etc during development and prototyping.
 */
export const enableNotYetExistingApiMocks = (apiMockAdapter: MockAdapter) => {
  enableErrorsMock(apiMockAdapter)
}
