import {User} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const queryKey = 'user-search'

export const useUserSearch = (
  namePattern: string | undefined,
  options?: UseQueryOptions<User[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  return useQuery<User[], AxiosError>(
    [queryKey, namePattern, plantId],
    async () => {
      const path = `/shifthandover/${plantId}/search/taskassignee?namePattern=${namePattern}`

      if (!namePattern) {
        return Promise.resolve([])
      }
      const response = await axiosInstance.post<User[]>(path)
      return response.data
    },
    {
      cacheTime: 60000,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
